import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoMdTrash } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import API from "../../../axios/API"
import { PopInAnim } from "../../../commons/anims"
// import InlineTextField from "../../../components/snippets/InlineTextField"
import { setAlert, setPageTitle } from "../../../store/theme/actions"
import CareplanLoader from "../../Careplan/CareplanLoader"

import { useFormik } from "formik"
import { createPortal } from "react-dom"
import { FiDownload } from "react-icons/fi"
import { SiMinutemailer } from "react-icons/si"
import { careplan } from "../../../data/dummy/careplan"
import FlowText from "../../../components/snippets/FlowText"
import { Link } from "react-router-dom"
import Dialog from "../../../components/snippets/Dialog"
import axios from "axios"
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import CareplanDialog from "../../Dialogs/CareplanDialog"
import { useHistory } from "react-router"


const initialValues = {
    cgResponsibility: '',
    cmResponsibility: '',
    followupDate: ''
}

const Careplan = ({ match }) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [chkAuthorized, setChkAuthorized] = useState(false)
    const [careplanData, setCareplanData] = useState({})
    const [formData] = useState(initialValues)
    const [edit, setEdit] = useState(false)
    const [rerun] = useState(false)
    const [setShowFinalize] = useState(false)
    const [caseId, setCaseId] = useState('')
    const [demographic, setDemographic] = useState([])
    const [setRace] = useState([])
    const organization = useSelector(state => state.auth.organization)
    const userDetails = useSelector(state => state.auth.userDetails)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [downloadPopup, setDownloadPopup] = useState(false)
    // const [setEmail] = useState()
    let orgId = localStorage.getItem('orgid')
    const downloadRef = useRef(null)
    const [previousDownloadFocus, setPreviousDownloadFocus] = useState(null);
    const [supportService,setSupportService] = useState([])

    const form = useFormik({
        initialValues: formData,
        enableReinitialize: true
    })

    useEffect(() => {
        setCaseId(match.params.caseId)
        setLoading(true)
        API.get(`organizations/${orgId}/cases/${match.params.caseId}/demographic`)
            .then(res => {
                if (res.status === 200) {
                    setDemographic(res.data.body)
                    if (res.data.body.caregiver.race === 1) {
                        res.data.body.caregiver.race = 'white'
                    } else if (res.data.body.caregiver.race === 2) {
                        res.data.body.caregiver.race = 'black or african american'
                    } else if (res.data.body.caregiver.race === 3) {
                        res.data.body.caregiver.race = 'hispanic or latin american'
                    } else if (res.data.body.caregiver.race === 4) {
                        res.data.body.caregiver.race = 'asian'
                    } else if (res.data.body.caregiver.race === 5) {
                        res.data.body.caregiver.race = 'american indian or alaska native'
                    } else if (res.data.body.caregiver.race === 6) {
                        res.data.body.caregiver.race = 'native hawaiian or pacific islander'
                    } else if (res.data.body.caregiver.race === 7) {
                        res.data.body.caregiver.race = 'middle eastern or north african'
                    } else if (res.data.body.caregiver.race === 8) {
                        res.data.body.caregiver.race = 'some other race/ethnicity'
                    } else if (res.data.body.caregiver.race === 9) {
                        res.data.body.caregiver.race = 'refused'
                    } else {
                        res.data.body.caregiver.race = "Other"
                    }
                    setRace(res.data.body.caregiver.race)
                   
                    setCareplanData(careplan)
                    setEdit(false)
                    setLoading(false)
                } else {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    history.push(`/casedata/${caseId}`)

                }
            })
    }, [])// eslint-disable-line

    useEffect(() => {
        dispatch(setPageTitle(t('Care Plan')))
    }, [i18n.language]) // eslint-disable-line
    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${match.params.queue}`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
            }
              }).then(() => setChkAuthorized(true))
            .catch(err => {
                if (err) {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    // history.push('/')
                }
            })
    }, []) // eslint-disable-line
    useEffect(() => {
        setChkAuthorized(true)
        if (!chkAuthorized) return
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${match.params.queue}`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
            }
              }).then(res => {
                if (res.status === 200) {
                    // if(res.data.body.prefLanguage === "es"){
                    //     res.data.body.prefLanguage = t("Spanish")
                    // } else if (res.data.body.prefLanguage === "zh"){
                    //     res.data.body.prefLanguage = t("Mandarin")
                    // } else if (res.data.body.prefLanguage === "en"){
                    //     res.data.body.prefLanguage = t("English")
                    // }
                    setCareplanData(res.data.body)
                    setEdit(false)
                    setSupportService(res.data.body.supportServices)
                    // setFormData({followup: res.data.data.followup_date || ''})
                    // if(res.data.data.status === 'pending') setEdit(true)
                    setLoading(false)
                } else {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    // history.push('/')
                }
            })
            .catch(err => {
                console.log(err)
                if (err) {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    // history.push('/')
                }
            })
    }, [chkAuthorized, rerun]) // eslint-disable-line


    const setComment = (value, serviceId, i) => {
        let id = i
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.map(a => {
            a.goalDetails.map(c => {
                c.strategyDetails.map(d => {
                    d.goalTypeDetails.map(e => {
                        e.services.map((f, i) => {
                            if (f.rdbId === serviceId.rdbId && id === i) {
                                f.comment = value;
                            }
                            return f
                        })
                        return e
                    })
                    return d
                })
                return c
            })
            return a
        })
        setEdit(false)
        setCareplanData(data)
    }
    const setOutcome = (goalType, strategy, value) => {
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data[goalType].goalDetails[strategy].desiredOutcome = value
        setCareplanData(data)
    }
    const showOutcome = (goalType, strategy) => {
        for (let category in goalType.goalDetails[strategy].strategyDetails) {
            for (let type in goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails) {
                if (goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.length > 0) return true
            }
        }
        return false
    }
    const removeItem = (item) => {
        if (!edit) return
        let data = JSON.parse(JSON.stringify(careplanData))
        data.caseGoals.map(a => {
            a.goalDetails.map(c => {
                c.strategyDetails.map(d => {
                    d.goalTypeDetails.map(e => {
                        e.services.map((f, i) => {
                            if (f.rdbId === item.rdbId) {
                                e.services.splice(i, 1)
                            }
                            return f
                        })
                        return e
                    })
                    return d
                })
                return c
            })
            return a
        })
        setCareplanData(data)
    }
    const setCgRespone = val => {
        if (!edit) return
        setCareplanData({ ...careplanData, cgResponsibility: val })
    }
    const shareCareplan = () => {
        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        let details = {
            caseId: match.params.caseId,
            cpId: match.params.careplanId,
            assessId: match.params.assessmentId,
            step: 2,
            lang: 'en',
            orgId: organization,
            draft :1, 
            timeZone:tzabbr
        }
        API.post(`care-plans/${match.params.careplanId}:share`,details).then(res=>{
            if(res.status === 200 && res.data.body === true){
                dispatch(setAlert({title:t('Success'),subtitle:t('Care Plan shared successfully'),type:'success',active:true}))
            }else if(res.status ===200 && res.data.status === false) {
                dispatch(setAlert({ title: t('Warning'), subtitle: res.data.errorMessage,active: true, type: 'warning' }))
            }
        }).catch(err => {
            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
        })
    }
    const saveCareplan = () => {
    }
    const finalize = () => {
        if (form.values.followupDate === '') {
            dispatch(setAlert({ title: t('Followup field is required'), subtitle: t('Please fill followup'), active: true, type: 'warning' }))
        } else {
            setShowFinalize(true)
        }
    }


    // const printCareplan = () => {
    //     let timestamp = new Date().getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //     // let details = {
    //     //     caseId: match.params.caseId,
    //     //     cpId: match.params.careplanId,
    //     //     assessId: match.params.assessmentId,
    //     //     step: 2,
    //     //     lang: 'en',
    //     //     orgId: organization
    //     // }
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/care-plans/${match.params.careplanId}/queue/${2}?Timezone=${tzabbr}`,{
    //         headers: {
    //             'Authorization': localStorage.getItem('token'), 
    //             'Accept': ''
    //         }
    //           }).then(res => {
    //         if (res.status === 200) {
    //             const linkSource = `data:application/pdf;base64,${res.data.body}`;
    //             const downloadLink = document.createElement("a");
    //             const fileName = "TCE-Care Plan.pdf";
    //             downloadLink.href = linkSource;
    //             downloadLink.download = fileName;
    //             downloadLink.click();
    //             setDownloadPopup(false)
    //         } else {
    //             dispatch(setAlert({ title: ('Error'), subtitle:t('Something went wrong'), active: true, type: 'error' }))
    //             setDownloadPopup(false)
    //         }
    //     }).catch(err => {
    //         dispatch(setAlert({ title: ('Error'), subtitle:t('Something went wrong'), active: true, type: 'error' }))
    //         setDownloadPopup(false)
    //     })
    // }
    const follow =(value)=>
    {if(value !== null && value){
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        let utc = moment.utc(value).local().format('MMM DD,YYYY HH:mm')
       return utc + ' ' +tzabbr
        //      let followupdate = new Date(value.slice(0,10))
        //  return `${followupdate.toString().slice(4,10)}, ${followupdate.toString().slice(11,15)} ${value.slice(11,19)}`
         }else{
             return ''
         }
      }

      useEffect(() => {
        if (downloadPopup) {
          setPreviousDownloadFocus(document.activeElement);
          downloadRef.current?.focus();
        } else {
          previousDownloadFocus && previousDownloadFocus.focus();
        }
      }, [downloadPopup]);

    return (
        <div className="mb-8">
            {loading ? <CareplanLoader />
                :
                <div>
                    <div className="flex justify-between text-lg font-bold">
                        <div>
                            <div>
                                <span className="text-zinc-600 dark:text-gray-400">{t("Care Plan for")} </span><span className="text-gray-600 dark:text-white" data-private="lipsum">{demographic && demographic.caregiver !== undefined && demographic.caregiver.firstName} {demographic && demographic.caregiver !== undefined && demographic.caregiver.lastName}</span>
                            </div>
                            <div>
                                <span className="text-zinc-600 dark:text-gray-400">{t("Caring for")} </span><span className="text-gray-600 dark:text-white" data-private="lipsum">{demographic && demographic.careReceiver !== undefined && demographic.careReceiver.firstName} ({demographic && demographic.careReceiver !== undefined && demographic.careReceiver.relationship})</span>
                            </div>
                            <div>
                                <span className="text-zinc-600 dark:text-gray-400">{t("Case #")} &nbsp;&nbsp;</span><span className="text-gray-600 dark:text-white underline"><Link to={`/casedata/${caseId}`}>{caseId}</Link></span>
                            </div>
                        </div>
                        <div className="flex gap-4 items-center">
                            {!edit &&
                                <>
                                    {/* downloadCareplan(match.params.careplan_id, careplanData.caregiver) */}
                                    <button className="flex gap-1 items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-black cursor-pointer" onClick={() => setDownloadPopup(true)}>
                                        <FiDownload role="img" title="Download Careplan"/> {t('Download')}
                                    </button>
                                    {/* emailCareplan(match.params.careplan_id, dispatch, t) */}
                                   {(userDetails.role.length === 1 && userDetails.role.includes('referrer')) || (demographic && demographic.caregiver !== undefined && demographic.caregiver.email === "") || (demographic && demographic.caregiver !== undefined && demographic.caregiver.email === null) ? '' :<button className="flex gap-1 items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-black cursor-pointer" onClick={() => shareCareplan()}>
                                        <SiMinutemailer role="img" title="Share Careplan"/> {`${t('Share')} ${t('Care Plan')}`}
                                    </button>}
                                </>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 mt-6">
                        {
                            careplanData.caseGoals.map(goalType => (
                                goalType.goalName !== undefined && goalType.goalId !== 0 &&
                                <div  key={goalType} className="form-wrap py-4 m-0">
                                    <div tabIndex={0} className="text-lg font-bold text-gray-700 dark:text-white">
                                        {goalType.goalName}
                                    </div>
                                    <div className="flex flex-col gap-3 mt-3 ml-3">
                                        {
                                            Object.keys(goalType.goalDetails).map(strategy => (
                                                <div  key={strategy}>
                                                    <div tabIndex={0} className="flex flex-col gap-2 text-gray-600 dark:text-gray-200">
                                                        <div className="flex items-center gap-2 font-semibold">
                                                            <div>{goalType.goalDetails[strategy].strategyName}</div>
                                                        </div>
                                                        {(goalType.goalDetails[strategy].desiredOutcome || showOutcome(goalType, strategy)) &&
                                                         <div>
                                                         <textarea disabled={true} placeholder={t("Desired Outcomes")} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none rounded-t-lg ...`} value={goalType.goalDetails[strategy].desiredOutcome} setValue={val => setOutcome(goalType, strategy, val)} />
                                                     </div>
                                                            // <div>
                                                            //     <InlineTextField label={t("Desired Outcomes")} edit={true} value={goalType.goalDetails[strategy].desiredOutcome} setValue={val => setOutcome(goalType, strategy, val)} />
                                                            // </div>
                                                        }
                                                        <div>
                                                            <div>
                                                                {
                                                                    Object.keys(goalType.goalDetails[strategy].strategyDetails).map(category => (
                                                                        <div key={category}>
                                                                            {
                                                                                Object.keys(goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails).map(type => (
                                                                                    <div key={type}>
                                                                                        {goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.length > 0 &&
                                                                                            <div className="flex flex-col gap-4 pl-3">
                                                                                                {/* <div className="text-sm font-bold mt-1 -mb-2 text-gray-500 dark:text-gray-300">{t('These support service are to help you adjust or change your personal rules')}</div> */}
                                                                                                <AnimatePresence>
                                                                                                    {
                                                                                                        goalType.goalDetails[strategy].strategyDetails[category].goalTypeDetails[type].services.map((item, i) => (
                                                                                                            <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" key={i} className="relative ring-1 ring-gray-400 dark:ring-gray-600 rounded-lg pl-3 py-2">
                                                                                                                {item.info && item.info !== '' && <div className="font-bold text-gray-600 dark:text-tcolor">{item.info}</div>}
                                                                                                                {/* {item.value.content && item.value.content !== '' && <div className="font-bold text-tcolor">{item.value.content}</div>} */}
                                                                                                                <div className="text-sm mt-1">
                                                                                                                    {item.address && item.address !== '' &&
                                                                                                                        <>
                                                                                                                            <div>{item.address}</div>
                                                                                                                            <div>{`${item.city}, ${item.state} ${item.zipCode}`}</div>
                                                                                                                        </>
                                                                                                                    }
                                                                                                                    {item.phone && item.phone !== '' && item.phone !== '-' && <div>{`${item.phone}`}</div>}
                                                                                                                    {/* {item.webLink && item.webLink !== '' && item.webLink !== '-' && <div><a className="underline" target="_balnk" rel="norefferer" href={`http://${item.webLink}`}>{`${item.webLink}`}</a></div>} */}
                                                                                                                    {item.webLink && item.webLink !== '' && <div><a className="underline" target="_blank" rel="noopener noreferrer" href={(item.webLink.indexOf("http://") === 0 || item.webLink.indexOf("https://") === 0 ||  item.webLink.indexOf("HTTP://") === 0 ||  item.webLink.indexOf("HTTPS://") === 0) ? `${item.webLink}` : `https://${item.webLink}`}>{`${item.webLink !== '-' ? item.webLink :''}`}</a></div>}
                                                                                                                    <div className="mr-3 mt-3">
                                                                                                                        <textarea disabled={true} placeholder={t('Comment')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={item.comment || ''} onChange={ev => setComment(ev.target.value, item, i)} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {edit &&
                                                                                                                    <div className="absolute top-2 right-3 p-0.5 rou nded-full bg-red-500 text-white cursor-pointer" title={t('Remove')} onClick={() => removeItem(item)}>
                                                                                                                        <IoMdTrash size="20px" />
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </motion.div>
                                                                                                        ))
                                                                                                    }
                                                                                                </AnimatePresence>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <motion.section aria-label={'Additional Resources and Services'} className="form-wrap py-4 m-0">
                            <div className="text-lg font-bold text-gray-700 dark:text-white">
                                {'Additional Resources and Services'}
                            </div>
                            <div className="flex flex-col gap-3 mt-3 ml-3">
                                <div className="flex flex-col gap-2 text-gray-600 dark:text-gray-200">
                                    <div className="flex items-center gap-2 font-semibold">
                                        <div>{'Add Support Resources and Services'}</div>
                                    </div>
                                 
                                    {supportService !== undefined && supportService.length > 0 && <div className="flex flex-col gap-4 pl-3">
                                                    {/* <div className="text-sm font-bold mt-1 -mb-2 text-gray-500 dark:text-gray-300">{t('These support service are to help you adjust or change your personal rules')}</div> */}
                                                    <AnimatePresence>
                                                        {
                                                           supportService.map((item, i) => (
                                                                <>
                                                                    {
                                                                        !item.deleted && <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" key={i} className="relative ring-1 ring-gray-400 dark:ring-gray-600 rounded-lg pl-3 py-2">
                                                                            <div><textarea disabled={true} placeholder={t("Desired Outcomes")} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none rounded-t-lg ...`} value={item.desiredOutcome} setValue={val => setOutcome(goalType, strategy, val)} /></div>
                                                                            {item.info && item.info !== '' && <div className="font-bold text-gray-600 dark:text-tcolor">{item.info}</div>}
                                                                            {/* {item.value.content && item.value.content !== '' && <div className="font-bold text-tcolor">{item.value.content}</div>} */}
                                                                            <div className="text-sm mt-1">
                                                                                {item.address && item.address !== '' &&
                                                                                    <>
                                                                                        <div>{item.address}</div>
                                                                                        <div>{`${item.city}, ${item.state} ${item.zipCode}`}</div>
                                                                                    </>
                                                                                }
                                                                                {item.phone && item.phone !== '' && <div>{`${item.phone !== '-' ? item.phone : ''}`}</div>}
                                                                                {item.webLink && item.webLink !== '' && <div><a className="underline" target="_blank" rel="noopener noreferrer" href={(item.webLink.indexOf("http://") === 0 || item.webLink.indexOf("https://") === 0 || item.webLink.indexOf("HTTP://") === 0 || item.webLink.indexOf("HTTPS://") === 0) ? `${item.webLink}` : `https://${item.webLink}`}>{`${item.webLink !== '-' ? item.webLink : ''}`}</a></div>}
                                                                                <div className="mr-3 mt-3">
                                                                                    <textarea placeholder={t('Comment')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={item.comment || ''} onChange={ev => setAdditionalComment(ev.target.value, i)}/>
                                                                                </div>
                                                                            </div>
                                                                        </motion.div>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </AnimatePresence>
                                                </div>}
                                </div>
                            </div>
                        </motion.section>
                        <div className="form-wrap py-4 m-0">
                            <div className="text-lg font-bold text-gray-600 dark:text-white mb-2">
                                {t("Caregiver's Responsibilities")}
                            </div>
                            <div>
                                <textarea disabled={true} placeholder={t('Type Here')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={careplanData.cgResponsibility || ''} onChange={ev => setCgRespone(ev.target.value)} />
                            </div>
                            <div className="text-lg font-bold text-gray-600 dark:text-white mb-2">
                                {t('Care Manager Responsibilities')}
                            </div>
                            <div>
                                <textarea disabled={true} placeholder={t('Type Here')} className={`pb-1.5 pl-3 pt-2 text-sm bg-gray-300 dark:bg-ldark text-black dark:text-white pr-10 w-full rounded h-[90%] outline-none`} value={careplanData.cmResponsibility || ''}/>
                            </div>
                            <div className="form-wrap py-4 m-0">
                                <div className="text-lg font-bold text-gray-600 dark:text-white mb-2">
                                    {t('Followup Date')}
                                </div>
                                <div>
                                    <FlowText text={`${follow(careplanData.followupDate)}`}/>
                                </div>
                            </div>
                        </div>
                        {edit &&
                            <div className="flex gap-4 justify-end mr-4">
                                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-tcolor hover:bg-opacity-80 text-white" onClick={() => saveCareplan()}>{t('save')}</button>
                                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => finalize()}>{t('Finalize Care Plan')}</button>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                downloadPopup && 
                createPortal(<div role="dialog">
                    <div ref={downloadRef} role="dialog" tabIndex={-1} aria-modal="true"></div>
                <CareplanDialog prefLanguage={careplanData.prefLanguage} active={downloadPopup} onClose={() => setDownloadPopup(false)} assessmentId={match.params.assessmentId} caseId={match.params.caseId} careplanId={match.params.careplanId} type={'create'} /> 
                    </div>, document.body)
            }
            {/* <ServiceSelector active={showServiceSelector} categories={selectorDetails.categories} adhoc={careplanData.caseGoals} zip={careplanData.zip} onClose={() => setShowServiceSelector(false)} onAdd={(category, type, val) => serviceAdded(category, type, val)} onAddCategoryId={(val) => addCategoryId(val)} />
            <Dialog showDialog={showFinalize} title={t('Finalize Careplan')} onClose={() => setShowFinalize(false)}>
                <ConfirmFinalize onClose={() => setShowFinalize(false)} onFinalize={finalizeCarePlan} />
            </Dialog> */}
        </div>
    )
}

export default withRouter(Careplan)