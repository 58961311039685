import { useTranslation } from "react-i18next"

const ReleaseNotes = () => {
    
    const { t } = useTranslation()

    return (
        <div>
             <div className="text-lg font-medium text-gray-500 dark:text-gray-500 underline flex items-center">
                            <h1> {t("Release Version")} - 5.58.0</h1>
                        </div>
           {/* <p class="font-black text-center..."> Work in Progress</p> */}
        </div>
         
   
    )
}

export default ReleaseNotes