
import { useFormik } from "formik"
import { useState, useMemo, useEffect, useLayoutEffect } from "react"
import * as Yup from 'yup'
import { useTranslation } from "react-i18next"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import { useHistory } from 'react-router-dom';
import API from '../../axios/API';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux"
import DemographicLoader from "./DemographicLoader"
import Button from "../../components/snippets/Button"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import { setAlert } from "../../store/theme/actions"
import FieldMultiSelect from "../../components/snippets/FieldMultiSelect"
import UserMultiSelect from "../../components/snippets/UserMultiSelect"
import AddRefInput from "../../components/snippets/AddRefInput"


const initalFollowupValue = {
    followupDate: '',
    notes: '',
    reason: '',
}
// const otherInitialValues ={
//     otherRelationship : ''
// }

const CreateCase = ({ match }) => {

    const { t } = useTranslation()
    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const userDetails = useSelector(state => state.auth.userDetails)
    const [relationShip, setRelationship] = useState([])
    const [language, setLanguage] = useState([])
    const [loading, setLoading] = useState(false)
    const [followupReason, setFollowupReason] = useState([])
    const [initialData, setInitialData] = useState({ careReceiver: {race:[]}, careGiver: {race:[]}})
    const organization = useSelector(state => state.auth.organization)
    const history = useHistory()
    const [crLength, setCrLength] = useState()
    const [cgLength, setCgLength] = useState()
    const [cgRace,setCgRace] = useState([])
    const [crRace,setCrRace] = useState ([])
    const [both, setBoth] = useState(false)
    const [inputEnable, setInputEnable] = useState(false)
    const [crRefused,setCrRefused] = useState(false)
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    const [zipEnable, setZipEnable] = useState(false)
    const [crEnable,setCrEnable]= useState(false)
    let orgId = localStorage.getItem('orgid')
    let subId = localStorage.getItem('subId')
    const [subreq, setSubReq] = useState(false)
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    let prgName = localStorage.getItem('prgname')
    let assessType1 = localStorage.getItem('assessType')
    let pcsp = localStorage.getItem('pcsp')
    const [programName1, setProgramName1]=useState(false)
    const [datePscp, setDatePscp]=useState(false)
    const [assessmentTypeId1,setAssessmentTypeId1] = useState(false)
    const [programName, setProgramName] = useState([])
    const [assessmentType, setAssessmentType] = useState([])
    const [errWarning,setErrWarning] = useState(false)
    const [errWarning1,setErrWarning1] = useState(false)
    const [errWarning2,setErrWarning2] = useState(false)
    const [customsubWarn,setCustomSubWarn] = useState()
    const [customsubWarn1,setCustomSubWarn1] = useState()
    const [customsubWarn2,setCustomSubWarn2] = useState()
    const [option,setoption] = useState()
    const [saveContinue,setSaveContinue] = useState(false)

    const currentRole = userDetails && userDetails.role.includes("referrer")
    const [subAsian,setSubAsians] = useState([])
    const [subNative,setSubNative] = useState([])
    const [otherAsianString,setOtherAsianString] = useState(false)
    const [otherNativeString,setOtherNativeString] = useState(false)
    const [otherAsianStringBothCg,setOtherAsianStringBothCg] = useState(false)
    const [otherNativeStringBothCg,setOtherNativeStringBothCg] = useState(false)
    const [otherAsianStringForCaregiver,setOtherAsianStringForCaregiver] = useState(false)
    const [otherNativeStringForCaregiver,setOtherNativeStringForCaregiver] = useState(false)
    const [otherAsianStringForCareReceiver,setOtherAsianStringForCareReceiver] = useState(false)
    const [otherNativeStringForCareReceiver,setOtherNativeStringForCareReceiver] = useState(false)
    const [otherNativeErr,setOtherNativeErr] = useState(false)
    const [otherAsianErr,setOtherAsianErr] = useState(false)
    const [otherNativeErr1,setOtherNativeErr1] = useState(false)
    const [otherAsianErr1,setOtherAsianErr1] = useState(false)
    const [otherAsianForCareReceiver,setOtherAsianForCareReceiver] = useState([])
    const [otherNativeForCareReceiver,setOtherNativeForCareReceiver] = useState([])
    const [otherAsianForCargiver,setOtherAsianForCargiver] = useState([])
    const [otherNativeForCaregiver,setOtherNativeForCaregiver] = useState([])
    const [otherAsian,setOtherAsian] = useState([])
    const [otherNative,setOtherNative] = useState([])
    const [otherAsianBothCg,setOtherAsianBothCg] = useState([])
    const [otherNativeBothCg,setOtherNativeBothCg] = useState([])
    const OTHER_ASIAN = 20;
    const OTHER_NATIVE = 26;
    let lang= localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)
    const [tempCrSub,setTempCrSub] = useState()

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            // return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
            return (value.replace(/[- ]/g, '').length === 10)
        })
    })
    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'LengthSubId', function(){
        return this.test(t('SubscriberId must be the length of either 9 or 14'),t('SubscriberId must be the length of either 9 or 14'),(value)=>{
            if(value === undefined || value == '' || value.length === 0 || value === null || value.length === 9 || value.length === 14) return true
            if(value.length >= 10 && value.length < 14) return false  
        })
    })
    // useEffect(() => {
    //    const stringPcspDate = history?.location?.state?.assDetail.pcspDate
    //     setAssessPcsp((stringPcspDate !== null ?stringPcspDate.split('-')[0] + "/" + stringPcspDate.split('-')[1] + "/" + stringPcspDate.split('-')[2]:'') || '')
    // }, [])//eslint-disable-line

    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if(birthyear === 'true'){
            setBirthYear1(true)
        }
        setLoading(true)
        if(menuItems && menuItems !== null && menuItems !== '' && menuItems !== undefined){
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined) {
                setGender(menuItems.gender.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/genders?lang=${lang}`).then((gendersResp) => {
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.race && menuItems.race.length > 0 && menuItems.race !== undefined) {
                setCrRace(menuItems.race.map((c) => ({ text: c.name, value: c.value })))
                setCgRace(menuItems.race.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/races?lang=${lang}`).then((raceResp) => {
                    setCrRace(raceResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                    setCgRace(raceResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.relationship && menuItems.relationship.length > 0 && menuItems.relationship !== undefined) {
                setRelationship(menuItems.relationship.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/relationships?lang=${lang}`).then((relationshipResp) => {
                    setRelationship(relationshipResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if(menuItems.followupReason && menuItems.followupReason.length > 0 && menuItems.followupReason !== undefined){
                setFollowupReason(menuItems.followupReason.map((c)=>({text:c.display,value:c.optionId})))
            }else{
                API.get(`options/follow-up-reasons?lang=${lang}`).then((followupResp)=>{
                    setFollowupReason(followupResp.data.map((c)=>({text:c.display,value:c.optionId})))
                })
            }
            if (menuItems.language && menuItems.language.length > 0 && menuItems.language !== undefined) {
                setLanguage(menuItems.language.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/languages?lang=${lang}`).then((languageResp) => {
                    setLanguage(languageResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.programName && menuItems.programName.length > 0 && menuItems.programName !== undefined) {
                setProgramName(menuItems.programName.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-programName?lang=${lang}`).then((programNameId) => {
                    setProgramName(programNameId.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.assessmentType && menuItems.assessmentType.length > 0 && menuItems.assessmentType !== undefined) {
                setAssessmentType(menuItems.assessmentType.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-assessmentType?lang=${lang}`).then((assessmentTypeId) => {
                    setAssessmentType(assessmentTypeId.data.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.subRaces && menuItems.subRaces !== null && menuItems.subRaces !== '' && menuItems.subRaces !== undefined) {
                setSubAsians(menuItems.subRaces.subAsianRace.map((c) => ({ text: c.name, value: c.value })))
                setSubNative(menuItems.subRaces.subHawaiianRace.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/subraces?lang=${lang}`).then((subRaces) => {
                    setSubAsians(subRaces.data.body.subAsianRace.map(c => ({ text: c.name, value: c.value })))
                    setSubNative(subRaces.data.body.subHawaiianRace.map(c => ({ text: c.name, value: c.value })))
                })
            }
        }else{
            axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/races?lang=${lang}`),
            API.get(`menus/relationships?lang=${lang}`), API.get(`options/follow-up-reasons?lang=${lang}`),API.get(`menus/languages?lang=${lang}`),API.get(`menus/case-programName?lang=${lang}`),API.get(`menus/case-assessmentType?lang=${lang}`),API.get(`menus/subraces?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp,
                raceResp, relationShipResp, followupResp,languageResp,programNameId,assessmentTypeId,subRaces) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setCrRace(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setCgRace(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setRelationship(relationShipResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setFollowupReason(followupResp.data.map(c => ({ text: c.display, value: c.optionId })))
                setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setProgramName(programNameId.data.body.map(c => ({ text: c.name, value: c.value })))
                setAssessmentType(assessmentTypeId.data.body.map(c => ({ text: c.name , value: c.value})))
                setSubAsians(subRaces.data.body.subAsianRace.map(c=>({ text:c.name, value: c.value})))
                setSubNative(subRaces.data.body.subHawaiianRace.map(c=>({ text:c.name, value: c.value})))
            }))
        }
        setYears(years.map(c=>({text:c,value:c})))
                if(subId === 'true'){
                    setSubReq(true)
                }
                if(prgName === 'true'){
                    setProgramName1(true)
                }
                if(pcsp === 'true'){
                    setDatePscp(true)
                }
                if(assessType1 === 'true'){
                    setAssessmentTypeId1(true)
                }
                if(orgEthnicityEnable === 'true'){
                    setOrgRaceEnabl(true)
                }
    }, [assessType1, birthyear, pcsp, prgName, subId])//eslint-disable-line

    useEffect(() => {
        if (match.params.careReceiverId !== "0" && match.params.careGiverId !== "0") {
            axios.all([API.get(`/care-receivers/${match.params.careReceiverId}`), API.get(`/caregivers/${match?.params?.careGiverId}`),API.get(`organizations/${orgId}/cases/${match?.params?.caseId}/relationship`)]).then(axios.spread((careReceiverResp, careGiverResp,caseResp) => {
                if (careReceiverResp.status === 200 && careGiverResp.status === 200 && caseResp.status === 200) {
                    setDataLoaded(true)
                    setFormLoading(false)
                    let tempdata = careReceiverResp.data                    
                    if(tempdata.dobYear === 0 && tempdata.dobMonth === 0 && tempdata.dobDay === 0){
                        setCrRefused(true)
                    }
                    if(birthyear === 'true' && tempdata.dobYear !== 0){
                        tempdata.dateOfBirth =  tempdata.dobYear + "/" + tempdata.dobMonth + "/" + tempdata.dobDay
                    }else if (tempdata.dobYear !== 0) {
                        tempdata.dateOfBirth =  tempdata.dobYear
                    }else {
                        tempdata.dateOfBirth = ''
                    }
                    if (tempdata.dateOfBirth === undefined) {
                        tempdata.dateOfBirth = ''
                    }
                    if(tempdata.assessTypeId === 0){
                        tempdata.assessTypeId = ''
                       }  
                    tempdata.race = []
                    if (tempdata.white === true) {
                        tempdata.race.push(1)
                    } 
                    if (tempdata.black === true) {
                        tempdata.race.push(2)
                    } 
                    if (tempdata.hispanic === true) {
                        tempdata.race.push(3)
                    } 
                    if (tempdata.nativeAmerican === true) {
                        tempdata.race.push(5)
                    } 
                    if (tempdata.middleEastern === true) {
                        tempdata.race.push(7)
                    } 
                    if (tempdata.otherRace === true) {
                        tempdata.race.push(8)
                    } 
                    if (tempdata.refused === true) {
                        tempdata.race.push(9)
                    } 
                    if(tempdata.ethnicity !== undefined && orgEthnicityEnable === 'true'){
                        tempdata.ethnicity.map((c,i)=>{
                            tempdata.race.push(c)
                            if(c >= 10 && c<= 29){
                                setOtherAsian((prevValue)=>[...prevValue,c])
                            }
                            if(c >= 30 && c<= 35){
                                setOtherNative((prevValue)=>[...prevValue,c])
                            }
                        })
                    }
                    if(tempdata.address.postalCode !== ""){
                        setCrEnable(true)
                    }
                    if(tempdata.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                        setOtherAsianString(true)
                    }
                    if(tempdata.otherAsianRace.otherSubRaceId === OTHER_NATIVE){
                        setOtherNativeString(true)
                    }
                    let tempdata1 = careGiverResp.data
                    if(tempdata1.dobYear === 0 && tempdata1.dobMonth === 0 && tempdata1.dobDay === 0){
                        setCgRefused(true)
                    }
                    tempdata1.relationShip = (caseResp.data && caseResp.data!==0 ? caseResp.data : '')
                   
                    if(birthyear === 'true'&& tempdata1.dobYear !== 0){
                        tempdata1.dateOfBirth =  tempdata1.dobYear + "/" + tempdata1.dobMonth + "/" + tempdata1.dobDay
                    }else if (tempdata1.dobYear !== 0) {
                        tempdata1.dateOfBirth =  tempdata1.dobYear
                    } else {
                        tempdata1.dateOfBirth = ''
                    }
                    if (tempdata1.dateOfBirth === undefined) {
                        tempdata1.dateOfBirth = ''
                    }
                    if(tempdata1.pcspDate === null){
                        tempdata1.pcspDate = ''
                    }
                    if(tempdata1.pgNameId === 0){
                        tempdata1.pgNameId = ''
                    }
                   
                    tempdata1.phoneNumber = ''
                    tempdata1.race =[]
                    if (tempdata1.white === true) {
                        tempdata1.race.push(1)
                    } 
                    if (tempdata1.black === true) {
                        tempdata1.race.push(2)
                    } 
                    if (tempdata1.hispanic === true) {
                        tempdata1.race.push(3)
                    } 
                    if (tempdata1.asian === true) {
                        tempdata1.race.push(4)
                    }
                    if (tempdata1.nativeAmerican === true) {
                        tempdata1.race.push(5)
                    } 
                    if (tempdata1.middleEastern === true) {
                        tempdata1.race.push(7)
                    } 
                    if (tempdata1.otherRace === true) {
                        tempdata1.race.push(8)
                    } 
                    if (tempdata1.refused === true) {
                        tempdata1.race.push(9)
                    } 
                    if(tempdata1.ethnicity !== undefined && orgEthnicityEnable === 'true'){
                        tempdata1.ethnicity.map((c,i)=>{
                            tempdata1.race.push(c)
                            if(c >= 10 && c<= 29){
                                setOtherAsianBothCg((prevValue)=>[...prevValue,c])
                            }
                            if(c >= 30 && c<= 35){
                                setOtherNativeBothCg((prevValue)=>[...prevValue,c])
                            }
                        })
                    }
                    if(tempdata1.relationShip === 14 || tempdata1.relationshipId === 14){
                        setInputEnable(true)
                    }
                    if(tempdata1.address.postalCode !== ""){
                        setZipEnable(true)
                    }
                    if(tempdata1.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                        setOtherAsianStringBothCg(true)
                    }
                    if(tempdata1.otherAsianRace.otherSubRaceId === OTHER_NATIVE){
                        setOtherNativeStringBothCg(true)
                    }
                    setInitialData({ careReceiver: tempdata, careGiver: tempdata1 })
                    setLoading(false)
                    setBoth(true)
                }
            }))
        } 
        else if (match.params.careGiverId === "0") {
            // if(noCaregiver === 'true'){
            //     history.location?.state?.cgNew === true ? common(false) : common(true)
            // }
            axios.all([API.get(`organizations/${orgId}/cases/${match?.params?.caseId}/relationship`),API.get(`care-receivers/${match.params.careReceiverId}`)]).then(axios.spread((caseResp,res)=>{
                if (res.status === 200) {
                    setDataLoaded(true)
                    setFormLoading(false)
                    let tempdata = res.data
                    if(tempdata.dobYear === 0 && tempdata.dobMonth === 0 && tempdata.dobDay === 0){
                        setCrRefused(true)
                    }
    
                    if(birthyear === 'true' && tempdata.dobYear !== 0 ){
                        tempdata.dateOfBirth =  tempdata.dobYear + "/" + tempdata.dobMonth + "/" + tempdata.dobDay
                    }else if (tempdata.dobMonth !== 0 && tempdata.dobYear !== 0 && tempdata.dobDay !== 0) {
                        tempdata.dateOfBirth =  tempdata.dobYear
                    } else {
                        tempdata.dateOfBirth = ''
                    }
                    if (tempdata.dateOfBirth === undefined) {
                        tempdata.dateOfBirth = ''
                    }
                   if(tempdata.address.postalCode !== ""){
                       setCrEnable(true)
                   }
                   if(tempdata.assessTypeId === 0){
                    tempdata.assessTypeId = ''
                   }                    
                    tempdata.race = []
                    if (tempdata.white === true) {
                        tempdata.race.push(1)
                    } 
                    if (tempdata.black === true) {
                        tempdata.race.push(2)
                    } 
                    if (tempdata.hispanic === true) {
                        tempdata.race.push(3)
                    } 
                    if (tempdata.nativeAmerican === true) {
                        tempdata.race.push(5)
                    } 
                    if (tempdata.middleEastern === true) {
                        tempdata.race.push(7)
                    } 
                    if (tempdata.otherRace === true) {
                        tempdata.race.push(8)
                    } 
                    if (tempdata.refused === true) {
                        tempdata.race.push(9)
                    } 
                    if(tempdata.ethnicity !== undefined && orgEthnicityEnable === 'true'){
                        tempdata.ethnicity.map((c,i)=>{
                            tempdata.race.push(c)
                            if(c >= 10 && c<= 29){
                                setOtherAsianForCareReceiver((prevValue)=>[...prevValue,c])
                            }
                            if(c >= 30 && c<= 35){
                                setOtherNativeForCareReceiver((prevValue)=>[...prevValue,c])
                            }
                        })
                    }
                    if(tempdata.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                        setOtherAsianStringForCareReceiver(true)
                    }
                    if(tempdata.otherAsianRace.otherSubRaceId === OTHER_NATIVE){
                        setOtherNativeStringForCareReceiver(true)
                    }
                    let races = []
                    setInitialData({
                        careReceiver: tempdata, careGiver: {
                            firstName: '',
                            lastName: '',
                            address: {
                                addressLine1: '',
                                state: '',
                                city: '',
                                postalCode: '',
                            },
                            emailAddress: '',
                            subscriberId:'',
                            phone: '',
                            dobDay: '',
                            dobYear: '',
                            dobMonth: '',
                            dateOfBirth: '',
                            gender: '',
                            race : races,
                            relationShip : (caseResp.data && caseResp.data!==0 ? caseResp.data :''),
                            otherRelationship:'',
                            pgName:'',
                            pgNameId:'',
                            // pcspDate : history.location?.state?.cgNew === true ? history.location?.state?.assDetail?.pcspDate : (tempdata.pcspDate !== null ? tempdata.pcspDate.split('-')[0] + "/" + tempdata.pcspDate.split('-')[1] + "/" + tempdata.pcspDate.split('-')[2]:'') || '',
                            pcspDate : (pcsp === 'true' && tempdata.pcspDate !== null) ? tempdata.pcspDate.split('-')[0] + "/" + tempdata.pcspDate.split('-')[1] + "/" + tempdata.pcspDate.split('-')[2]:'',
                            assessTypeId:'',
                            otherAsianRace:{
                                otherSubRaceId:'',
                                otherSubRaceValue:'',
                                raceId:''
                            },
                            otherNativeRace:{
                                otherSubRaceId:'',
                                otherSubRaceValue:'',
                                raceId:''
                            },
                            noCaregiver: history.location?.state?.cgNew === true ? false : true
                        }
                    })
                    setLoading(false)
                    // if(history.location?.state?.cgNew === true) {
                    //     setBoth(true)
                    //     common(false)
                    // }else {
                    //     setBoth(false)
                    //     common(true)
                    // }
                    setBoth(false)
                }
            }))
        } else if (match.params.careReceiverId === "0") {
            axios.all([API.get(`organizations/${orgId}/cases/${match?.params?.caseId}/relationship`),API.get(`caregivers/${match.params.careGiverId}`)]).then(axios.spread((caseResp,res)=>{

                if (res.status === 200) {
                    setDataLoaded(true)
                    setFormLoading(false)
                    let tempdata1 = res.data
                    if(tempdata1.dobYear === 0 && tempdata1.dobMonth === 0 && tempdata1.dobDay === 0){
                        setCgRefused(true)
                    }
                    if(birthyear === 'true' && tempdata1.dobYear !== 0){
                        tempdata1.dateOfBirth = tempdata1.dobYear + "/" + tempdata1.dobMonth + "/" + tempdata1.dobDay
                    }else if (tempdata1.dobDay !== 0 && tempdata1.dobMonth !== 0 && tempdata1.dobYear !== 0) {
                        tempdata1.dateOfBirth = tempdata1.dobYear
                    } else {
                        tempdata1.dateOfBirth = ''
                    }
                    if (tempdata1.dateOfBirth === undefined) {
                        tempdata1.dateOfBirth = ''
                    }
                    if(tempdata1.pcspDate === null){
                        tempdata1.pcspDate = ''
                    }
                    if(tempdata1.pgNameId === 0){
                        tempdata1.pgNameId = ''
                    }
                   if(tempdata1.address.postalCode !== ""){
                       setZipEnable(true)
                   }
                    tempdata1.phoneNumber = ''
                    tempdata1.race = []
                    // tempdata1.map(c=>{
                        if (tempdata1.white === true) {
                            tempdata1.race.push(1)
                        } 
                        if (tempdata1.black === true) {
                            tempdata1.race.push(2)
                        } 
                        if (tempdata1.hispanic === true) {
                            tempdata1.race.push(3)
                        } 
                        if (tempdata1.nativeAmerican === true) {
                            tempdata1.race.push(5)
                        } 
                        if (tempdata1.middleEastern === true) {
                            tempdata1.race.push(7)
                        } 
                        if (tempdata1.otherRace === true) {
                            tempdata1.race.push(8)
                        } 
                        if (tempdata1.refused === true) {
                            tempdata1.race.push(9)
                        } 
                        if(tempdata1.ethnicity !== undefined && orgEthnicityEnable === 'true'){
                            tempdata1.ethnicity.map((c,i)=>{
                                tempdata1.race.push(c)
                                if(c >= 10 && c<= 29){
                                    setOtherAsianForCargiver((prevValue)=>[...prevValue,c])
                                }
                                if(c >= 30 && c<= 35){
                                    setOtherNativeForCareReceiver((prevValue)=>[...prevValue,c])
                                }
                            })
                        }
                       
                        if(tempdata1.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                            setOtherAsianStringForCaregiver(true)
                        }
                        if(tempdata1.otherAsianRace.otherSubRaceId === OTHER_NATIVE){
                            setOtherNativeStringForCaregiver(true)
                        }
                    // })
                    tempdata1.relationShip = (caseResp.data && caseResp.data!==0 ? caseResp.data : '')
                    let races = []
                    setInitialData({
                        careReceiver: {
                            address: {
                                addressLine1: '',
                                state: '',
                                city: '',
                                postalCode: '',
                            },
                            firstName: '',
                            lastName: '',
                            emailAddress: '',
                            subscriberId: '',
                            phone: '',
                            dateOfBirth: '',
                            dobDay: '',
                            dobYear: '',
                            dobMonth: '',
                            gender: '',
                            pgName:'',
                            pgNameId:'',
                            pcspDate :'',
                            assessType:'',
                            assessTypeId:'',
                            race : races,
                            otherAsianRace:{
                                otherSubRaceId:'',
                                otherSubRaceValue:'',
                                raceId:''
                            },
                            otherNativeRace:{
                                otherSubRaceId:'',
                                otherSubRaceValue:'',
                                raceId:''
                            },
                        }, careGiver: tempdata1
                    })
                    if(tempdata1.relationShip === 14 || tempdata1.relationshipId === 14){
                        setInputEnable(true)
                    }
                    setLoading(false)
                    setBoth(false)
                }
            }))
        }
    }, [birthyear, match.params.careGiverId, match.params.careReceiverId, match.params?.caseId])// eslint-disable-line
    const followupData = useMemo(() => {
        setDataLoaded(true)
        return { ...initalFollowupValue }
    }, []) // eslint-disable-line
    
    const profileFormSchema = cgRefused && crRefused ? Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county:Yup.string(),
                postalCode: currentRole ? Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")) : Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            phone: Yup.string().validatePhoneLength(),
            dateOfBirth: Yup.date(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string(),
            race: Yup.array(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        }),
        careGiver:
            Yup.object({
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace().nullable(),
                    state: Yup.string(),
                    city: Yup.string(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t('Zip code is a required field')),
                }),
                emailAddress: Yup.string().validateEmail(),
                phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")),
                dateOfBirth: Yup.date(),
                dobDay: Yup.number(),
                dobYear: Yup.number(),
                dobMonth: Yup.number(),
                gender: Yup.string(),
                race: Yup.array(),
                relationShip: Yup.string().nullable().validateName().required(t("Relationship is a required field")),
                pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable()
                otherAsianRace:Yup.object({
                    otherSubRaceValue:otherAsianStringBothCg ? Yup.string().EmptySpace().nullable().otherRelation().required(t('Required')) : Yup.string().nullable()
                }),
                otherNativeRace:Yup.object({
                    otherSubRaceValue:otherNativeStringBothCg ?  Yup.string().EmptySpace().nullable().otherRelation().required(t('Required')) : Yup.string().nullable()
                })
            }),
    }) : cgRefused && !crRefused ? Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: currentRole ? Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")) : Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            phone: Yup.string().validatePhoneLength(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string(),
            race: Yup.array(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        }),
        careGiver:
            Yup.object({
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace().nullable(),
                    state: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
                }),
                emailAddress: Yup.string().validateEmail().nullable(),
                phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")).nullable(),
                dobDay: Yup.number().nullable(),
                dobYear: Yup.number().nullable(),
                dobMonth: Yup.number().nullable(),
                dateOfBirth: Yup.date().nullable(),
                gender: Yup.string().nullable(),
                race: Yup.array().nullable(),
                relationShip: Yup.string().nullable().validateName().required(t("Relationship is a required field")).nullable(),
                pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable()
                otherAsianRace:Yup.object({
                    otherSubRaceValue:otherAsianStringBothCg ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                }),
                otherNativeRace:Yup.object({
                    otherSubRaceValue:otherNativeStringBothCg ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                })
            }),
    }) : !cgRefused && crRefused ? Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string().nullable(),
                city: Yup.string().nullable(),
                county: Yup.string().nullable(),
                postalCode: currentRole ? Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")) : Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            phone: Yup.string().validatePhoneLength(),
            dateOfBirth: Yup.date(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string(),
            race: Yup.array(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().otherRelation().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().otherRelation().required(t('Required')) : Yup.string().nullable()
            })
        }),
        careGiver:
            Yup.object({
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace().nullable(),
                    state: Yup.string().nullable(),
                    city: Yup.string().nullable(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
                }),
                emailAddress: Yup.string().validateEmail().nullable(),
                phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")).nullable(),
                dobDay: Yup.number().nullable(),
                dobYear: Yup.number().nullable(),
                dobMonth: Yup.number().nullable(),
                dateOfBirth: Yup.date().required(t("Please provide the required details")).nullable(),
                gender: Yup.string().nullable(),
                race: Yup.array().nullable(),
                relationShip: Yup.string().nullable().validateName().required(t("Relationship is a required field")).nullable(),
                pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable()
                otherAsianRace:Yup.object({
                    otherSubRaceValue:otherAsianStringBothCg ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                }),
                otherNativeRace:Yup.object({
                    otherSubRaceValue:otherNativeStringBothCg ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                })
            }),
    }) : !cgRefused && !crRefused && Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string().nullable(),
                city: Yup.string().nullable(),
                county: Yup.string().nullable(),
                postalCode: currentRole ? Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")) : Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("Zip Code is a required field")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail().nullable(),
            phone: Yup.string().validatePhoneLength().nullable(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")).nullable(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string().nullable(),
            race: Yup.array().nullable(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianString ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeString ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        }),
        careGiver:
            Yup.object({
                firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace().nullable(),
                    state: Yup.string(),
                    city: Yup.string(),
                    postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t('Zip code is a required field')),
                }),
                emailAddress: Yup.string().validateEmail(),
                phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")),
                dobDay: Yup.number(),
                dobYear: Yup.number(),
                dobMonth: Yup.number(),
                dateOfBirth: Yup.date().required(t("Please provide the required details")),
                gender: Yup.string(),
                race: Yup.array(),
                relationShip: Yup.string().nullable().validateName().required(t("Relationship is a required field")),
                pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
                // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable()
                otherAsianRace:Yup.object({
                    otherSubRaceId:Yup.string().nullable(),
                    otherSubRaceValue:otherAsianStringBothCg ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                }),
                otherNativeRace:Yup.object({
                    otherSubRaceId: Yup.string().nullable(),
                    otherSubRaceValue:otherNativeStringBothCg ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
                })
            }),
    })

    const careReceiverFormSchema = crRefused ? Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string().nullable(),
                city: Yup.string().nullable(),
                county: Yup.string().nullable(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail().nullable(),
            phone: Yup.string().validatePhoneLength().nullable(),
            dateOfBirth: Yup.date().nullable(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string().nullable(),
            race: Yup.array().nullable(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianStringForCareReceiver ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeStringForCareReceiver ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })  
        })
    }) :Yup.object().shape({
        careReceiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string().nullable(),
                city: Yup.string().nullable(),
                county: Yup.string().nullable(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).nullable(),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail().nullable(),
            phone: Yup.string().validatePhoneLength().nullable(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")).nullable(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string().nullable(),
            race: Yup.array().nullable(),
            subscriberId: subreq && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")) : !subreq && assessmentTypeId1&& userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[0-9]*$/,t("Subscriber Id should be numeric value")) : subreq && userDetails.env !=='MOLINA'  ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessTypeId: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")): Yup.string(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianStringForCareReceiver ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeStringForCareReceiver ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        })
    })

    const careGiverFormSchema = cgRefused ? Yup.object().shape({
        careGiver: Yup.object({
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string().nullable(),
                city: Yup.string().nullable(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t('Zip code is a required field')).nullable(),
            }),
            emailAddress: Yup.string().validateEmail().nullable(),
            phone: Yup.string().validatePhoneLength().required(t("MobileNumber is required")),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string().nullable(),
            race: Yup.array().nullable(),
            dateOfBirth: Yup.date(),
            relationShip: Yup.string(),
            pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
            pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianStringForCaregiver ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeStringForCaregiver ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        })
    }) : Yup.object().shape({
        careGiver: Yup.object({
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is a required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable(),
                state: Yup.string(),
                city: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t('Zip code is a required field')),
            }),
            emailAddress: Yup.string().validateEmail(),
            phone: Yup.string().validatePhoneLength().nullable().required(t("Phone is a required field")).nullable().matches(/^[0-9-]*$/),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            gender: Yup.string().nullable(),
            race: Yup.array().nullable(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")).nullable(),
            relationShip: Yup.string().nullable(),
            pgNameId: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string(),
            pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")): Yup.date().nullable(),
            otherAsianRace:Yup.object({
                otherSubRaceValue:otherAsianStringForCaregiver ? Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            }),
            otherNativeRace:Yup.object({
                otherSubRaceValue:otherNativeStringForCaregiver ?  Yup.string().EmptySpace().otherRelation().nullable().required(t('Required')) : Yup.string().nullable()
            })
        })
    })


    const followupSchema = Yup.object().shape({
        followupDate: Yup.string().required(t("Followup date is a required field")),
        note: Yup.string().EmptySpace(),
        reason: Yup.string().required(t("Reason is a required field")),
    })

    const followupForm = useFormik({
        initialValues: followupData,
        validateOnMount: true,
        validationSchema: followupSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setFormLoading(true)
            submitFollowup()
            setFormLoading(false)
        }
    })

    const profileForm = useFormik({
        initialValues: initialData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
        
           if(values.careGiver.relationShip === 14){
                otherRelationshipForm.submitForm()
           }
           else {
            if(!errWarning && !errWarning1 && !errWarning2 && !otherAsianErr && !otherNativeErr && !otherAsianErr1 && !otherNativeErr1){
                setFormLoading(true)
                submitForm()
                setBoth(true)
                setFormLoading(false)
            }
              
           }
        }
    })

    const otherRelationshipFormSchema = Yup.object().shape({
        otherRelationship : Yup.string().EmptySpace().otherRelation().required(t("Other Relationship is required"))
    })

    const otherRelationshipForm = useFormik({
        initialValues: initialData.careGiver,
        validateOnMount: true,
        enableReinitialize:true,
        validationSchema:otherRelationshipFormSchema,
        onSubmit:(values)=>{
            profileForm.values.careGiver.otherRelationship = values.otherRelationship
            submitForm()
        }
    })

    const careReceiverForm = useFormik({
        initialValues: initialData,
        validationSchema: careReceiverFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if(!errWarning && !errWarning2){
                setFormLoading(true)
                profileForm.values.careReceiver = values.careReceiver
                submitForm()
                setFormLoading(false)
            }
           
        }
    })

    const careGiverForm = useFormik({
        initialValues: initialData,
        validationSchema: careGiverFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if(values.careGiver.relationShip === 14){
                profileForm.values.careGiver = values.careGiver
                otherRelationshipForm.submitForm()
            }else{
                if(!errWarning1){
                    setFormLoading(true)
                profileForm.values.careGiver = values.careGiver
                submitForm()
                setFormLoading(false)
                }
                
            }
        }
    })

    const validateForm = async (value) => {
        if(!both) {
            const setProfileFormValues = async () => {
                await profileForm.setValues({
                    careGiver: careGiverForm.values.careGiver, 
                    careReceiver: careReceiverForm.values.careReceiver, 
                    caseId: match.params.caseId 
                })
            }
            await setProfileFormValues();
        } 
                setSaveContinue(true)
                setBoth(true)
                profileForm.submitForm()
                if(careGiverForm.values.careGiver.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                    setOtherAsianStringBothCg(true)
                }
                if(careGiverForm.values.careGiver.otherNativeRace.otherSubRaceId === OTHER_NATIVE){
                    setOtherNativeStringBothCg(true)
                }
                if(careReceiverForm.values.careGiver.otherAsianRace.otherSubRaceId === OTHER_ASIAN){
                    setOtherAsianString(true)
                }
                if(careReceiverForm.values.careGiver.otherNativeRace.otherSubRaceId === OTHER_NATIVE){
                    setOtherNativeString(true)
                }
                setTimeout(function () {
                    //profileForm.submitForm()
                }, 500)
            // }
          }
          const CloseForm = async (value) => {
            let details
            if (profileForm.values.careReceiver.dateOfBirth !== undefined && profileForm.values.careReceiver.dateOfBirth && birthyear1) {
                let date = profileForm.values.careReceiver.dateOfBirth
                date = date.split('/')
                profileForm.values.careReceiver.dobDay = date[2]
                profileForm.values.careReceiver.dobMonth = date[1]
                profileForm.values.careReceiver.dobYear = date[0]
            }
                profileForm.values.careReceiver.dobYear = profileForm.values.careReceiver.dateOfBirth ? profileForm.values.careReceiver.dateOfBirth : 0
            // }
            if (profileForm.values.careGiver.dateOfBirth !== undefined && profileForm.values.careGiver.dateOfBirth && birthyear1) {
                let date = profileForm.values.careGiver.dateOfBirth
                date = date.split('/')
                profileForm.values.careGiver.dobDay = date[2]
                profileForm.values.careGiver.dobMonth = date[1]
                profileForm.values.careGiver.dobYear = date[0]
            }
            profileForm.values.careGiver.dobYear = profileForm.values.careGiver.dateOfBirth ? profileForm.values.careGiver.dateOfBirth : 0
                details = {
                    "caregiver":
                    {
                        "address": {
                            "state": profileForm.values.careGiver.address.state,
                            "addressLine1": profileForm.values.careGiver.address.addressLine1,
                            "addressLine2": "",
                            "city": profileForm.values.careGiver.address.city,
                            "postalCode": profileForm.values.careGiver.address.postalCode
                        },
                        "language": profileForm.values.careGiver.language,
                        "personId": initialData.careGiver.personId,
                        "firstName": profileForm.values.careGiver.firstName.trim(),
                        "lastName": profileForm.values.careGiver.lastName.trim(),
                        "emailAddress": profileForm.values.careGiver.emailAddress,
                        "phone": profileForm.values.careGiver.phone,
                        "areaCode": "",
                        "subscriberId": "",
                        "preferredName": "",
                        "hispanic": profileForm.values.careGiver.race.includes(3),
                        "nativeAmerican": profileForm.values.careGiver.race.includes(5),
                        "dobDay": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobDay) : parseInt(profileForm.values.careGiver.dobDay ? profileForm.values.careGiver.dobDay : 1),
                        "phoneType": "",
                        "pacificIslander": otherNativeBothCg.length > 0 ? true :  profileForm.values.careGiver.race.includes(6),
                        "otherRace": profileForm.values.careGiver.race.includes(8),
                        "refused": profileForm.values.careGiver.race.includes(9),
                        "medicaid": false,
                        "medicare": false,
                        "tricare": false,
                        "otherInsurer": false,
                        "multiRacial": false,
                        "dobYear": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobYear) : parseInt(profileForm.values.careGiver.dobYear ? profileForm.values.careGiver.dobYear : 0),
                        "dobMonth": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobMonth) :  parseInt(profileForm.values.careGiver.dobMonth ? profileForm.values.careGiver.dobMonth : 1),
                        "uninsured": false,
                        "maritalStat": "",
                        "phoneAuthorized": false,
                        "pronouns": null,
                        "samsId": "",
                        "ssn": "",
                        "homePhone": "",
                        "gender": profileForm.values.careGiver.gender,
                        "employmentId": 0,
                        "educationId": 0,
                        "incomeId": 0,
                        "white": profileForm.values.careGiver.race.includes(1),
                        "black": profileForm.values.careGiver.race.includes(2),
                        "asian": otherAsianBothCg.length > 0 ? true : profileForm.values.careGiver.race.includes(4),
                        "middleEastern": profileForm.values.careGiver.race.includes(7),
                        "middleName": "",
                        "relationshipId" : profileForm.values.careGiver.relationShip,
                        "otherRelationship":profileForm.values.careGiver.otherRelationship,
                        "pgName": profileForm.values.careGiver.pgNameId ? profileForm.values.careGiver.pgNameId : 0,
                        "ethnicity": profileForm.values.careGiver.race.includes(9) ? [] : otherAsianForCargiver.length > 0 || otherNativeForCaregiver.length > 0 ? [...otherAsianForCargiver,...otherNativeForCaregiver] : [...otherAsianBothCg,...otherNativeBothCg],
                        "otherAsianRace":{
                            "otherSubRaceId":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                            "otherSubRaceValue":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue,
                            "raceId":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 4 :''
                        },
                        "otherNativeRace":{
                            "otherSubRaceId":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 35 :'',
                            "otherSubRaceValue":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue,
                            "raceId":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 6 :''
                        }
                        // "pcspDate" :profileForm.values.careGiver.pcspDate ? profileForm.values.careGiver.pcspDate : null
                    },
                    "careReceiver":
                    {
                        "address": {
                            "state": profileForm.values.careReceiver.address.state,
                            "addressLine1": profileForm.values.careReceiver.address.addressLine1,
                            "addressLine2": "",
                            "city": profileForm.values.careReceiver.address.city,
                            "county": profileForm.values.careReceiver.address.county,
                            "postalCode": profileForm.values.careReceiver.address.postalCode
                        },
                        "language": profileForm.values.careReceiver.language,
                        "personId": profileForm.values.careReceiver.personId ? profileForm.values.careReceiver.personId : 0,
                        "firstName": profileForm.values.careReceiver.firstName.trim(),
                        "lastName": profileForm.values.careReceiver.lastName.trim(),
                        "relationshipId": 0,
                        "otherRelationship":"",
                        "emailAddress": profileForm.values.careReceiver.emailAddress,
                        "phone": profileForm.values.careReceiver.phone,
                        "areaCode": "",
                        "subscriberId": profileForm.values.careReceiver.subscriberId,
                        "preferredName": "",
                        "hispanic": profileForm.values.careReceiver.race.includes(3),
                        "nativeAmerican": profileForm.values.careReceiver.race.includes(5),
                        "dobDay": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobDay) : parseInt(profileForm.values.careReceiver.dobDay ? profileForm.values.careReceiver.dobDay : 1),
                        "phoneType": "",
                        "pacificIslander": otherNative.length > 0 ? true :profileForm.values.careReceiver.race.includes(6) ,
                        "otherRace": profileForm.values.careReceiver.race.includes(8),
                        "refused": profileForm.values.careReceiver.race.includes(9),
                        "medicaid": false,
                        "medicare": false,
                        "tricare": false,
                        "otherInsurer": false,
                        "multiRacial": false,
                        "dobYear": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobYear) : parseInt(profileForm.values.careReceiver.dobYear ? profileForm.values.careReceiver.dobYear : 0),
                        "dobMonth": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobMonth) : parseInt(profileForm.values.careReceiver.dobMonth ? profileForm.values.careReceiver.dobMonth : 1),
                        "uninsured": false,
                        "maritalStat": "",
                        "phoneAuthorized": false,
                        "pronouns": null,
                        "samsId": "",
                        "ssn": "",
                        "homePhone": "",
                        "gender": profileForm.values.careReceiver.gender,
                        "employmentId": 0,
                        "educationId": 0,
                        "incomeId": 0,
                        "white": profileForm.values.careReceiver.race.includes(1),
                        "black": profileForm.values.careReceiver.race.includes(2),
                        "asian": otherAsian.length > 0 ? true :profileForm.values.careReceiver.race.includes(4),
                        "middleEastern": profileForm.values.careReceiver.race.includes(7),
                        "middleName": "",
                        "relationShip": "",
                        "assessType":profileForm.values.careReceiver.assessTypeId,
                        "ethnicity":profileForm.values.careReceiver.race.includes(9) ? [] : otherAsianForCareReceiver.length > 0 || otherNativeForCareReceiver.length > 0 ? [...otherAsianForCareReceiver,...otherNativeForCareReceiver] : [...otherAsian,...otherNative],
                        "otherAsianRace":{
                            "otherSubRaceId":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 29 : '',
                            "otherSubRaceValue":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue,
                            "raceId":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 4 : ''
                        },
                        "otherNativeRace":{
                            "otherSubRaceId":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 35:'',
                            "otherSubRaceValue":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue,
                            "raceId":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 6 :''
                        }
                    },
    
                    "organizationId": organization,
                    "caseId": match.params.caseId,
                    "reassessReason": history?.location?.state?.assDetail?.reassessmentReason,
                    "pcspDate": history?.location?.state?.assDetail?.pcspDate,
                    "noCaregiver": false,
                    "referrerInfo": true
    
    
                }
            API.post(`cases:referrer`, details).then(res => {
                if (res.status === 200) {
                    if(match.params.careManagerId === '0'){
                        dispatch(setAlert({ title: t('Success'), subtitle: res.data.referrer, type: 'success', active: true }))
                        history.push('/pendingRefferals')

                    }else{
                        dispatch(setAlert({ title: t('Success'), subtitle: res.data.referrer, type: 'success', active: true }))
                    }
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                   
                } else {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            })
                
              }

    const setCareGiverRace =(val)=>{
        if (val === 1) {
            profileForm.values.careGiver.white = !profileForm.values.careGiver.white;
        } else if (val === 2) {
            profileForm.values.careGiver.black = !profileForm.values.careGiver.black;
        } else if (val === 3) {
            profileForm.values.careGiver.hispanic = !profileForm.values.careGiver.hispanic;
        } else if (val === 4) {
            profileForm.values.careGiver.asian = !profileForm.values.careGiver.asian;
        } else if (val === 5) {
            profileForm.values.careGiver.nativeAmerican = !profileForm.values.careGiver.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.careGiver.middleEastern = !profileForm.values.careGiver.middleEastern;
        } else if (val === 6) {
            profileForm.values.careGiver.pacificIslander = !profileForm.values.careGiver.pacificIslander;
        } else if (val === 9) {
            profileForm.values.careGiver.refused = !profileForm.values.careGiver.refused;
        } else if (val === 8) {
            profileForm.values.careGiver.otherRace = !profileForm.values.careGiver.otherRace;
        } else { }
    }
    const setCareReceiverRace = (val,type) =>{
        // setCrRace(val)
        if (val === 1) {
            profileForm.values.careReceiver.white = !profileForm.values.careReceiver.white;
        } else if (val === 2) {
            profileForm.values.careReceiver.black = !profileForm.values.careReceiver.black;
        } else if (val === 3) {
            profileForm.values.careReceiver.hispanic = !profileForm.values.careReceiver.hispanic;
        } else if (val === 4) {
            profileForm.values.careReceiver.asian = !profileForm.values.careReceiver.asian;
        } else if (val === 5) {
            profileForm.values.careReceiver.nativeAmerican = !profileForm.values.careReceiver.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.careReceiver.middleEastern = !profileForm.values.careReceiver.middleEastern;
        } else if (val === 6) {
            profileForm.values.careReceiver.pacificIslander = !profileForm.values.careReceiver.pacificIslander;
        } else if (val === 9) {
            profileForm.values.careReceiver.refused = !profileForm.values.careReceiver.refused;
        } else if (val === 8) {
            profileForm.values.careReceiver.otherRace = !profileForm.values.careReceiver.otherRace;
        } else { }
     }

   useLayoutEffect(()=>{
    if(profileForm.values.careReceiver.race.includes(29)){
        setOtherAsianString(true)
       }else {
        setOtherAsianString(false)
        profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, otherAsianRace: { ...profileForm.values.careReceiver.otherAsianRace, otherSubRaceValue: "" } } })
       }
   },[profileForm.values.careReceiver.race.includes(29)])
   useLayoutEffect(()=>{
       if(profileForm.values.careReceiver.race.includes(35)){
        setOtherNativeString(true)
    }else{setOtherNativeString(false)
        profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver, otherNativeRace: { ...profileForm.values.careReceiver.otherNativeRace,otherSubRaceValue: ""} } })
    }
   },[profileForm.values.careReceiver.race.includes(35)])
   
   useLayoutEffect(()=>{
    if(profileForm.values.careGiver.race.includes(29)){
        setOtherAsianStringBothCg(true)
        } else {
        setOtherAsianStringBothCg(false)
        profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, otherAsianRace: { ...profileForm.values.careGiver.otherAsianRace, otherSubRaceValue: "" } } })
        }
   },[profileForm.values.careGiver.race.includes(29)])
   useLayoutEffect(()=>{
        if(profileForm.values.careGiver.race.includes(35)){
        setOtherNativeStringBothCg(true)
    }else{setOtherNativeStringBothCg(false)
        profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, otherNativeRace: { ...profileForm.values.careGiver.otherNativeRace,otherSubRaceValue: ""} } })
    }
   },[profileForm.values.careGiver.race.includes(35)])


   useLayoutEffect(()=>{
    if(careReceiverForm.values.careReceiver.race.includes(29)){
        setOtherAsianStringForCareReceiver(true)
        }else{
        setOtherAsianStringForCareReceiver(false)
        careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, otherAsianRace: { ...careReceiverForm.values.careReceiver.otherAsianRace, otherSubRaceValue:'' } } })
        }
    
   },[careReceiverForm.values.careReceiver.race.includes(29)])

   useLayoutEffect(()=>{
        if(careReceiverForm.values.careReceiver.race.includes(35)){
            setOtherNativeStringForCareReceiver(true)
            }else{setOtherNativeStringForCareReceiver(false)
            careReceiverForm.setValues({ ...careReceiverForm.values, careReceiver: { ...careReceiverForm.values.careReceiver, otherNativeRace: { ...careReceiverForm.values.careReceiver.otherNativeRace,otherSubRaceValue: ""} } })
        }
    
   },[careReceiverForm.values.careReceiver.race.includes(35)])
   useLayoutEffect(()=>{
    if(careGiverForm.values.careGiver.race.includes(29)){
        setOtherAsianStringForCaregiver(true)
        }else{
        setOtherAsianStringForCaregiver(false)
        careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, otherAsianRace: { ...careGiverForm.values.careGiver.otherAsianRace,otherSubRaceValue: "" } } })
        }
   },[careGiverForm.values.careGiver.race.includes(29)])
   useLayoutEffect(()=>{
        if(careGiverForm.values.careGiver.race.includes(35)){
            setOtherNativeStringForCaregiver(true)
        }else{setOtherNativeStringForCaregiver(false)
            careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, otherNativeRace: { ...careGiverForm.values.careGiver.otherNativeRace,otherSubRaceValue: ""} } })
        }
   },[careGiverForm.values.careGiver.race.includes(35)])


     const setOtherAsianSelect = (value,type) =>{
        if(type === 'cr'){
            if(otherAsianForCareReceiver.includes(value)){
                let values = otherAsianForCareReceiver.filter((c)=>c!==value)
                setOtherAsianForCareReceiver(values)
            }else{
                setOtherAsianForCareReceiver((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'cg'){
            if(otherAsianForCargiver.includes(value)){
                let values = otherAsianForCargiver.filter((c)=>c!==value)
                setOtherAsianForCargiver(values)
            }else{
                setOtherAsianForCargiver((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'pgcr'){
            if(otherAsian.includes(value)){
                let values = otherAsian.filter((c)=>c!==value)
                setOtherAsian(values)
            }else{
                setOtherAsian((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'pgcg'){
            if(otherAsianBothCg.includes(value)){
                let values = otherAsianBothCg.filter((c)=>c!==value)
                setOtherAsianBothCg(values)
            }else{
                setOtherAsianBothCg((prevValue)=>[...prevValue,value])
            }
        }
    }

    const setNativeSelect = (value,type) =>{
        if(type === 'cr'){
            if(otherNativeForCareReceiver.includes(value)){
                let values = otherNativeForCareReceiver.filter((c)=>c!==value)
                setOtherNativeForCareReceiver(values)
            }else{
                setOtherNativeForCareReceiver((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'cg'){
            if(otherNativeForCaregiver.includes(value)){
                let values = otherNativeForCaregiver.filter((c)=>c!==value)
                setOtherNativeForCaregiver(values)
            }else{
                setOtherNativeForCaregiver((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'pgcr'){
            if(otherNative.includes(value)){
                let values = otherNative.filter((c)=>c!==value)
                setOtherNative(values)
            }else{
                setOtherNative((prevValue)=>[...prevValue,value])
            }
        }
        if(type === 'pgcg'){
            if(otherNativeBothCg.includes(value)){
                let values = otherNativeBothCg.filter((c)=>c!==value)
                setOtherNativeBothCg(values)
            }else{
                setOtherNativeBothCg((prevValue)=>[...prevValue,value])
            }
        }
    }
     const otherValue = (values) => {
        if (values === 14) {
            setInputEnable(true)
        } else {
            setInputEnable(false)
            // profileForm.setValues({...profileForm,relationshipId:values,otherRelationship :''})
        }
    }

    const submitForm = () => {
        let details
        if (profileForm.values.careReceiver.dateOfBirth !== undefined && profileForm.values.careReceiver.dateOfBirth && birthyear1) {
            let date = profileForm.values.careReceiver.dateOfBirth
            date = date.split('/')
            profileForm.values.careReceiver.dobDay = date[2]
            profileForm.values.careReceiver.dobMonth = date[1]
            profileForm.values.careReceiver.dobYear = date[0]
        }
            profileForm.values.careReceiver.dobYear = profileForm.values.careReceiver.dateOfBirth ? profileForm.values.careReceiver.dateOfBirth : 0
        // }
        if (profileForm.values.careGiver.dateOfBirth !== undefined && profileForm.values.careGiver.dateOfBirth && birthyear1) {
            let date = profileForm.values.careGiver.dateOfBirth
            date = date.split('/')
            profileForm.values.careGiver.dobDay = date[2]
            profileForm.values.careGiver.dobMonth = date[1]
            profileForm.values.careGiver.dobYear = date[0]
        }
        profileForm.values.careGiver.dobYear = profileForm.values.careGiver.dateOfBirth ? profileForm.values.careGiver.dateOfBirth : 0
        // }
        if (both) {
            // let careGiverPhone = profileForm.values.careGiver.phone.split('-')
            // let careReceiverPhone = profileForm.values.careReceiver.phone.split('')
            details = {
                "caregiver":
                {
                    "address": {
                        "state": profileForm.values.careGiver.address.state,
                        "addressLine1": profileForm.values.careGiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careGiver.address.city,
                        "postalCode": profileForm.values.careGiver.address.postalCode
                    },
                    "language": profileForm.values.careGiver.language,
                    "personId": initialData.careGiver.personId,
                    "firstName": profileForm.values.careGiver.firstName.trim(),
                    "lastName": profileForm.values.careGiver.lastName.trim(),
                    "emailAddress": profileForm.values.careGiver.emailAddress,
                    "phone": profileForm.values.careGiver.phone,
                    "areaCode": "",
                    "subscriberId": "",
                    "preferredName": "",
                    "hispanic": profileForm.values.careGiver.race.includes(3),
                    "nativeAmerican": profileForm.values.careGiver.race.includes(5),
                    "dobDay": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobDay) : parseInt(profileForm.values.careGiver.dobDay ? profileForm.values.careGiver.dobDay : 1),
                    "phoneType": "",
                    "pacificIslander": otherNativeBothCg.length > 0 ? true :  profileForm.values.careGiver.race.includes(6),
                    "otherRace": profileForm.values.careGiver.race.includes(8),
                    "refused": profileForm.values.careGiver.race.includes(9),
                    "medicaid": false,
                    "medicare": false,
                    "tricare": false,
                    "otherInsurer": false,
                    "multiRacial": false,
                    "dobYear": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobYear) : parseInt(profileForm.values.careGiver.dobYear ? profileForm.values.careGiver.dobYear : 0),
                    "dobMonth": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobMonth) :  parseInt(profileForm.values.careGiver.dobMonth ? profileForm.values.careGiver.dobMonth : 1),
                    "uninsured": false,
                    "maritalStat": "",
                    "phoneAuthorized": false,
                    "pronouns": null,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "gender": profileForm.values.careGiver.gender,
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": profileForm.values.careGiver.race.includes(1),
                    "black": profileForm.values.careGiver.race.includes(2),
                    "asian": otherAsianBothCg.length > 0 ? true : profileForm.values.careGiver.race.includes(4),
                    "middleEastern": profileForm.values.careGiver.race.includes(7),
                    "middleName": "",
                    "relationshipId" : profileForm.values.careGiver.relationShip,
                    "otherRelationship":profileForm.values.careGiver.otherRelationship,
                    "pgName": profileForm.values.careGiver.pgNameId ? profileForm.values.careGiver.pgNameId : 0,
                    "ethnicity": profileForm.values.careGiver.race.includes(9) ? [] : otherAsianForCargiver.length > 0 || otherNativeForCaregiver.length > 0 ? [...otherAsianForCargiver,...otherNativeForCaregiver] : [...otherAsianBothCg,...otherNativeBothCg],
                    "otherAsianRace":{
                        "otherSubRaceId":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                        "otherSubRaceValue":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue,
                        "raceId":profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 4 :''
                    },
                    "otherNativeRace":{
                        "otherSubRaceId":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 35 :'',
                        "otherSubRaceValue":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue,
                        "raceId":profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 6 :''
                    }
                    // "pcspDate" :profileForm.values.careGiver.pcspDate ? profileForm.values.careGiver.pcspDate : null
                },
                "careReceiver":
                {
                    "address": {
                        "state": profileForm.values.careReceiver.address.state,
                        "addressLine1": profileForm.values.careReceiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careReceiver.address.city,
                        "county": profileForm.values.careReceiver.address.county,
                        "postalCode": profileForm.values.careReceiver.address.postalCode
                    },
                    "language": profileForm.values.careReceiver.language,
                    "personId": profileForm.values.careReceiver.personId ? profileForm.values.careReceiver.personId : 0,
                    "firstName": profileForm.values.careReceiver.firstName.trim(),
                    "lastName": profileForm.values.careReceiver.lastName.trim(),
                    "relationshipId": 0,
                    "otherRelationship":"",
                    "emailAddress": profileForm.values.careReceiver.emailAddress,
                    "phone": profileForm.values.careReceiver.phone,
                    "areaCode": "",
                    "subscriberId": profileForm.values.careReceiver.subscriberId,
                    "preferredName": "",
                    "hispanic": profileForm.values.careReceiver.race.includes(3),
                    "nativeAmerican": profileForm.values.careReceiver.race.includes(5),
                    "dobDay": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobDay) : parseInt(profileForm.values.careReceiver.dobDay ? profileForm.values.careReceiver.dobDay : 1),
                    "phoneType": "",
                    "pacificIslander": otherNative.length > 0 ? true :profileForm.values.careReceiver.race.includes(6) ,
                    "otherRace": profileForm.values.careReceiver.race.includes(8),
                    "refused": profileForm.values.careReceiver.race.includes(9),
                    "medicaid": false,
                    "medicare": false,
                    "tricare": false,
                    "otherInsurer": false,
                    "multiRacial": false,
                    "dobYear": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobYear) : parseInt(profileForm.values.careReceiver.dobYear ? profileForm.values.careReceiver.dobYear : 0),
                    "dobMonth": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobMonth) : parseInt(profileForm.values.careReceiver.dobMonth ? profileForm.values.careReceiver.dobMonth : 1),
                    "uninsured": false,
                    "maritalStat": "",
                    "phoneAuthorized": false,
                    "pronouns": null,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "gender": profileForm.values.careReceiver.gender,
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": profileForm.values.careReceiver.race.includes(1),
                    "black": profileForm.values.careReceiver.race.includes(2),
                    "asian": otherAsian.length > 0 ? true :profileForm.values.careReceiver.race.includes(4),
                    "middleEastern": profileForm.values.careReceiver.race.includes(7),
                    "middleName": "",
                    "relationShip": "",
                    "assessType":profileForm.values.careReceiver.assessTypeId,
                    "ethnicity":profileForm.values.careReceiver.race.includes(9) ? [] : otherAsianForCareReceiver.length > 0 || otherNativeForCareReceiver.length > 0 ? [...otherAsianForCareReceiver,...otherNativeForCareReceiver] : [...otherAsian,...otherNative],
                    "otherAsianRace":{
                        "otherSubRaceId":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 29 : '',
                        "otherSubRaceValue":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue,
                        "raceId":profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 4 : ''
                    },
                    "otherNativeRace":{
                        "otherSubRaceId":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 35:'',
                        "otherSubRaceValue":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue,
                        "raceId":profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 6 :''
                    }
                },

                "organizationId": organization,
                "caseId": match.params.caseId,
                "reassessReason": history?.location?.state?.assDetail?.reassessmentReason,
                "pcspDate": history?.location?.state?.assDetail?.pcspDate,
                "noCaregiver": false,
                "referrerInfo": saveContinue ? false : true


            }
        } else if (!both && cgLength === 0) {
            // let careReceiverPhone = profileForm.values.careReceiver.phone.split('-')
            details = {
                "careReceiver":
                {
                    "address": {
                        "state": profileForm.values.careReceiver.address.state,
                        "addressLine1": profileForm.values.careReceiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careReceiver.address.city,
                        "county": profileForm.values.careReceiver.address.county,
                        "postalCode": profileForm.values.careReceiver.address.postalCode
                    },
                    "language": profileForm.values.careReceiver.language,
                    "personId": profileForm.values.careReceiver.personId ? profileForm.values.careReceiver.personId : 0,
                    "firstName": profileForm.values.careReceiver.firstName.trim(),
                    "lastName": profileForm.values.careReceiver.lastName.trim(),
                    "relationshipId": 0,
                    "otherRelationship":"",
                    "emailAddress": profileForm.values.careReceiver.emailAddress,
                    "phone": profileForm.values.careReceiver.phone,
                    "areaCode": "",
                    "subscriberId": profileForm.values.careReceiver.subscriberId,
                    "preferredName": "",
                    "hispanic": careReceiverForm.values.careReceiver.race.includes(3),
                    "nativeAmerican": careReceiverForm.values.careReceiver.race.includes(5),
                    "dobDay": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobDay) : parseInt(profileForm.values.careReceiver.dobDay ? profileForm.values.careReceiver.dobDay : 1),
                    "phoneType": "",
                    "pacificIslander":otherNativeForCareReceiver.length > 0 ? true : careReceiverForm.values.careReceiver.race.includes(6),
                    "otherRace": careReceiverForm.values.careReceiver.race.includes(8),
                    "refused": careReceiverForm.values.careReceiver.race.includes(9),
                    "medicaid": false,
                    "medicare": false,
                    "tricare": false,
                    "otherInsurer": false,
                    "multiRacial": false,
                    "dobYear": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobYear) : parseInt(profileForm.values.careReceiver.dobYear ? profileForm.values.careReceiver.dobYear : 0),
                    "dobMonth": crRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careReceiver.dobMonth) : parseInt(profileForm.values.careReceiver.dobMonth ? profileForm.values.careReceiver.dobMonth : 1),
                    "uninsured": false,
                    "maritalStat": "",
                    "phoneAuthorized": false,
                    "pronouns": null,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "gender": profileForm.values.careReceiver.gender,
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": careReceiverForm.values.careReceiver.race.includes(1),
                    "black": careReceiverForm.values.careReceiver.race.includes(2),
                    "asian": otherAsianForCareReceiver.length > 0 ? true : careReceiverForm.values.careReceiver.race.includes(4),
                    "middleEastern": careReceiverForm.values.careReceiver.race.includes(7),
                    "middleName": "",
                    "relationShip": "",
                    "assessType": careReceiverForm.values.careReceiver.assessTypeId,
                    "ethnicity": careReceiverForm.values.careReceiver.race.includes(9) ? [] :[...otherAsianForCareReceiver,...otherNativeForCareReceiver],
                    "otherAsianRace":{
                        "otherSubRaceId":careReceiverForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && careReceiverForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                        "otherSubRaceValue":careReceiverForm.values.careReceiver.otherAsianRace.otherSubRaceValue,
                        "raceId":careReceiverForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== '' && careReceiverForm.values.careReceiver.otherAsianRace.otherSubRaceValue !== null ? 4 :''
                    },
                    "otherNativeRace":{
                        "otherSubRaceId":careReceiverForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && careReceiverForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 35 :'',
                        "otherSubRaceValue":careReceiverForm.values.careReceiver.otherNativeRace.otherSubRaceValue,
                        "raceId":careReceiverForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== '' && careReceiverForm.values.careReceiver.otherNativeRace.otherSubRaceValue !== null ? 35 :''
                    }
                },
                "organizationId": organization,
                "caseId": match.params.caseId,
                "noCaregiver": true,
                "referrerInfo": true

            }
        } else if (!both && crLength === 0) {
            // let careGiverPhone = profileForm.values.careGiver.phone.split('-')
            details = {
                "caregiver":
                {
                    "address": {
                        "state": profileForm.values.careGiver.address.state,
                        "addressLine1": profileForm.values.careGiver.address.addressLine1,
                        "addressLine2": "",
                        "city": profileForm.values.careGiver.address.city,
                        "postalCode": profileForm.values.careGiver.address.postalCode
                    },
                    "language":  profileForm.values.careGiver.language,
                    "personId": initialData.careGiver.personId,
                    "firstName": profileForm.values.careGiver.firstName.trim(),
                    "lastName": profileForm.values.careGiver.lastName.trim(),
                    "emailAddress": profileForm.values.careGiver.emailAddress,
                    "phone": profileForm.values.careGiver.phone,
                    "areaCode": "",
                    "subscriberId": "",
                    "preferredName": "",
                    "hispanic": careGiverForm.values.careGiver.race.includes(3),
                    "nativeAmerican": careGiverForm.values.careGiver.race.includes(5),
                    "dobDay": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobDay): parseInt(profileForm.values.careGiver.dobDay ? profileForm.values.careGiver.dobDay : 1),
                    "phoneType": "",
                    "pacificIslander": otherNativeForCaregiver.length > 0 ? true : careGiverForm.values.careGiver.race.includes(6),
                    "otherRace": careGiverForm.values.careGiver.race.includes(8),
                    "refused": careGiverForm.values.careGiver.race.includes(9),
                    "medicaid": false,
                    "medicare": false,
                    "tricare": false,
                    "otherInsurer": false,
                    "multiRacial": false,
                    "dobYear": cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.careGiver.dobYear): parseInt(profileForm.values.careGiver.dobYear ? profileForm.values.careGiver.dobYear : 0),
                    "dobMonth": cgRefused ? 0 :birthyear1 ? parseInt(profileForm.values.careGiver.dobMonth): parseInt(profileForm.values.careGiver.dobMonth ? profileForm.values.careGiver.dobMonth : 1),
                    "uninsured": false,
                    "maritalStat": "",
                    "phoneAuthorized": false,
                    "pronouns": null,
                    "samsId": "",
                    "ssn": "",
                    "homePhone": "",
                    "gender": profileForm.values.careGiver.gender,
                    "employmentId": 0,
                    "educationId": 0,
                    "incomeId": 0,
                    "white": careGiverForm.values.careGiver.race.includes(1),
                    "black": careGiverForm.values.careGiver.race.includes(2),
                    "asian": otherAsianForCargiver.length > 0 ? true : careGiverForm.values.careGiver.race.includes(4),
                    "middleEastern": careGiverForm.values.careGiver.race.includes(7),
                    "middleName": "",
                    relationshipId : profileForm.values.careGiver.relationShip ? profileForm.values.careGiver.relationShip : 0,
                    "otherRelationship": profileForm.values.careGiver.otherRelationship ? profileForm.values.careGiver.otherRelationship.trim() : '',
                    "pgName":profileForm.values.careGiver.pgNameId ? profileForm.values.careGiver.pgNameId : 0,
                    "ethnicity": careGiverForm.values.careGiver.race.includes(9) ? [] :[...otherAsianForCargiver,...otherNativeForCaregiver],
                    "otherAsianRace":{
                        "otherSubRaceId":careGiverForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && careGiverForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                        "otherSubRaceValue":careGiverForm.values.careGiver.otherAsianRace.otherSubRaceValue,
                        "raceId":careGiverForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && careGiverForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 4 :''
                    },
                    "otherNativeRace":{
                        "otherSubRaceId":careGiverForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && careGiverForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 35 :'',
                        "otherSubRaceValue":careGiverForm.values.careGiver.otherNativeRace.otherSubRaceValue,
                        "raceId":careGiverForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && careGiverForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 6 :''
                    }
                    // "pcspDate" :profileForm.values.careGiver.pcspDate ? profileForm.values.careGiver.pcspDate : null
                },
                "organizationId": organization,
                "caseId": match.params.caseId,
                "referrerInfo": true
            }
        }
        API.post(`cases:referrer`, details).then(res => {
            if (res.status === 200) {
                if(match.params.careManagerId === '0'){
                    dispatch(setAlert({ title: t('Success'), subtitle: res.data.referrer, type: 'success', active: true }))
                }else{
                    dispatch(setAlert({ title: t('Success'), subtitle: res.data.referrer, type: 'success', active: true }))
                }
                if(saveContinue){
                history.push('/case_list')
                }else{
                    history.push('/pendingRefferals')
                }
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }

    const submitFollowup = () => {
        let split = followupForm.values.followupDate.split(' ')
        let dateSplit = split[0].split('/')
        let timeSplit = split[1].split(':')
        let date = dateSplit[2]
        let month = dateSplit[1]
        let year = dateSplit[0]
        let hour = timeSplit[0]
        let minute = timeSplit[1]
        let concatStr = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ''
        let reasonName = followupReason.map(c => { if (c.value === followupForm.values.reason) { return c.text }
        return null;
     })
        reasonName = reasonName.filter(c => c !== undefined).toString()
        let details = {
            "followupDate": concatStr,
            "caseId": match.params.caseId,
            "notes": followupForm.values.note,
            "reason": followupForm.values.reason,
            "reasonName": reasonName
        }
        API.post(`/cases/${match.params.caseId}/follow-ups?orgId=${orgId}`, details).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Followup Submitted Successfully'), type: 'success', active: true }))
                history.push('/pendingRefferals')
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }

    const setRefused = (side,value) =>{
        if(side === 'cr') {
            setCrRefused(value)
        }else if(side === 'cg'){
            setCgRefused(value)
        }
    }

    const getEmailAddress = (value,field) =>{
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            if(regex.test(value)){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${match.params.careReceiverId}/emailValidate?email=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true })) 
                    if(field === 'cr'){
                        setErrWarning(true)
                        setCustomSubWarn(err.response.data.errorMessage)
                        // careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,emailAddress:""}})
                    }else if(field === 'pcrr'){
                        setErrWarning(true)
                        setCustomSubWarn(err.response.data.errorMessage)
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,emailAddress:""}})
                    } 
                }
            })
            }else{
                setErrWarning(false)
            }
        }
       
     const getEmailAddress1 = (value,field) =>{
            var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
                if(regex.test(value)){
                API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${match?.params?.careGiverId}/emailValidate?email=${value}`).then((res) => {
                    if (res.status === 200) {
                        setErrWarning1(false)
                    }
                }).catch(err => {
                    if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true })) 
                        if(field === 'cg'){
                            setErrWarning1(true)
                        setCustomSubWarn1(err.response.data.errorMessage)
                            // careGiverForm.setValues({...careGiverForm.values,careGiver:{...careGiverForm.values.careGiver,emailAddress:""}})
                        }else if(field === 'pcgr'){
                            setErrWarning1(true)
                        setCustomSubWarn1(err.response.data.errorMessage)
                            //  profileForm.setValues({... profileForm.values,careGiver:{... profileForm.values.careGiver,emailAddress:""}})
                        }  
                    }
                })
                }else{
                    setErrWarning1(false)
                }
            }

    // const common = (value) => {
    //     if (value === true) {
    //         setoption(true)
    //         careGiverForm.resetForm()
    //     } else if (value === false) {
    //         setoption(false)
    //     }
    //     // if (history.location.state?.cgNew === true && value === false) {
    //     //     //careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver,noCaregiver: false } })
    //     // } else if (history.location.state?.cgNew === true && value === true) 
    //     // {
    //     //     careGiverForm.resetForm()
    //     //     // careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver,firstName:'',
    //     //     // lastName: '',address:'',assessTypeId:'',dateOfBirth:'',dobDay:'',dobMonth:'',dobYear:'',emailAddress:'',
    //     //     // gender:'',otherRelationship:'',language:'',pgName:'',pgNameId:'',phone:'',relationShip:'',subscriberId:'',race:[],
    //     //     // noCaregiver: true } })
    //     // }

    // }
    
    const getSubscriberId= (value,field) => {
        var regex = /^[a-zA-Z0-9_]*$/;
        if(value && regex.test(value) && tempCrSub !== value){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${match.params.careReceiverId}/SubIdValidate?subId=${value}`).then((res)=>{
                if(res.status === 200){
                    setErrWarning2(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    if(field === 'cr'){
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                        // careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,subscriberId:""}})
                    }else if(field === 'pcrr'){
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,subscriberId:""}})
                    }
                   
                }
            })
        }else{
            setErrWarning2(false)
        }
        setTempCrSub(value)
    }

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    if(field === 'cr'){
                        setCrEnable(true)
                        careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,address:{...careReceiverForm.values.careReceiver.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country,county:res.data.body.county}}})
                    }else if(field === 'cg'){
                        setZipEnable(true)
                        careGiverForm.setValues({...careGiverForm.values,careGiver:{...careGiverForm.values.careGiver,address:{...careGiverForm.values.careGiver.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}}})
                    }else if(field === 'pcrr'){
                        setCrEnable(true)
                        profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country,county:res.data.body.county}}})
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,state:res.data.body.state}}})
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,city:res.data.body.city}}})
                        // profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,country:res.data.body.country}}})
                    }else if(field === 'pcgr'){
                        setZipEnable(true)
                         profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}}})
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,state:res.data.body.state}}})
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,city:res.data.body.city}}})
                        // profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,country:res.data.body.country}}})
                    }
                }
            })
        }
         else if(value.length === 0){
            if(field === 'cr'){
                setCrEnable(false)
                careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,address:{...careReceiverForm.values.careReceiver.address,state:"",city:"",country:"",county:""}}})
            }else if(field === 'cg'){
                setZipEnable(false)
                careGiverForm.setValues({...careGiverForm.values,careGiver:{...careGiverForm.values.careGiver,address:{...careGiverForm.values.careGiver.address,state:"",city:"",country:"",county:""}}})
            }else if(field === 'pcrr'){
                setCrEnable(false)
                profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,address:{...profileForm.values.careReceiver.address,state:"",city:"",country:"",county:""}}})
            }else if(field === 'pcgr'){
                setZipEnable(false)
                 profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,address:{...profileForm.values.careGiver.address,state:"",city:"",country:""}}})
            }
        }
    }

    // useLayoutEffect(() => {
    //     console.log("cr",crRefused,"cg",cgRefused)
    //     if(crRefused){
    //         profileForm.setValues({...profileForm.values,careReceiver:{...profileForm.values.careReceiver,dateOfBirth:0,dobYear:0}})
    //         careReceiverForm.setValues({...careReceiverForm.values,careReceiver:{...careReceiverForm.values.careReceiver,dateOfBirth:0,dobYear:0}})
    //     }else if(cgRefused){
    //         profileForm.setValues({...profileForm.values,careGiver:{...profileForm.values.careGiver,dateOfBirth:0,dobYear:0}}) 
    //         careGiverForm.setValues({...careGiverForm.values,careGiver:{...careGiverForm.values.careGiver,dateOfBirth:0,dobYear:0}}) 
    //     }
    // },[crRefused,cgRefused])

    return (
        <div className="flex-1 flex justify-center items-center">
            {
                loading ?
                    <DemographicLoader />
                    :
                    <div className="flex flex-col justify-center w-full md:w-11/12">
                        
                        {
                            !both &&

                            <div className="flex flex-col lg:flex-row justify-between gap-6 flex-wrap items-center">
                                <div className="flex-1 flex flex-col items-center mt-12">
                                    <div></div>
                                    <div className="">{t('Care Receiver')} {t('Information')}</div>
                                    <div className="mt-8 lg:mt-10 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                        <AddRefInput err1={saveContinue} ariaLabel={'First name'} hideData={true} loading={formLoading} label={t('First Name')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.firstName`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                        <AddRefInput err1={saveContinue} ariaLabel={'Last name'} hideData={true} loading={formLoading} label={t('Last Name')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.lastName`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Phone'} hideData={true} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.phone`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.phone`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.phone`)} edit={true} dataLoaded={dataLoaded} />
                                            <a href={`tel: +1${careReceiverForm.getFieldProps('careReceiverForm.phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Email'} hideData={true} loading={formLoading} label={t('Email')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.emailAddress`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val,'cr')} err={errWarning} customErrmsg={customsubWarn} />
                                            <a href={`mailto: ${careReceiverForm.getFieldProps(`careReceiver.emailAddress`).value}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a>
                                        </div>
                                        {/* <AddRefInput err1={saveContinue} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.addressLine1`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} /> */}
                                        {/* <AddRefInput err1={saveContinue} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.city`)} edit={true} dataLoaded={dataLoaded} /> */}
                                        {/* <FieldSelect loading={formLoading} label={t('State')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.state`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.state`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" /> */}
                                        { birthyear1 ? <AddRefInput err1={saveContinue} ariaLabel={'Date Of Birth'} disabled={crRefused ? true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                        <FieldSelect ariaLabel={'Birth year'} disabled={crRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                      }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                        {/* <AddRefInput err1={saveContinue} type="year" disabled={crRefused ? true:false} loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} /> */}
                                        {crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cr',false)}>{t('Refused')}</button>}
                                        {!crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cr',true)}>{t('Refused')}</button>}
                                            </div>
                                        {assessmentTypeId1 ? <FieldSelect ariaLabel={'Assessment Type'} loading={formLoading} label={t("Assessment Type")} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.assessTypeId`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.assessTypeId`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.assessTypeId`)} edit={true} options={assessmentType} position="top" dataLoaded={dataLoaded} />:''}
                                        <AddRefInput err1={saveContinue} ariaLabel={'Zipcode'} loading={formLoading} label={t('Zip Code')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.postalCode`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cr')}/>
                                        {crEnable ?  <AddRefInput err1={saveContinue} ariaLabel={'Address'} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.addressLine1`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''}
                                        {crEnable ? <AddRefInput err1={saveContinue} ariaLabel={'City'} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''}
                                      {crEnable ? <FieldSelect ariaLabel={'State'} loading={formLoading} label={t('State')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.state`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.state`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''}
                                      {crEnable ? <AddRefInput err1={saveContinue} ariaLabel={'County'} loading={formLoading} label={t('County')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.address.county`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.address.county`)} edit={true} dataLoaded={dataLoaded} />:''}
                                  
                                            <AddRefInput err1={saveContinue} ariaLabel={'Subscriber Id'} loading={formLoading} label={t('Subscriber ID')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.subscriberId`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.subscriberId`)} edit={true} dataLoaded={dataLoaded} ml={true} onBlur={(ev) => getSubscriberId(ev.target.value,'cr')} onPaste={(ev) => getSubscriberId(ev,'cr')} err={errWarning2} customErrmsg={customsubWarn2} />
                                        <FieldSelect ariaLabel={'Gender'} loading={formLoading} label={t('Gender')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.gender`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.gender`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.gender`)} options={gender} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                       {orgRaceEnable ?<FieldMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.race`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.race`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.race`)} edit={true} dataLoaded={dataLoaded} options={crRace} asians={subAsian} native={subNative} position="bottom" setAsian={(ev)=>setOtherAsianSelect(ev,'cr')} setNative={(ev)=>setNativeSelect(ev,'cr')} onChange={val=>setCareReceiverRace(val)}/>:
                                        <UserMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.race`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.race`)} fieldHelper={careReceiverForm.getFieldHelpers(`careReceiver.race`)} edit={true} dataLoaded={dataLoaded} options={crRace}  position="bottom" onChange={val=>setCareReceiverRace(val)}/>}
                                        {otherAsianStringForCareReceiver &&<AddRefInput err1={saveContinue} ariaLabel={'Other Asian'} hideData={true} loading={formLoading} label={t('Other Asian')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.otherAsianRace.otherSubRaceValue`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.otherAsianRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        {otherNativeStringForCareReceiver &&<AddRefInput err1={saveContinue} ariaLabel={'Other Native Hawaiian'} hideData={true} loading={formLoading} label={t('Other Native Hawaiian')} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.otherNativeRace.otherSubRaceValue`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.otherNativeRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        <FieldSelect ariaLabel={'Preferred Language'} loading={formLoading} label={t("Preferred Language")} fieldMeta={careReceiverForm.getFieldMeta('careReceiver.language')} fieldHelper={careReceiverForm.getFieldHelpers('careReceiver.language')} fieldProps={careReceiverForm.getFieldProps('careReceiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                       
                                        <div style={{height:'105px'}}></div>
                                    </div>
                                    {/* <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-2 py-4`}>
                                        <div className="w-11/12 text-sm">
                                            {'Race (You can select multiple race)'}
                                        </div>
                                        <div className="mt-4 pb-3">
                                            <AssessmentOptionField edit={true} type={'multi_option'} value={crRace} options={crRace} setValue={val => setCareReceiverRace(val)} />
                                        </div>
                                    </motion.div> */}
                                </div>
                                <div className="flex-1 flex flex-col items-center">
                                    <div className="">{t('Caregiver')} {t('Information')}</div>
                                    <div className="mt-8 lg:mt-10 grid grid-cols-2 gap-4 w-full lg:w-3/4">
                                    {/* {both ?'' : <ReferrerCheckbox loading={formLoading} label={t("No Caregiver Available")}  autoComplete='off' fieldHelper={careGiverForm.getFieldHelpers('careGiver.noCaregiver')} fieldMeta={careGiverForm.getFieldMeta('careGiver.noCaregiver')} fieldProps={careGiverForm.getFieldProps('careGiver.noCaregiver')} dataLoaded={dataLoaded}  edit={true}  onChange={(ev) => common(ev)} />}{both?'':<div></div>} */}
                                        <AddRefInput err1={saveContinue} ariaLabel={'First Name'} hideData={true} loading={formLoading} disabled={option} label={t('First Name')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.firstName`)} fieldProps={careGiverForm.getFieldProps(`careGiver.firstName`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                        <AddRefInput err1={saveContinue} ariaLabel={'Last Name'} hideData={true} loading={formLoading} disabled={option} label={t('Last Name')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.lastName`)} fieldProps={careGiverForm.getFieldProps(`careGiver.lastName`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Phone'} hideData={true} type="phone" loading={formLoading} disabled={option} label={t('Phone Number')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.phone`)} fieldProps={careGiverForm.getFieldProps(`careGiver.phone`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.phone`)} edit={true} dataLoaded={dataLoaded} />
                                            <a href={`tel: +1${careGiverForm.getFieldProps('careGiver.phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Email'} hideData={true} loading={formLoading} disabled={option} label={t('Email')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.emailAddress`)} fieldProps={careGiverForm.getFieldProps(`careGiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress1(val,'cg')} err={errWarning1} customErrmsg={customsubWarn1} />
                                            <a href={`mailto: ${careGiverForm.getFieldProps(`careGiver.emailAddress`).value}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a>
                                        </div>
                                        {birthyear1 ? <AddRefInput err1={saveContinue} ariaLabel={'Date'} disabled={cgRefused && option? true:option?true:cgRefused?true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={careGiverForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                       <FieldSelect ariaLabel={'Birth Year'} disabled={cgRefused && option? true:option?true:cgRefused?true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={careGiverForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                      }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-2 relative w-72 text-base">
                                        {cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                                        {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                                       </div>
                                       {programName1 ? <FieldSelect ariaLabel={'Program Name'} loading={formLoading} disabled={option} label={t('Program Name')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.pgNameId`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.pgNameId`)} fieldProps={careGiverForm.getFieldProps(`careGiver.pgNameId`)} edit={true} options={programName} position="bottom" dataLoaded={dataLoaded} />:''}
                                         {datePscp ? <AddRefInput err1={saveContinue} ariaLabel={'Date Of PCSP'}  loading={formLoading} type="date" disabled={option} label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.pcspDate`)} fieldProps={careGiverForm.getFieldProps(`careGiver.pcspDate`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.pcspDate`)} edit={false} dataLoaded={dataLoaded} />:''}
                                        <AddRefInput err1={saveContinue} ariaLabel={'Zipcode'} loading={formLoading} disabled={option} label={t('Zip Code')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.address.postalCode`)} fieldProps={careGiverForm.getFieldProps(`careGiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')}/>
                                       {zipEnable ?  <AddRefInput err1={saveContinue} ariaLabel={'Address'} loading={formLoading} disabled={option} label={t('Address')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.address.addressLine1`)} fieldProps={careGiverForm.getFieldProps(`careGiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''}
                                        {zipEnable ? <AddRefInput err1={saveContinue} ariaLabel={'City'} loading={formLoading} disabled={option} label={t('City')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.address.city`)} fieldProps={careGiverForm.getFieldProps(`careGiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''}
                                      {zipEnable ? <FieldSelect ariaLabel={'State'} loading={formLoading} disabled={option} label={t('State')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.address.state`)} fieldProps={careGiverForm.getFieldProps(`careGiver.address.state`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''}  
                                     
                                        <FieldSelect ariaLabel={'Gender'} loading={formLoading} disabled={option} label={t('Gender')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.gender`)} fieldProps={careGiverForm.getFieldProps(`careGiver.gender`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.gender`)} options={gender} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                        <FieldSelect ariaLabel={'Relationship'} loading={formLoading} disabled={option} label={t('Relationship (Caring for)')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.relationShip`)} fieldProps={careGiverForm.getFieldProps(`careGiver.relationShip`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.relationShip`)} options={relationShip} edit={true} dataLoaded={dataLoaded} position="bottom" onChange={otherValue} />
                                        { inputEnable && <AddRefInput err1={saveContinue} ariaLabel={'Other Relationship'} loading={formLoading} disabled={option} label={t("Other Relation")} autoComplete='off' fieldMeta={otherRelationshipForm.getFieldMeta(`otherRelationship`)} fieldProps={otherRelationshipForm.getFieldProps(`otherRelationship`)} edit={true} dataLoaded={dataLoaded} />}
                                       {orgRaceEnable ? <FieldMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} disabled={option} label={t('Race/Ethnicity')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.race`)} fieldProps={careGiverForm.getFieldProps(`careGiver.race`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.race`)} edit={true} dataLoaded={dataLoaded} options={cgRace} asians={subAsian} native={subNative} position="bottom" setAsian={(ev)=>setOtherAsianSelect(ev,'cg')} setNative={(ev)=>setNativeSelect(ev,'cg')} onChange={val=>setCareGiverRace(val)}/>:
                                        <UserMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} disabled={option} label={t('Race/Ethnicity')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.race`)} fieldProps={careGiverForm.getFieldProps(`careGiver.race`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.race`)} edit={true} dataLoaded={dataLoaded} options={cgRace}  position="bottom" onChange={val=>setCareGiverRace(val)}/>}
                                        {otherAsianStringForCaregiver &&<AddRefInput err1={saveContinue} ariaLabel={'Other Asian'} hideData={true} loading={formLoading} label={t('Other Asian')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.otherAsianRace.otherSubRaceValue`)} fieldProps={careGiverForm.getFieldProps(`careGiver.otherAsianRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        {otherNativeStringForCaregiver &&<AddRefInput err1={saveContinue} ariaLabel={'Other Native Hawaiian'} hideData={true} loading={formLoading} label={t('Other Native Hawaiian')} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.otherNativeRace.otherSubRaceValue`)} fieldProps={careGiverForm.getFieldProps(`careGiver.otherNativeRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        <FieldSelect ariaLabel={'Preferred Language'} loading={formLoading} disabled={option} label={t("Preferred Language")} fieldMeta={careGiverForm.getFieldMeta('careGiver.language')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.language')} fieldProps={careGiverForm.getFieldProps('careGiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                    </div>
                                    {/* <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-3 ml-20 py-4`}>
                                        <div className="w-11/12 text-sm">
                                            {'Race (You can select multiple race)'}
                                        </div>
                                        <div className="mt-4 pb-3">
                                            <AssessmentOptionField edit={true} type={'multi_option'} value={cgRace} options={cgRace} setValue={val => setCareGiverRace(val)} />
                                        </div>
                                    </motion.div> */}
                                </div>
                            </div>
                        }
                        {
                            both &&
                            <div className="flex flex-col lg:flex-row justify-between gap-6 flex-wrap items-center">
                                <div className="flex-1 flex flex-col items-center mt-12">
                                    <div className="">{t('Care Receiver')} {t('Information')}</div>
                                    <div className="mt-8 lg:mt-10 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">

                                        <AddRefInput err1={saveContinue} ariaLabel={'First name is a required field'} hideData={true} loading={formLoading} label={t('First Name')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.firstName`)} fieldProps={profileForm.getFieldProps(`careReceiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                        <AddRefInput err1={saveContinue} ariaLabel={'Last Name is a required field'} hideData={true} loading={formLoading} label={t('Last Name')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.lastName`)} fieldProps={profileForm.getFieldProps(`careReceiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Phone'} hideData={true} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.phone`)} fieldProps={profileForm.getFieldProps(`careReceiver.phone`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.phone`)} edit={true} dataLoaded={dataLoaded} />
                                            <a href={`tel: +1${profileForm.getFieldProps('careReceiver.phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Email'} hideData={true} loading={formLoading} label={t('Email')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.emailAddress`)} fieldProps={profileForm.getFieldProps(`careReceiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val,'pcrr')} err={errWarning} customErrmsg={customsubWarn} />
                                            <a href={`mailto: ${profileForm.getFieldProps(`careReceiver.emailAddress`).value}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a>
                                        </div>
                                        {/* <FieldSelect loading={formLoading} label={t('Race')} fieldMeta={profileForm.getFieldMeta(`careReceiver.race`)} fieldProps={profileForm.getFieldProps(`careReceiver.race`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.race`)} options={raceEthnicity} edit={true} dataLoaded={dataLoaded} position="bottom" onChange={changeRacevalues(profileForm.getFieldProps('careReceiver.race'))} /> */}
                                        {/* <AddRefInput err1={saveContinue} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.addressLine1`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} /> */}
                                        {/* <AddRefInput err1={saveContinue} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.city`)} edit={true} dataLoaded={dataLoaded} /> */}
                                        {/* <FieldSelect loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careReceiver.address.state`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" /> */}
                                        {birthyear1 ? <AddRefInput err1={saveContinue} ariaLabel={'Date is a required field'} disabled={crRefused ? true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                        <FieldSelect ariaLabel={'Birth Year is a required field'} disabled={crRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                       }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                        {crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cr',false)}>{t('Refused')}</button>}
                                        {!crRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cr',true)}>{t('Refused')}</button>}
                                        </div>
                                        {assessmentTypeId1 ? <FieldSelect ariaLabel={'Assessment Type is a required field'} loading={formLoading} label={t("Assessment Type")} fieldMeta={profileForm.getFieldMeta('careReceiver.assessTypeId')} fieldHelper={profileForm.getFieldHelpers(`careReceiver.assessTypeId`)} fieldProps={profileForm.getFieldProps(`careReceiver.assessTypeId`)} edit={true} options={assessmentType} position="top" dataLoaded={dataLoaded} />:''}
                                        <AddRefInput err1={saveContinue} ariaLabel={'Zipcode is a required field'} loading={formLoading} label={t('Zip Code')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'pcrr')}/>
                                        {crEnable ?  <AddRefInput err1={saveContinue} ariaLabel={'Address'} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.addressLine1`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''}
                                        {crEnable ? <AddRefInput err1={saveContinue} ariaLabel={'City'} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''}
                                        {crEnable ? <FieldSelect ariaLabel={'State'} loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careReceiver.address.state`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''}
                                        {crEnable ? <AddRefInput err1={saveContinue} ariaLabel={'County'} loading={formLoading} label={t('County')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.address.county`)} fieldProps={profileForm.getFieldProps(`careReceiver.address.county`)} edit={true} dataLoaded={dataLoaded} />:''}
                                      
                                        <AddRefInput err1={saveContinue} ariaLabel={'Subscriber Id is a required field'} loading={formLoading} label={t('Subscriber ID')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.subscriberId`)} fieldProps={profileForm.getFieldProps(`careReceiver.subscriberId`)} edit={true} dataLoaded={dataLoaded} ml={true} onBlur={(ev) => getSubscriberId(ev.target.value,'pcrr')} onPaste={(ev) => getSubscriberId(ev,'pcrr')} err={errWarning2} customErrmsg={customsubWarn2} />
                                        <FieldSelect ariaLabel={'Gender'} loading={formLoading} label={t('Gender')} fieldMeta={profileForm.getFieldMeta(`careReceiver.gender`)} fieldProps={profileForm.getFieldProps(`careReceiver.gender`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.gender`)} options={gender} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                       {orgRaceEnable ? <FieldMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={profileForm.getFieldMeta(`careReceiver.race`)} fieldProps={profileForm.getFieldProps(`careReceiver.race`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.race`)} edit={true} dataLoaded={dataLoaded} options={crRace} asians={subAsian} native={subNative} position="bottom" setAsian={(ev)=>setOtherAsianSelect(ev,'pgcr')} setNative={(ev)=>setNativeSelect(ev,'pgcr')} onChange={val=>setCareReceiverRace(val,profileForm.getFieldProps(`careReceiver.race`))}/>:
                                        <UserMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={profileForm.getFieldMeta(`careReceiver.race`)} fieldProps={profileForm.getFieldProps(`careReceiver.race`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.race`)} edit={true} dataLoaded={dataLoaded} options={crRace}  position="bottom"  onChange={val=>setCareReceiverRace(val,profileForm.getFieldProps(`careReceiver.race`))}/>}
                                        {otherAsianString &&<AddRefInput err1={saveContinue} ariaLabel={'Other Asian'} hideData={true} loading={formLoading} label={t('Other Asian')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.otherAsianRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careReceiver.otherAsianRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        {otherNativeString &&<AddRefInput err1={saveContinue} ariaLabel={'Other Native Hawaiian'} hideData={true} loading={formLoading} label={t('Other Native Hawaiian')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.otherNativeRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careReceiver.otherNativeRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        <FieldSelect ariaLabel={'Preferred Language'} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careReceiver.language')} fieldHelper={profileForm.getFieldHelpers('careReceiver.language')} fieldProps={profileForm.getFieldProps('careReceiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                        <div style={{height:'105px'}}></div>
                                    </div>
                                    {/* <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-3 py-4`}>
                                        <div className="w-11/12 text-sm">
                                            {'Race (You can select multiple race)'}
                                        </div>
                                        <div className="mt-4 pb-3">
                                            <AssessmentOptionField edit={true} type={'multi_option'} value={crRace} options={crRace} setValue={val => setCareReceiverRace(val)} />
                                        </div>
                                    </motion.div> */}
                                </div>
                                <div className="flex flex flex-col items-center">
                                    <div>{t('Caregiver')} {t('Information')}</div>
                                    <div className="mt-8 lg:mt-10 grid grid-cols-2 gap-4 w-full lg:w-3/4">
                                        <AddRefInput err1={saveContinue} ariaLabel={'First Name is a required field'} hideData={true} loading={formLoading} label={t('First Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.firstName`)} fieldProps={profileForm.getFieldProps(`careGiver.firstName`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.firstName`)} edit={true} dataLoaded={dataLoaded} />
                                        <AddRefInput err1={saveContinue} ariaLabel={'Last Name is a required field'} hideData={true} loading={formLoading} label={t('Last Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.lastName`)} fieldProps={profileForm.getFieldProps(`careGiver.lastName`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.lastName`)} edit={true} dataLoaded={dataLoaded} />
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Phone Name is a required field'} hideData={true} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.phone`)} fieldProps={profileForm.getFieldProps(`careGiver.phone`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.phone`)} edit={true} dataLoaded={dataLoaded} />
                                            <a href={`tel: +1${profileForm.getFieldProps('careGiver.phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                                                <FaPhoneAlt size="16px" />
                                            </a>
                                        </div>
                                        <div className="flex items-center gap-2 relative">
                                            <AddRefInput err1={saveContinue} ariaLabel={'Email'} hideData={true} loading={formLoading} label={t('Email')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.emailAddress`)} fieldProps={profileForm.getFieldProps(`careGiver.emailAddress`)} edit={true} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress1(val,'pcgr')} err={errWarning1} customErrmsg={customsubWarn1} />
                                            <a href={`mailto: ${profileForm.getFieldProps(`careGiver.emailAddress`).value}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a>
                                        </div>
                                        {/* <AddRefInput err1={saveContinue} type="phone" loading={formLoading} label={t('Phone Number')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.phone`)} fieldProps={profileForm.getFieldProps(`careGiver.phone`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.phone`)} edit={true} dataLoaded={dataLoaded} /> */}
                                        {/* <FieldSelect loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careGiver.address.state`)} fieldProps={profileForm.getFieldProps(`careGiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" /> */}
                                        {birthyear1 ? <AddRefInput err1={saveContinue} ariaLabel={'Date is a required field'} disabled={cgRefused ? true:false} type="date" loading={formLoading} label={t('Date of Birth')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} edit={true} dataLoaded={dataLoaded} maxDate={new Date()} />:
                                        <FieldSelect ariaLabel={'Birth Year is a required field'} disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                       }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-2 relative w-72 text-base">
                                        {cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                                        {!cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                                        </div>
                                        {programName1 ? <FieldSelect ariaLabel={'Program Name is a required field'} loading={formLoading} label={t('Program Name')} fieldMeta={profileForm.getFieldMeta(`careGiver.pgNameId`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.pgNameId`)} fieldProps={profileForm.getFieldProps(`careGiver.pgNameId`)} edit={true} options={programName} position="bottom" dataLoaded={dataLoaded} />:''}
                                         {datePscp ? <AddRefInput err1={saveContinue} ariaLabel={'Date is a required field'}  loading={formLoading} type="date"  label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.pcspDate`)} fieldProps={profileForm.getFieldProps(`careGiver.pcspDate`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.pcspDate`)} edit={false} dataLoaded={dataLoaded} />:''}
                                        <AddRefInput err1={saveContinue} ariaLabel={'Zipcode is a required field'} loading={formLoading} label={t('Zip Code')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careGiver.address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'pcgr')}/>
                                       {zipEnable ? <AddRefInput err1={saveContinue} ariaLabel={'Address'} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.addressLine1`)} fieldProps={profileForm.getFieldProps(`careGiver.address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />:''} 
                                       {zipEnable ? <AddRefInput err1={saveContinue} ariaLabel={'City'} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.address.city`)} fieldProps={profileForm.getFieldProps(`careGiver.address.city`)} edit={true} dataLoaded={dataLoaded} />:''} 
                                       {zipEnable ? <FieldSelect ariaLabel={'State'} loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careGiver.address.state`)} fieldProps={profileForm.getFieldProps(`careGiver.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />:''} 
                                        <FieldSelect ariaLabel={'Gender'} loading={formLoading} label={t('Gender')} fieldMeta={profileForm.getFieldMeta(`careGiver.gender`)} fieldProps={profileForm.getFieldProps(`careGiver.gender`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.gender`)} options={gender} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                        <FieldSelect ariaLabel={'Relationship is a required field'} loading={formLoading} label={t('Relationship (Caring for)')} fieldMeta={profileForm.getFieldMeta(`careGiver.relationShip`)} fieldProps={profileForm.getFieldProps(`careGiver.relationShip`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.relationShip`)} options={relationShip} edit={true} dataLoaded={dataLoaded} position="bottom" onChange={otherValue} />
                                        { inputEnable && <AddRefInput err1={saveContinue} ariaLabel={'Other'} loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={otherRelationshipForm.getFieldMeta(`otherRelationship`)} fieldProps={otherRelationshipForm.getFieldProps(`otherRelationship`)} edit={true} dataLoaded={dataLoaded} />}
                                        {orgRaceEnable ? <FieldMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={profileForm.getFieldMeta(`careGiver.race`)} fieldProps={profileForm.getFieldProps(`careGiver.race`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.race`)} edit={true} dataLoaded={dataLoaded} options={cgRace} asians={subAsian} native={subNative} position="bottom" setAsian={(ev)=>setOtherAsianSelect(ev,'pgcg')} setNative={(ev)=>setNativeSelect(ev,'pgcg')} onChange={val=>setCareGiverRace(val)}/>:
                                        <UserMultiSelect ariaLabel={'Race/Ethnicity'} loading={formLoading} label={t('Race/Ethnicity')} fieldMeta={profileForm.getFieldMeta(`careGiver.race`)} fieldProps={profileForm.getFieldProps(`careGiver.race`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.race`)} edit={true} dataLoaded={dataLoaded} options={cgRace}  position="bottom"  onChange={val=>setCareGiverRace(val)}/>}
                                        {otherAsianStringBothCg &&<AddRefInput err1={saveContinue} ariaLabel={'Other Asian'} hideData={true} loading={formLoading} label={t('Other Asian')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.otherAsianRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careGiver.otherAsianRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        {otherNativeStringBothCg &&<AddRefInput err1={saveContinue} ariaLabel={'Other Native Hawaiian'} hideData={true} loading={formLoading} label={t('Other Native Hawaiian')} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careGiver.otherNativeRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careGiver.otherNativeRace.otherSubRaceValue`)} edit={true} dataLoaded={dataLoaded} />}
                                        <FieldSelect ariaLabel={'Preferred Language'} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careGiver.language')} fieldHelper={profileForm.getFieldHelpers('careGiver.language')} fieldProps={profileForm.getFieldProps('careGiver.language')} edit={true} options={language} position="top" dataLoaded={dataLoaded} />
                                    </div>
                                    {/* <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-3 ml-20 py-4`}>
                                        <div className="w-11/12 text-sm">
                                            {'Race (You can select multiple race)'}
                                        </div>
                                        <div className="mt-4 pb-3">
                                            <AssessmentOptionField edit={true} type={'multi_option'} value={cgRace} options={cgRace} setValue={val => setCareGiverRace(val)} />
                                        </div>
                                    </motion.div> */}
                                </div>
                            </div>
                        }

                        <div className="flex mt-10 pt-10 mb-10 justify-center gap-4">
                           <Button ariaLabel={'Next'} injectClass="mt-10 py-1 py-2" onClick={() => CloseForm('close')}>{t("Save & Close")}</Button>
                           <Button ariaLabel={'Next'} injectClass="mt-10 py-1 py-2" onClick={() => validateForm('continue')}>{t("Save & Continue")}</Button>

                        </div>
                        
                        {/* <div className="text-center">
                            {match.params.careManagerId === '0' ? <Button ariaLabel={'Next'} injectClass="mt-10 py-1 py-2" onClick={() => validateForm()}>{t("Next")}</Button>
                             : <Button ariaLabel={'Submit'} injectClass="mt-10 py-1 py-2" onClick={() => validateForm()}>{t("Submit")}</Button>}
                        </div> */}
                        
                        <div className="flex mt-10 pt-10 mb-10 justify-center gap-4 border-t border-gray-300 dark:border-gray-600">
                            <div className="flex flex-col gap-3">
                                <FieldInput ariaLabel={'Followup Date'} type="datetime" minDate={new Date()} loading={formLoading} label={t('Follow-up Date')} autoComplete='off' fieldMeta={followupForm.getFieldMeta(`followupDate`)} fieldProps={followupForm.getFieldProps(`followupDate`)} fieldHelper={followupForm.getFieldHelpers(`followupDate`)} edit={true} dataLoaded={true} />
                                <FieldSelect ariaLabel={'Rename'} loading={formLoading} label={t('Reason')} fieldMeta={followupForm.getFieldMeta(`reason`)} fieldProps={followupForm.getFieldProps(`reason`)} fieldHelper={followupForm.getFieldHelpers(`reason`)} options={followupReason} edit={true} dataLoaded={true} position="bottom" />
                            </div>
                            <FieldInput ariaLabel={'Notes'} type="textarea" loading={formLoading} label={t('Notes')} autoComplete='off' fieldMeta={followupForm.getFieldMeta(`note`)} fieldProps={followupForm.getFieldProps(`note`)} fieldHelper={followupForm.getFieldHelpers(`note`)} edit={true} dataLoaded={true} />
                            <div>
                                <Button ariaLabel={'Schedule Follow-Up'} injectClass="mt-10 py-1" onClick={() => followupForm.submitForm()}>
                                    <div className="flex items-center gap-4">
                                        <span className="text-base">{t("Schedule Follow-Up")}</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default CreateCase
