import { useFormik } from "formik"
import { useState, useMemo, useEffect, useLayoutEffect, useRef } from "react"
import * as Yup from 'yup'
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FieldMultiSelect from "../../components/snippets/FieldMultiSelect"
import { useHistory } from 'react-router-dom';
import FieldCheckbox from "../../components/snippets/FieldCheckbox"
import API from '../../axios/API';
import axios from 'axios'
import { useDispatch } from "react-redux"
import { setAlert } from "../../store/theme/actions"
import { useTranslation } from 'react-i18next'
// import { FaRegFilePowerpoint } from "react-icons/fa"
import FlowText from "../../components/snippets/FlowText"
import Dialog from "../../components/snippets/Dialog"
import { useSelector } from "react-redux"
import ReferrerCheckbox from "../../components/snippets/ReferrerCheckbox"
import ReferrerInput from "../../components/snippets/ReferrerInput"
import ReferrerSelect from "../../components/snippets/ReferrerSelect"
import EduSelect from "../../components/snippets/EduSelect"
import PcspInput from "../../components/snippets/PcspInput"
import moment from "moment"
import UserMultiSelect from "../../components/snippets/UserMultiSelect"


const initialFormValues = {
    organizationId: 0,
    noCaregiver: '',
    careGiver: {
        address: {
            country: '',
            timeZone: '',
            state: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            postalCode: '',
            addressLine3: ''
        },
        language: '',
        personId: 0,
        firstName: '',
        lastName: '',
        programName: '',
        emailAddress: '',
        phone: '',
        areaCode: '',
        subscriberId: '',
        preferredName: '',
        hispanic: false,
        nativeAmerican: false,
        dateOfBirth: '',
        dobDay: 0,
        phoneType: '',
        pacificIslander: false,
        otherRace: false,
        multiRacial: false,
        dobYear: 0,
        dobMonth: 0,
        uninsured: false,
        refused: false,
        medicaid: false,
        medicare: false,
        tricare: false,
        otherInsurer: false,
        maritalStat: '',
        phoneAuthorized: false,
        pronouns: '',
        samsId: '',
        ssn: '',
        homePhone: '',
        gender: '',
        employmentId: 0,
        educationId: 0,
        incomeId: 0,
        white: false,
        black: false,
        asian: false,
        middleEastern: false,
        middleName: '',
        race: [],
        pgName: '',
        pcspDate: '',
        otherAsianRace: {
            otherSubRaceId: '',
            otherSubRaceValue: "",
            raceId: 0
        },
        otherNativeRace: {
            otherSubRaceId: '',
            otherSubRaceValue: "",
            raceId: 0
        }
    },
    careReceiver: [{
        address: {
            state: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            county: '',
            postalCode: '',
        },
        language: '',
        disability: '',
        personId: 0,
        firstName: '',
        lastName: '',
        relationshipId: '',
        otherRelationship: '',
        emailAddress: '',
        phone: '',
        areaCode: '',
        subscriberId: '',
        preferredName: '',
        hispanic: false,
        nativeAmerican: false,
        dateOfBirth: '',
        dobDay: 0,
        phoneType: '',
        pacificIslander: false,
        otherRace: false,
        refused: false,
        medicaid: false,
        medicare: false,
        tricare: false,
        otherInsurer: false,
        multiRacial: false,
        dobYear: 0,
        dobMonth: 0,
        uninsured: false,
        maritalStat: '',
        phoneAuthorized: false,
        pronouns: 0,
        samsId: '',
        ssn: '',
        homePhone: '',
        gender: '',
        employmentId: 0,
        educationId: 0,
        incomeId: 0,
        white: false,
        black: false,
        asian: false,
        middleEastern: false,
        middleName: '',
        relationship: '',
        race: [],
        assessType: '',
        tcareProtocol: '',
        otherAsianRace: {
            otherSubRaceId: '',
            otherSubRaceValue: "",
            raceId: 0
        },
        otherNativeRace: {
            otherSubRaceId: '',
            otherSubRaceValue: "",
            raceId: 0
        }
    }]
}

const referralinitialValues = {

    "careGiver":
    {
        "dateOfBirth": '',
        "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "postalCode": "" },
        "language": "",
        "personId": 0,
        "firstName": "",
        "lastName": "",
        "emailAddress": "",
        "check": "",
        "phoneNumber": "",
        "areaCode": "",
        "subscriberId": "",
        "preferredName": "",
        "hispanic": false,
        "nativeAmerican": false,
        "dobDay": 0,
        "phoneType": "",
        "pacificIslander": false,
        "otherRace": false,
        "refused": false,
        "medicaid": false,
        "medicare": false,
        "tricare": false,
        "otherInsurer": false,
        "multiRacial": false,
        "dobYear": 0,
        "dobMonth": 0,
        "uninsured": false,
        "maritalStat": "",
        "phoneAuthorized": false,
        "pronouns": null,
        "samsId": "",
        "ssn": "",
        "homePhone": "",
        "gender": "",
        "employmentId": 0,
        "educationId": 0,
        "incomeId": 0,
        "white": true,
        "black": false,
        "asian": false,
        "middleEastern": false,
        "middleName": "",
        "pgName": "",
        "pcspDate": "",
        "otherAsianRace": {
            "otherSubRaceId": '',
            "otherSubRaceValue": "",
            "raceId": ''
        },
        "otherNativeRace": {
            "otherSubRaceId": '',
            "otherSubRaceValue": "",
            "raceId": ''
        }
    },
    "careReceiver":
    {
        "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "postalCode": "" },
        "pcspDate": '',
        race: '',
        "dateOfBirth": '',
        "language": "",
        "personId": 0,
        "firstName": "",
        "lastName": "",
        "relationshipId": 0,
        "emailAddress": "",
        "phone": "",
        "areaCode": "",
        "subscriberId": "",
        "preferredName": "",
        "hispanic": false,
        "nativeAmerican": false,
        "dobDay": 0,
        "phoneType": "",
        "pacificIslander": false,
        "otherRace": false,
        "refused": false,
        "medicaid": false,
        "medicare": false,
        "tricare": false,
        "otherInsurer": false,
        "multiRacial": false,
        "dobYear": 0,
        "dobMonth": 0,
        "uninsured": false,
        "maritalStat": "",
        "phoneAuthorized": false,
        "pronouns": null,
        "samsId": "",
        "ssn": "",
        "homePhone": "",
        "gender": "",
        "employmentId": 0,
        "educationId": 0,
        "incomeId": 0,
        "white": false,
        "black": false,
        "asian": false,
        "middleEastern": false,
        "middleName": "",
        "relationship": "",
        "assessType": "",
        "tcareProtocol": "",
        "otherAsianRace": {
            "otherSubRaceId": '',
            "otherSubRaceValue": "",
            "raceId": ''
        },
        "otherNativeRace": {
            "otherSubRaceId": '',
            "otherSubRaceValue": "",
            "raceId": ''
        }
    },
    referrerCaseFollowup: {
        followupdate: '',
        notes: '',
        reason: '',
    },
    "organizationId": '',
    "caseId": ''

}



const NewCase = () => {

    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [employment, setEmployment] = useState([])
    const [pronouns, setPronouns] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [education, setEducation] = useState([])
    const [editForm] = useState(true)
    const [programName, setProgramName] = useState([])
    const [inputEnable, setInputEnable] = useState([{ id: 0, enable: false }])
    const [inputEnable1, setInputEnable1] = useState(false)
    const [language, setLanguage] = useState([])
    const [Income, setIncome] = useState([])
    const [relationShip, setRelationship] = useState([])
    const userDetails = useSelector(state => state.auth.userDetails)
    const [assessmentType, setAssessmentType] = useState([])
    const [disability, setDisability] = useState([])
    const [careReceiverList, setCareReceiverList] = useState(0)
    // const [activatedIndex, setActivatedIndex] = useState()
    const [page1, setPage1] = useState(true)
    const [page2, setPage2] = useState(false)
    const [cgRefused, setCgRefused] = useState(false)
    // const [dateRefused, setDateRefused] = useState(false)
    const [crRefused, setCrRefused] = useState([{ id: 0, selected: false }])
    const [crRefused1, setCrRefused1] = useState(false)
    const [years, setYears] = useState([])
    const [zipEnable, setZipEnable] = useState(false)
    const [zipEnable1, setZipEnable1] = useState(false)
    const [notEnable, setNotEnable] = useState([{ id: 0, enable: false }])
    // const [activeZip,setActiveZip] = useState()
    const [disable, setDisable] = useState(false)
    let subId = localStorage.getItem('subId')
    let prgName = localStorage.getItem('prgname')
    let assessType1 = localStorage.getItem('assessType')
    let pcsp = localStorage.getItem('pcsp')
    let birthyear = localStorage.getItem('birthyear')
    let ssn = localStorage.getItem('ssn')
    const [subreq, setSubReg] = useState([{ id: 0, enable: false }])
    const [birthyear1, setBirthYear1] = useState(false)
    const [birthyear2, setBirthYear2] = useState([{ id: 0, enable: false }])
    const [programName1, setProgramName1] = useState(false)
    const [datePscp, setDatePscp] = useState(false)
    const [assessmentTypeId1, setAssessmentTypeId1] = useState(false)
    const [assessmentTypeId, setAssessmentTypeId] = useState([{ id: 0, enable: false }])
    const { t } = useTranslation()
    const [errWarning] = useState(false)
    const [errWarning1, setErrWarning1] = useState(false)
    const [errWarning2, setErrWarning2] = useState(false)
    // const [customsubWarn, setCustomSubWarn] = useState()
    const [customsubWarn1, setCustomSubWarn1] = useState()
    const [customsubWarn2, setCustomSubWarn2] = useState()
    const [crError, setCrError] = useState([{ id: 0, crErrorWarn: false, crCustomMsg: '', subDisable: false }])
    const [mailDialog, setMailDialog] = useState(false)
    const [subDialog, setSubDialog] = useState(false)
    const [subDialog1, setSubDialog1] = useState(false)
    const [caseId, setCaseId] = useState('')
    const [referral, setReferral] = useState('')
    const [mailDisable, setMailDisable] = useState(false)
    let contact = localStorage.getItem('contactCG')
    const [option, setoption] = useState(false)
    const organization = useSelector(state => state.auth.organization)
    let history = useHistory();
    const [cgNew, setCgNew] = useState('false')
    const [reaccessReason, setReaccessReason] = useState('')
    const [subAsian, setSubAsians] = useState([])
    const [subNative, setSubNative] = useState([])
    const [otherAsianString, setOtherAsianString] = useState(false)
    const [otherNativeStringForCaregiver, setOtherNativeStringForCaregiver] = useState(false)
    const [otherAsianString1, setOtherAsianString1] = useState([{ id: 0, enable: false }])
    const [otherNativeStringForCareReceiver, setOtherNativeStringForCareReceiver] = useState([{ id: 0, enable: false }])
    const [cgEmailError, setCgEmailError] = useState(false)
    const [nextButton, setNextButton] = useState(0)
    const OTHER_ASIAN = 20;
    const OTHER_NATIVE = 26;
    const [otherAsianCgValue, setOtherAsianCgValue] = useState([])
    const [otherNativeCgValue, setOtherNativeCgValue] = useState([])
    const [otherAsianCrValue, setOtherAsianCrValue] = useState([{ id: 0, race: [] }])
    const [otherNativeCrValue, setOtherNativeCrValue] = useState([{ id: 0, race: [] }])
    // const userDetails = useSelector(state=>state.auth.userDetails)
    let disableAssociateCr = localStorage.getItem('disableAssociateCr')
    const [protocol, setProtocol] = useState([])
    const [protocolTypeId1, setProtocolTypeId1] = useState(false)
    let protocolType = localStorage.getItem('tcareProtocolEnabled')
    const [protocolTypeId, setProtocolTypeId] = useState([{ id: 0, enable: false }])
    let lang = localStorage.getItem('language')
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)
    const [tempSub,setTempSub] = useState([{ id: 0, subId: '' }])
    const [tempCrSub,setTempCrSub] = useState()
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    const nextRef = useRef(null)
    const [os,setOs] = useState('')

    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test(t('validEmail'), t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '' || value === null) return true
            return (value.replace(/[- ]/g, '').length === 10)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^\d]+$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r =/^[^\d]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'LengthSubId', function(){
        return this.test(t('SubscriberId must be the length of either 9 or 14'),t('SubscriberId must be the length of either 9 or 14'),(value)=>{
            if(value === undefined || value == '' || value.length === 0 || value === null || value.length === 9 || value.length === 14) return true
            if(value.length >= 10 && value.length < 14) return false  
        })
    })

    useEffect(() => {
        history.push({ state: '' })
        if (birthyear === 'true') {
            setBirthYear1(true)
            setBirthYear2([{ id: 0, enable: true }])
        }
        if (prgName === 'true') {
            setProgramName1(true)
        } if (pcsp === 'true') {
            setDatePscp(true)
        } if (assessType1 === 'true') {
            setAssessmentTypeId1(true)
            setAssessmentTypeId([{ id: 0, enable: true }])
        }if (protocolType === 'true') {
            setProtocolTypeId1(true)
            setProtocolTypeId([{ id: 0, enable: true }])
        }if(orgEthnicityEnable === 'true'){
            setOrgRaceEnabl(true)
        }
    }, [])//eslint-disable-line

    const formData = useMemo(() => {
        if (initialFormValues.careReceiver[0].relationshipId > 0) {

            initialFormValues.careReceiver[0].firstName = ''
            initialFormValues.careReceiver[0].lastName = ''
            initialFormValues.careReceiver[0].phone = ''
            initialFormValues.careReceiver[0].dobYear = ''
            initialFormValues.careReceiver[0].subscriberId = ''
            initialFormValues.careReceiver[0].address.postalCode = ''
            initialFormValues.careReceiver[0].address.state = ''
            initialFormValues.careReceiver[0].address.city = ''
            initialFormValues.careReceiver[0].address.county =
                initialFormValues.careReceiver[0].assessType = assessType1 === 'true' ? '' : ''
            initialFormValues.careReceiver[0].relationshipId = ''
            initialFormValues.careReceiver[0].tcareProtocol = protocolType === 'true' ? '' : ''

        }
        if (history?.location?.state?.subscriberId) {
            setCgNew(history?.location?.state?.cgNew)
            setReaccessReason(history?.location?.state?.assDetail?.reassessmentReason)
            initialFormValues.careReceiver[0].subscriberId = history?.location?.state?.subscriberId
            initialFormValues.careGiver.pcspDate = history?.location.state?.assDetail?.pcspDate
        } else {
            setCgNew(false)
            setReaccessReason()
            initialFormValues.careReceiver[0].subscriberId = ''
            initialFormValues.careGiver.pcspDate = ''
        }
        setDataLoaded(true)
        return { ...initialFormValues }
    }, [])//eslint-disable-line

    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line


    useEffect(() => {
        if (menuItems !== undefined && menuItems !== null && menuItems !== '') {
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined) {
                setGender(menuItems.gender.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/genders?lang=${lang}`).then((gendersResp) => {
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.employment && menuItems.employment.length > 0 && menuItems.employment !== undefined) {
                setEmployment(menuItems.employment.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/employments?lang=${lang}`).then((employmentResp) => {
                    setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.education && menuItems.education.length > 0 && menuItems.education !== undefined) {
                setEducation(menuItems.education.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/educations?lang=${lang}`).then((educationResp) => {
                    setEducation(educationResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.income && menuItems.income.length > 0 && menuItems.income !== undefined) {
                setIncome(menuItems.income.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/incomes?lang=${lang}`).then((incomeResp) => {
                    setIncome(incomeResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.maritalStat && menuItems.maritalStat.length > 0 && menuItems.maritalStat !== undefined) {
                setMarital(menuItems.maritalStat.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/marital-status?lang=${lang}`).then((maritalResp) => {
                    setMarital(maritalResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.pronoun && menuItems.pronoun.length > 0 && menuItems.pronoun !== undefined) {
                setPronouns(menuItems.pronoun.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/pronouns?lang=${lang}`).then((pronounsResp) => {
                    setPronouns(pronounsResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.protocol && menuItems.protocol.length > 0 && menuItems.protocol !== undefined) {
                setProtocol(menuItems.protocol.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/protocol?lang=${lang}`).then((protocolResp) => {
                    setProtocol(protocolResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.race && menuItems.race.length > 0 && menuItems.race !== undefined) {
                setRaceEthnicity(menuItems.race.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/races?lang=${lang}`).then((raceResp) => {
                    setRaceEthnicity(raceResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.language && menuItems.language.length > 0 && menuItems.language !== undefined) {
                setLanguage(menuItems.language.map((c) => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/languages?lang=${lang}`).then((languageResp) => {
                    setLanguage(languageResp.data.body.map((c) => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.relationship && menuItems.relationship.length > 0 && menuItems.relationship !== undefined) {
                setRelationship(menuItems.relationship.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/relationships?lang=${lang}`).then((relationshipResp) => {
                    setRelationship(relationshipResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.disability && menuItems.disability.length > 0 && menuItems.disability !== undefined) {
                setDisability(menuItems.disability.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/disability?lang=${lang}`).then((disability) => {
                    setDisability(disability.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.programName && menuItems.programName.length > 0 && menuItems.programName !== undefined) {
                setProgramName(menuItems.programName.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-programName?lang=${lang}`).then((programNameId) => {
                    setProgramName(programNameId.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.assessmentType && menuItems.assessmentType.length > 0 && menuItems.assessmentType !== undefined) {
                setAssessmentType(menuItems.assessmentType.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-assessmentType?lang=${lang}`).then((assessmentTypeId) => {
                    setAssessmentType(assessmentTypeId.data.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.subRaces && menuItems.subRaces !== null && menuItems.subRaces !== '' && menuItems.subRaces !== undefined) {
                setSubAsians(menuItems.subRaces.subAsianRace.map((c) => ({ text: c.name, value: c.value })))
                setSubNative(menuItems.subRaces.subHawaiianRace.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/subraces?lang=${lang}`).then((subRaces) => {
                    setSubAsians(subRaces.data.body.subAsianRace.map(c => ({ text: c.name, value: c.value })))
                    setSubNative(subRaces.data.body.subHawaiianRace.map(c => ({ text: c.name, value: c.value })))
                })
            }
        } else {
            axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
            API.get(`menus/pronouns?lang=${lang}`), API.get(`menus/races?lang=${lang}`), API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/languages?lang=${lang}`),
            API.get(`menus/incomes?lang=${lang}`), API.get(`menus/relationships?lang=${lang}`), API.get(`menus/disability?lang=${lang}`), API.get(`menus/case-programName?lang=${lang}`), API.get(`menus/case-assessmentType?lang=${lang}`), API.get(`menus/protocol?lang=${lang}`), API.get(`menus/subraces?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp, employmentResp, pronounsResp,
                raceResp, maritalResp, educationResp, languageResp, incomeResp, relationshipResp, disability, programNameId, assessmentTypeId, protocolResp, subRaces) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setPronouns(pronounsResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setMarital(maritalResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setEducation(educationResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setRelationship(relationshipResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setDisability(disability.data.body.map(c => ({ text: c.name, value: c.value })))
                setProgramName(programNameId.data.body.map(c => ({ text: c.name, value: c.value })))
                setAssessmentType(assessmentTypeId.data.body.map(c => ({ text: c.name, value: c.value })))
                setProtocol(protocolResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setSubAsians(subRaces.data.body.subAsianRace.map(c => ({ text: c.name, value: c.value })))
                setSubNative(subRaces.data.body.subHawaiianRace.map(c => ({ text: c.name, value: c.value })))
            }))
        }
        setYears(years.map(c => ({ text: c, value: c })))
        if (subId === 'true') {
            setSubReg([{ id: 0, enable: true }])
        }
        if (assessType1 === 'true') {
            setAssessmentTypeId([{ id: 0, enable: true }])
        }
        if (protocolType === 'true') {
            setProtocolTypeId([{ id: 0, enable: true }])
        }
    }, [])// eslint-disable-line  

    const profileFormSchema = useMemo(() => {
        let obj = {}
        crRefused.forEach((c, i) => {
            inputEnable.forEach((k, j) => {
                assessmentTypeId.forEach((d, p) => {
                    subreq.forEach((l, m) => {
                        protocolTypeId.forEach((e, r) => { 
                            otherAsianString1.forEach((s,r)=>{
                                    otherNativeStringForCareReceiver.forEach((v)=>{
                          obj[i] = Yup.object().shape({
                            address: Yup.object({
                                postalCode: Yup.string().nullable().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("ZIP Code is a required field")),
                            }),
                            language: Yup.string().nullable(),
                            firstName: Yup.string().nullable().max(50).validateName().required(t("First Name is a required field")),
                            relationshipId: Yup.string().nullable().required(t("Relationship is a required field")),
                            otherRelationship: Yup.string().when('relationshipId',{
                                is:'14',
                                then:Yup.string().nullable().EmptySpace().otherRelation().required(t('Other Relationship field is required')),
                            }),
                            lastName: Yup.string().nullable().max(50).validateName().required(t("Last Name is a required field")),
                            emailAddress: Yup.string().nullable().validateEmail(),
                            phone: Yup.string().nullable().validatePhoneLength(),
                            homePhone: Yup.string().nullable().validatePhoneLength(),
                            subscriberId: l.enable && d.enable && userDetails.env !=='MOLINA' ? Yup.string().nullable().EmptySpace().matches(/^[0-9]*$/, t("Subscriber Id should be numeric value")).required(t("Subscriber Id is a required field")):!l.enable && d.enable && userDetails.env !=='MOLINA' ? Yup.string().nullable().EmptySpace().matches(/^[0-9]*$/, t("Subscriber Id should be numeric value")): l.enable && userDetails.env !=='MOLINA' ? Yup.string().nullable().EmptySpace().matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")) : userDetails.env ==='MOLINA' ? Yup.string().nullable().EmptySpace().LengthSubId().matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")):Yup.string().nullable().EmptySpace().matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")),
                            preferredName: Yup.string().nullable(),
                            dateOfBirth: !c.selected ? Yup.date().required(t("Please provide the required details")) : null,
                            assessType: d.enable ? Yup.string().nullable().required(t("Assessment Type is a required field")) : Yup.string().nullable(),
                            dobDay: Yup.number(),
                            dobYear: Yup.number(),
                            dobMonth: Yup.number(),
                            maritalStat: Yup.string().nullable(),
                            // pronouns: Yup.string().nullable(),
                            // // ssn: Yup.string().nullable().length(4, "SSN number must be exactly 4 digits").matches(/^[0-9]*$/, "SSN number must be exactly 4 digits"),
                            gender: Yup.string().nullable(),
                            middleName: Yup.string().nullable().validateName().length(1),
                            phoneAuthorized: Yup.bool(),
                            employmentId: Yup.string().nullable(),
                            educationId: Yup.string().nullable(),
                            incomeId: Yup.string().nullable(),
                            race: Yup.array(),
                            disability: Yup.string().nullable(),
                            tcareProtocol: e.enable ? Yup.string().nullable().required(t("Protocol Type is a required field")) : Yup.string().nullable(),
                            otherAsianRace:Yup.object({
                                otherSubRaceValue:s.enable ? Yup.string().validateName().onlyAlphabet().nullable().required(t('Required')) : Yup.string().nullable()
                            }),
                            otherNativeRace:Yup.object({
                                otherSubRaceValue:v.enable ? Yup.string().validateName().onlyAlphabet().nullable().required(t('Required'))  : Yup.string().nullable()
                            })
                          })
                        })
                    })
                })
             })
            }) 
        })
  })
        let valid = {
            careGiver: Yup.object({
                address: Yup.object({
                    addressLine1: Yup.string().EmptySpace().nullable().required(t("Address is required")),
                    state: Yup.string().nullable().required(t("State is a required field")),
                    postalCode: Yup.string().nullable().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("ZIP Code is a required field")),
                }),
                language: Yup.string().nullable(),
                firstName: Yup.string().nullable().max(50).validateName().required(t("First Name is a required field")),
                otherRelationship: Yup.string().nullable().min(3, t("Too Short!")).EmptySpace().otherRelation(),
                lastName: Yup.string().nullable().max(50).validateName().required(t("Last Name is a required field")),
                emailAddress: Yup.string().nullable().validateEmail(),
                phone: Yup.string().nullable().validatePhoneLength().required(t("Mobile Number is a required field")),
                homePhone: Yup.string().nullable().validatePhoneLength(),
                subscriberId: Yup.string().nullable().EmptySpace().LengthSubId().length(9, t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")),
                preferredName: Yup.string().nullable(),
                dateOfBirth: !cgRefused ? Yup.date().required(t("Please provide the required details")) : Yup.date(),
                dobDay: Yup.number(),
                dobYear: Yup.number(),
                dobMonth: Yup.number(),
                pgName: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string().nullable(),
                pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
                maritalStat: Yup.string().nullable(),
                pronouns: Yup.string().nullable(),
                ssn: Yup.string().nullable().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numeric value")),
                gender: Yup.string().nullable(),
                middleName: Yup.string().nullable().validateName().length(1),
                phoneAuthorized: Yup.bool(),
                employmentId: Yup.string().nullable(),
                educationId: Yup.string().nullable(),
                incomeId: Yup.string().nullable(),
                race: Yup.array(),
                disability: Yup.string().nullable(),
                otherAsianRace:Yup.object({
                    otherSubRaceValue:otherAsianString ? Yup.string().validateName().onlyAlphabet().nullable().required(t('Required')) : Yup.string().nullable()
                }),
                otherNativeRace:Yup.object({
                    otherSubRaceValue:otherNativeStringForCaregiver ?  Yup.string().validateName().nullable().onlyAlphabet().required(t('Required')) : Yup.string().nullable()
                })
            }),
            careReceiver: Yup.object(obj)
        }

        return Yup.object().shape(valid).transform((_, val) => {
            let e = Object.keys(val.careReceiver).reduce((c, i) => {
                c[i] = val.careReceiver[i];
                return c;
            }, {})
            return {
                careGiver: val.careGiver,
                careReceiver: e
            }
          })
}, [inputEnable, crRefused, cgRefused, subreq, assessmentTypeId, programName1, otherAsianString1, otherNativeStringForCareReceiver, otherAsianString, otherNativeStringForCaregiver, protocolTypeId])// eslint-disable-line

    const referralFormSchema = (crRefused1) ? Yup.object().shape({
        careReceiver: Yup.object({
            pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            dateOfBirth: Yup.date(),
            phone: Yup.string().validatePhoneLength(),
            relationshipId: Yup.string().nullable(),
            otherRelationship: Yup.string().nullable().EmptySpace().otherRelation(),
            subscriberId: subId === 'true' && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")).required(t("Subscriber Id is a required field")): subId !== 'true' && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subId === 'true' && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessType: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
            tcareProtocol: protocolTypeId1 ? Yup.string().nullable().required(t("Protocol Type is a required field")) : Yup.string().nullable()
        }),
    }) : Yup.object().shape({
        careReceiver: Yup.object({
            pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace(),
                state: Yup.string(),
                city: Yup.string(),
                county: Yup.string(),
                postalCode: Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")),
            }),
            firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
            emailAddress: Yup.string().validateEmail(),
            dateOfBirth: Yup.date().required(t("Please provide the required details")),
            phone: Yup.string().validatePhoneLength(),
            relationshipId: Yup.string().nullable(),
            otherRelationship: Yup.string().nullable().EmptySpace().otherRelation().min(3, t("Too Short!")),
            subscriberId: subId === 'true' && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")).required(t("Subscriber Id is a required field")): subId !== 'true' && assessmentTypeId1 && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine numeric values")).matches(/^[0-9]*$/,t("Subscriber Id should numeric value")): subId === 'true' && userDetails.env !=='MOLINA' ? Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")).required(t("Subscriber Id is a required field")): userDetails.env ==='MOLINA' ? Yup.string().EmptySpace().matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")):Yup.string().EmptySpace().length(9,t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/,t("Subscriber Id should be alpha-numeric")),
            assessType: assessmentTypeId1 ? Yup.string().required(t("Assessment Type is a required field")) : Yup.string(),
            tcareProtocol: protocolTypeId1 ? Yup.string().nullable().required(t("Protocol Type is a required field")) : Yup.string().nullable()
        }),
    })



    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            if (page2) {
                setDisable(true)
                setFormLoading(true)
                submitForm()
                setFormLoading(false)
            }
            else {
                if (crError.filter((x) => x.crErrorWarn).length === 0 && !errWarning && !errWarning1) {
                    setPage1(false)
                    setPage2(true)
                    nextRef.current?.focus()
                }
            }
        },

        // validate: values => {
        //     let errors = { careReceiver: [] };
        //     let strRegex = /^[a-zA-Z'_^-\s]*$/g
        //     let isErrorExists = false;
        //     values.careReceiver.forEach((obj, index) => {
        //         let curErrObj = {};
        //         if (obj.relationshipId === 14) {
        //             let isValid = false;
        //             if (obj.otherRelationship !== '' && strRegex.test(obj.otherRelationship)) {
        //                 isValid = true;
        //             }
        //             if (!isValid) {
        //                 isErrorExists = true;
        //                 curErrObj.otherRelationship = 'Other Relationship field is required';
        //             }
        //         }
        //         errors.careReceiver.push(curErrObj);
        //     });
        //     if (!isErrorExists) {
        //         errors = {};
        //     }
        //     console.log("validate errors >>> ", errors);
        //     return errors;
        // }
    })
    const referralForm = useFormik({
        initialValues: referralinitialValues,
        validationSchema: referralFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            if (!errWarning2) {
                referralSubmit()
            }
            setFormLoading(false)
        }
    })

    const getEmailAddress = (value) => {
        var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        if (regex.test(value)) {
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/emailValidate?email=${value}`).then((res) => {
                if (res.status === 200) {
                    setErrWarning1(false)
                    setCgEmailError(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        if (!disableAssociateCr) {
                            setMailDialog(true)
                        }
                        setCaseId(err.response.data.caseId)
                        setErrWarning1(true)
                        setCustomSubWarn1(err.response.data.errorMessage)  
                }
            })
        } else {
            setCgEmailError(false)
            setErrWarning1(false)
        }
    }

    // const getSubscriberId = (value) => {
    //     if (value.length === 9) {
    //         API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/SubIdValidate?subId=${value}`).then((res) => {
    //             if (res.status === 200) {
    //                 setErrWarning(false)
    //             }
    //         }).catch(err => {
    //             if (err.response.status === 406) {
    //                 dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
    //                 setErrWarning(true)
    //                 // setCustomSubWarn(err.response.data.errorMessage)
    //                 // profileForm.setValues({ ...profileForm.values, careGiver: {...profileForm.values.careGiver,subscriberId: ''} })
    //             }
    //         })

    //     } else {
    //         setErrWarning(false)
    //     }

    // }

    const getAddress = (value, field, i) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                if (res.status === 200) {
                    setZipEnable(true)
                    if (field === 'cg') {
                        profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, address: { ...profileForm.values.careGiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, timeZone: res.data.body.timeZone } } })
                    }
                    // else if (field === 'cr') {
                    //     console.log(profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver[i], address: { ...profileForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    //     )
                    //     profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver[i], address: { ...profileForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    // }
                }
            })
        } else if (value.length === 0) {
            setZipEnable(false)
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, address: { ...profileForm.values.careGiver.address, state: "", city: "", country: "", timeZone: "" } } })
        }
    }

    const getAddress2 = (value, field, i) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                if (res.status === 200) {
                    setZipEnable1(true)
                    referralForm.setValues({ ...referralForm.values, careReceiver: { ...referralForm.values.careReceiver, address: { ...referralForm.values.careReceiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, county: res.data.body.county, timeZone: res.data.body.timeZone } } })

                    // else if (field === 'cr') {
                    //     console.log(referralForm.setValues({ ...referralForm.values, careReceiver: { ...referralForm.values.careReceiver[i], address: { ...referralForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    //     )
                    //     referralForm.setValues({ ...referralForm.values, careReceiver: { ...referralForm.values.careReceiver[i], address: { ...referralForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    // }
                }
            })
        } else if (value.length === 0) {
            setZipEnable1(false)
            referralForm.setValues({ ...referralForm.values, careReceiver: { ...referralForm.values.careReceiver, address: { ...referralForm.values.careReceiver.address, state: "", city: "", country: "", timeZone: "" } } })
        }
    }

    const setRefused = (side, value) => {
        if (side === 'cg') {
            setCgRefused(value)
            // setDateRefused(true)
        } else if (side === 'cr') {
            setCrRefused1(value)
        }
    }
    const setCrRefusing = (i, value) => {
        setCrRefused(crRefused.map((c, j) => {
            if (j === i) {
                c.selected = value
            }
            return c
        }))
    }

    const addNewCareReceiver = () => {
        profileForm.setValues({
            ...profileForm.values, careReceiver: [...profileForm.values.careReceiver, {
                "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "county": "", "postalCode": "" },
                "language": "",
                "firstName": "",
                "lastName": "",
                "relationshipId": "",
                "otherRelationship": "",
                "emailAddress": "",
                "phone": "",
                "areaCode": "",
                "subscriberId": "",
                "preferredName": "",
                "hispanic": false,
                "nativeAmerican": false,
                "dateOfBirth": '',
                "dobDay": 0,
                "phoneType": "",
                "pacificIslander": false,
                "otherRace": false,
                "refused": false,
                "medicaid": false,
                "medicare": false,
                "tricare": false,
                "otherInsurer": false,
                "multiRacial": false,
                "dobYear": 0,
                "dobMonth": 0,
                "uninsured": false,
                "maritalStat": "",
                "phoneAuthorized": false,
                "pronouns": null,
                "samsId": "",
                "ssn": "",
                "homePhone": "",
                "gender": "",
                "employmentId": 0,
                "educationId": 0,
                "incomeId": 0,
                "white": false,
                "black": false,
                "asian": false,
                "middleEastern": false,
                "middleName": "",
                "relationship": "",
                "race": [],
                "disability": "",
                "assessType": "",
                "tcareProtocol": "",
                "ethnicity": [],
                "otherAsianRace": {
                    "otherSubRaceId": '',
                    "otherSubRaceValue": "",
                    "raceId": 0
                },
                "otherNativeRace": {
                    "otherSubRaceId": '',
                    "otherSubRaceValue": "",
                    "raceId": 0
                }
            }]
        })
        setCareReceiverList(careReceiverList + 1)
        setInputEnable([...inputEnable, { id: careReceiverList + 1, enable: false }])
        setCrRefused([...crRefused, { id: careReceiverList + 1, selected: false }])
        setCrError([...crError, { id: careReceiverList + 1, crErrorWarn: false, crCustomMsg: '', subDisable: false }])
        if (birthyear === 'true') {
            setBirthYear2([...birthyear2, { id: careReceiverList + 1, enable: true }])
        }
        if (subId === 'true') {
            setSubReg([...subreq, { id: careReceiverList + 1, enable: true }])
        }
        if (assessType1 === 'true') {
            setAssessmentTypeId([...assessmentTypeId, { id: careReceiverList + 1, enable: true }])
        }
        if (protocolType === 'true') {
            setProtocolTypeId([...protocolTypeId, { id: careReceiverList + 1, enable: true }])
        }
        setNotEnable([...notEnable, { id: careReceiverList + 1, enable: false }])
        setOtherAsianString1([...otherAsianString1, { id: careReceiverList + 1, enable: false }])
        setOtherNativeStringForCareReceiver([...otherNativeStringForCareReceiver, { id: careReceiverList + 1, enable: false }])
        setCareReceiverList(careReceiverList + 1)
        setOtherAsianCrValue([...otherAsianCrValue,{id:careReceiverList +1,race:[]}])
        setOtherNativeCrValue([...otherNativeCrValue,{id:careReceiverList +1,race:[]}])
        setTempSub([...tempSub,{id:careReceiverList +1,subId:''}])
    }

    const removeCareReceiver = (index) => {
        if (profileForm.values.careReceiver.length > 1) {
            profileForm.setValues({ ...profileForm.values, careReceiver: profileForm.values.careReceiver.filter((c, i) => i !== index) })
            setCareReceiverList(careReceiverList - 1)
            setCrRefused(crRefused.filter((c, i) => i !== index))
            setInputEnable(inputEnable.filter((c, i) => i !== index))
            setTempSub(tempSub.filter((c,i) => i !== index))
        } else {
            dispatch(setAlert({ title: 'Warning', subtitle: 'Atleast one care receiver must needed to create a case', type: 'warning', active: true }))
        }
    }


    const getAddress1 = (values, i) => {
        // setActiveZip(i)
        if (values.length === 5) {
            API.get(`/intake/zones?zipCode=${values}`).then((res) => {
                if (res.status === 200) {
                    setNotEnable(notEnable.map((c, z) => {
                        if (i === z) {
                            c.enable = true
                        }
                        return c
                    }))

                    profileForm.setValues({
                        ...profileForm.values, careReceiver: profileForm.values.careReceiver.map((c, k) => {
                            // console.log(profileForm.values.careReceiver[i].address.state = res.data.body.state)
                            if (k === i) {
                                profileForm.values.careReceiver[i].address.state = res.data.body.state
                                profileForm.values.careReceiver[i].address.city = res.data.body.city
                                // profileForm.values.careReceiver[i].address.country = res.data.body.country
                                profileForm.values.careReceiver[i].address.county = res.data.body.county
                            }
                            return c
                        })
                    })

                    // profileForm.setValues({ ...profileForm.values, careReceiver: { ...profileForm.values.careReceiver[i], address: { ...profileForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                } else {
                    setNotEnable(notEnable.map((c, j) => {
                        if (i === j) {
                            c.enable = false
                        }
                        return c
                    }))
                }
            })
        } else if (values.length === 0) {
            profileForm.setValues({
                ...profileForm.values, careReceiver: profileForm.values.careReceiver.map((c, k) => {
                    // console.log(profileForm.values.careReceiver[i].address.state = res.data.body.state)
                    if (k === i) {
                        profileForm.values.careReceiver[i].address.state = ""
                        profileForm.values.careReceiver[i].address.city = ""
                        // profileForm.values.careReceiver[i].address.country = ""
                        profileForm.values.careReceiver[i].address.county = ""
                    }
                    return c
                })
            })
            setNotEnable(notEnable.map((c, j) => {
                if (i === j) {
                    c.enable = false
                }
                return c
            }))
        }

    }
    const getSubscriberId1 = (values, i) => {
        var regex = /^[a-zA-Z0-9_]*$/;        
        if (values && regex.test(values) && tempSub[i].subId !== values) {
            API.get(`/intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/SubIdValidate?subId=${values}`).then((res) => {
                if (res.status === 200) {
                    if(res.data.errorCode !== undefined){
                        dispatch(setAlert({ title: t('Warning'), subtitle: res.data.errorMessage, type: 'warning', active: true }))
                        let value = crError.map((e, j) => {
                            if (j === i) {
                                e.crErrorWarn = true
                                e.crCustomMsg = res.data.errorMessage
                                e.subDisable = false
                            }
                            return e
                        })
                        setCrError(value)
                    }else{
                        let value = crError.map((e, j) => {
                            if (j === i) {
                                e.crErrorWarn = false
                                e.subDisable = false
                            }
                            return e
                        })
                        setCrError(value)
                    }
                }else if(res.status === 500){
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    if(err.response.data.cgId === 0 || err.response.data.crId === 0 || err.response.data.errorCode === 'ERR_PENDING_REF'){
                        setSubDialog1(true)
                        setReferral(err.response.data)
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        let value = crError.map((e, j) => {
                            if (j === i) {
                                e.crErrorWarn = true
                                e.crCustomMsg = err.response.data.errorMessage
                                e.subDisable = false
                            }
                            return e;
                        })
                        setCrError(value)
                    } else if (err.response.data.errorCode === "ERR_PENDING_REF") {
                        setSubDialog1(true)
                        setReferral(err.response.data)
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    }
                    else {
                        setSubDialog(true)
                        setCaseId(err.response.data.caseId)
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        let value = crError.map((e, j) => {
                            if (j === i) {
                                e.crErrorWarn = true
                                e.crCustomMsg = err.response.data.errorMessage
                                e.subDisable = false
                            }
                            return e;
                        })
                        setCrError(value)
                    }
                    // else if(userDetails.role[0] === 'screener'){
                    //     setSubDialog(true)
                    //     setCaseId(err.response.data.caseId)
                    //     dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    //         let value = crError.map((e, j) => {
                    //             if (j === i) {
                    //                 e.crErrorWarn = true
                    //                 e.crCustomMsg = err.response.data.errorMessage
                    //                 e.subDisable = false
                    //             }
                    //             return e;
                    //         })
                    //         setCrError(value)
                    // }else if((err.response.data.cmId !== userDetails.caremanagerId) || (userDetails.role.length === 2 && userDetails.role.includes('screener') && userDetails.role.includes('admin'))){
                    //     setNotEnable(notEnable.map((c, z) => {
                    //         if (i === z) {
                    //             c.enable = true
                    //         }
                    //         return c
                    //     }))
                    //     profileForm.values.careReceiver[i] = err.response.data.person
                    //     profileForm.values.careReceiver[i].assessType = err.response.data.person.assessTypeId
                    //     if(birthyear1 && err.response.data.person.dobDob !== 0){
                    //         profileForm.values.careReceiver[i].dateOfBirth = err.response.data.person.dobYear + "/" + err.response.data.person.dobMonth + "/" + err.response.data.person.dobDay
                    //     }else if(err.response.data.person.dobDob !== 0){
                    //         profileForm.values.careReceiver[i].dateOfBirth = err.response.data.person.dobYear
                    //     }
                    //     if( err.response.data.person.relationshipId === 0){
                    //         profileForm.values.careReceiver[i].relationshipId = null
                    //     }
                    //     profileForm.values.careReceiver[i].race = []
                    //     dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    //     let value = crError.map((e, j) => {
                    //         if (j === i) {
                    //             e.crErrorWarn = false
                    //             e.crCustomMsg = err.response.data.errorMessage
                    //             e.subDisable = true
                    //         }
                    //         return e;
                    //     })
                    //     setCrError(value)
                    // }else{
                    // setSubDialog(true)
                    // setCaseId(err.response.data.caseId)
                    // dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    //     let value = crError.map((e, j) => {
                    //         if (j === i) {
                    //             e.crErrorWarn = true
                    //             e.crCustomMsg = err.response.data.errorMessage
                    //             e.subDisable = false
                    //         }
                    //         return e;
                    //     })
                    //     setCrError(value)
                    // }
                }else if(err.response.status === 500){
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            })

        } else {
            let value = crError.map((e, j) => {
                if (j === i) {
                    e.crErrorWarn = tempSub[i].subId === values && e.crErrorWarn ? e.crErrorWarn : false
                    e.subDisable = false
                }
                return e
            })
            setCrError(value)
        }
        setTempSub(tempSub.map((c, j) => {
            if ( i === j){
                c.subId = values
            }
            return c
        }))
    }

    const otherValue = (values, i) => {
        // setActivatedIndex(i)
        if (values === 14) {
            setInputEnable(inputEnable.map((c, j) => {
                if (i === j) {
                    c.enable = true
                }
                return c
            }))
        } else {
            setInputEnable(inputEnable.map((c, j) => {
                if (i === j) {
                    c.enable = false
                }
                return c
            }))

            profileForm.setValues({
                ...profileForm.values, careReceiver: profileForm.values.careReceiver.map((c, j) => {
                    if (j === i) {
                        c.relationshipId = values
                        c.otherRelationship = ''
                    }
                    return c
                })
            })
            // profileForm.setValues({...profileForm,relationshipId:values,otherRelationship :''})
        }

    }

    const changeRacevalues = ((val) => {
        if (val.value === 1) {
            profileForm.values.careGiver.white = !profileForm.values.careGiver.white;
        } else if (val.value === 2) {
            profileForm.values.careGiver.black = !profileForm.values.careGiver.black;
        } else if (val.value === 3) {
            profileForm.values.careGiver.hispanic = !profileForm.values.careGiver.hispanic;
        } else if (val.value === 4) {
            profileForm.values.careGiver.asian = !profileForm.values.careGiver.asian;
        } else if (val.value === 5) {
            profileForm.values.careGiver.nativeAmerican = !profileForm.values.careGiver.nativeAmerican;
        } else if (val.value === 7) {
            profileForm.values.careGiver.middleEastern = !profileForm.values.careGiver.middleEastern;
        } else if (val.value === 6) {
            profileForm.values.careGiver.pacificIslander = !profileForm.values.careGiver.pacificIslander;
        } else if (val.value === 9) {
            profileForm.values.careGiver.refused = !profileForm.values.careGiver.refused;
        } else if (val.value === 8) {
            profileForm.values.careGiver.otherRace = !profileForm.values.careGiver.otherRace;
        } else { }
    })



    const changeCrRaceValues = ((val, i) => {
        if (val === 1) {
            profileForm.values.careReceiver[i].white = !profileForm.values.careReceiver[i].white;
        } if (val === 2) {
            profileForm.values.careReceiver[i].black = !profileForm.values.careReceiver[i].black;
        } if (val === 3) {
            profileForm.values.careReceiver[i].hispanic = !profileForm.values.careReceiver[i].hispanic;
        } if (val === 4) {
            profileForm.values.careReceiver[i].asian = !profileForm.values.careReceiver[i].asian;
        } if (val === 5) {
            profileForm.values.careReceiver[i].nativeAmerican = !profileForm.values.careReceiver[i].nativeAmerican;
        } if (val === 7) {
            profileForm.values.careReceiver[i].middleEastern = !profileForm.values.careReceiver[i].middleEastern;
        } if (val === 6) {
            profileForm.values.careReceiver[i].pacificIslander = !profileForm.values.careReceiver[i].pacificIslander;
        } if (val === 9) {
            profileForm.values.careReceiver[i].refused = !profileForm.values.careReceiver[i].refused;
        } if (val === 8) {
            profileForm.values.careReceiver[i].otherRace = !profileForm.values.careReceiver[i].otherRace;
        } else { }

    })

    const checkCgInsurances = (value, val) => {
        let checkedValue = val.name.split('.')[1]
        if (checkedValue === 'uninsured' && value) {
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, medicaid: false, medicare: false, tricare: false, otherInsurer: false, uninsured: true } })
        } else if (checkedValue === 'medicaid' && value) {
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, medicaid: true, uninsured: false } })
        } else if (checkedValue === 'medicare' && value) {
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, medicare: true, uninsured: false } })
        } else if (checkedValue === 'tricare' && value) {
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, tricare: true, uninsured: false } })
        } else if (checkedValue === 'otherInsurer' && value) {
            profileForm.setValues({ ...profileForm.values, careGiver: { ...profileForm.values.careGiver, otherInsurer: true, uninsured: false } })
        }
    }

    const otherValue1 = (values) => {
        if (values === 14) {
            setInputEnable1(true)
        } else {
            setInputEnable1(false)
            // profileForm.setValues({...profileForm,relationshipId:values,otherRelationship :''})
        }
    }

    const getSubscriberId= (value,field) => {
        var regex = /^[a-zA-Z0-9_]*$/;
        if(userDetails.env !=='MOLINA' && value.length === 9 && regex.test(value) && tempCrSub !== value){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/SubIdValidate?subId=${value}`).then((res)=>{
                if(res.status === 200){
                    setErrWarning2(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    if(err.response.data.cgId === 0 || err.response.data.crId === 0 || err.response.data.cmId === 0){
                        setSubDialog1(true)
                        setReferral(err.response.data)
                        // dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    } else if ((parseInt(organization) === userDetails.orgId && userDetails.role.includes('screener')) || (parseInt(organization) !== userDetails.orgId && userDetails.role.includes('dynamic_scr'))) {
                        // setSubDialog(true)
                        setCaseId(err.response.data.caseId)
                        // dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    } else if ((parseInt(organization) !== userDetails.orgId && (err.response.data.cmId !== userDetails.caremanagerId) || (userDetails.role.includes('dynamic_scr') && userDetails.role.includes('dynamic_admin'))) ||
                        (parseInt(organization) === userDetails.orgId && (err.response.data.cmId !== userDetails.caremanagerId) || (userDetails.role.includes('screener') && userDetails.role.includes('admin')))) {
                        referralForm.values.careReceiver = err.response.data.person
                        referralForm.values.careReceiver.assessType = err.response.data.person.assessTypeId
                        if (birthyear1 && err.response.data.person.dobDob !== 0) {
                            referralForm.values.careReceiver.dateOfBirth = err.response.data.person.dobYear + "/" + err.response.data.person.dobMonth + "/" + err.response.data.person.dobDay
                        } else if (err.response.data.person.dobDob !== 0) {
                            referralForm.values.careReceiver.dateOfBirth = err.response.data.person.dobYear
                        }
                        if (err.response.data.person.relationshipId === 0) {
                            referralForm.values.careReceiver.relationshipId = null
                        }
                        referralForm.values.careReceiver.race = []
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(false)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    } else {
                        setSubDialog(true)
                        setCaseId(err.response.data.caseId)
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    }
                }
            })
        } else if (userDetails.env ==='MOLINA' && regex.test(value) && ((value.length === 9 || value.length === 14) && tempCrSub !== value)){
            API.get(`intake/organizations/${localStorage.getItem('orgid')}/clients/${0}/SubIdValidate?subId=${value}`).then((res)=>{
                if(res.status === 200){
                    setErrWarning2(false)
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    if(err.response.data.cgId === 0 || err.response.data.crId === 0  || err.response.data.errorCode === 'ERR_PENDING_REF'){
                        setSubDialog1(true)
                        setReferral(err.response.data)
                        // dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    }else if((parseInt(organization) === userDetails.orgId && userDetails.role.includes('screener')) || (parseInt(organization) !== userDetails.orgId && userDetails.role.includes('dynamic_scr'))){
                        // setSubDialog(true)
                        setCaseId(err.response.data.caseId)
                        // dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(true)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    }else if((parseInt(organization) !== userDetails.orgId && (err.response.data.cmId !== userDetails.caremanagerId) || (userDetails.role.includes('dynamic_scr') && userDetails.role.includes('dynamic_admin'))) ||
                        (parseInt(organization) === userDetails.orgId && (err.response.data.cmId !== userDetails.caremanagerId) || (userDetails.role.includes('screener') && userDetails.role.includes('admin')))){
                        referralForm.values.careReceiver = err.response.data.person
                        referralForm.values.careReceiver.assessType = err.response.data.person.assessTypeId
                        if(birthyear1 && err.response.data.person.dobDob !== 0){
                            referralForm.values.careReceiver.dateOfBirth = err.response.data.person.dobYear + "/" + err.response.data.person.dobMonth + "/" + err.response.data.person.dobDay
                        }else if(err.response.data.person.dobDob !== 0){
                            referralForm.values.careReceiver.dateOfBirth = err.response.data.person.dobYear
                        }
                        if( err.response.data.person.relationshipId === 0){
                            referralForm.values.careReceiver.relationshipId = null
                        }
                        referralForm.values.careReceiver.race = []
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        setErrWarning2(false)
                        setCustomSubWarn2(err.response.data.errorMessage)
                    }else{
                    setSubDialog(true)
                    setCaseId(err.response.data.caseId)
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    setErrWarning2(true)
                    setCustomSubWarn2(err.response.data.errorMessage)
                    }
                }
            })
        } else{
            setErrWarning2(false)
        }
        setTempCrSub(value)
    }

    useLayoutEffect(() => {
        if (profileForm.values.careGiver.race.includes(29)) {
            setOtherAsianString(true)
        } else {
            setOtherAsianString(false)
            profileForm.values.careGiver.otherAsianRace.otherSubRaceValue = ''

        }
        if (profileForm.values.careGiver.race.includes(35)) {
            setOtherNativeStringForCaregiver(true)
        } else {
            setOtherNativeStringForCaregiver(false)
            profileForm.values.careGiver.otherNativeRace.otherSubRaceValue = ''
        }
    }, [profileForm.values.careGiver.race, nextButton])

    useLayoutEffect(() => {
        profileForm.values.careReceiver.forEach((c, i) => {
            if (profileForm.values.careReceiver[i].race.includes(29)) {
                setOtherAsianString1(otherAsianString1.map((c, j) => {
                    if (i === j) {
                        c.enable = true
                    }
                    return c
                }))
                c.otherAsianRace.raceId = 4
                c.otherAsianRace.otherSubRaceId = 29
            } else {
                setOtherAsianString1(otherAsianString1.map((c, j) => {
                    if (i === j) {
                        c.enable = false
                    }
                    return c
                }))
                c.otherAsianRace.raceId = ''
                c.otherAsianRace.otherSubRaceId = ''
                c.otherAsianRace.otherSubRaceValue = ''
            }
            if (profileForm.values.careReceiver[i].race.includes(35)) {
                setOtherNativeStringForCareReceiver(otherNativeStringForCareReceiver.map((c, j) => {
                    if (i === j) {
                        c.enable = true
                    }
                    return c
                }))
                c.otherNativeRace.raceId = 6
                c.otherNativeRace.otherSubRaceId = 35
            } else {
                c.otherNativeRace.raceId = ''
                c.otherNativeRace.otherSubRaceId = ''
                c.otherNativeRace.otherSubRaceValue = ''
                setOtherNativeStringForCareReceiver(otherNativeStringForCareReceiver.map((c, j) => {
                    if (i === j) {
                        c.enable = false
                    }
                    return c
                }))
            }
        })
    }, [profileForm.values.careReceiver, nextButton])

    const submitForm = () => {
        profileForm.values.careReceiver.map((c, i) => {
            if (birthyear1) {
                c.dateOfBirth = c.dateOfBirth.split('/');
                c.dobDay = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[2] : c.dateOfBirth ? 1 : 0;
                c.dobMonth = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[1] : c.dateOfBirth ? 1 : 0;
                c.dobYear = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[0] : c.dateOfBirth ? c.dateOfBirth : 0;
            } else {
                c.dobDay = crRefused[i].selected ? 0 : c.dateOfBirth ? 1 : 0;
                c.dobMonth = crRefused[i].selected ? 0 : c.dateOfBirth ? 1 : 0;
                c.dobYear = crRefused[i].selected ? 0 : c.dateOfBirth ? c.dateOfBirth : 0;
            }
            return null;
        })
        let otherValue = 0;
        let otherRelation = 0;
        profileForm.values.careReceiver.map((c, i) => {
            if (c.relationshipId === 14) {
                otherValue = otherValue + 1
                if (c.otherRelationship.length > 0) {
                    otherRelation = otherRelation + 1
                }
            }
            return null;
        })
        profileForm.values.careReceiver.forEach((c, i) => {
            c.white = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(1)
                  c.black = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(2)
                  c.asian = otherAsianCrValue[i].race.length > 0 ? true :profileForm.values.careReceiver[i].race.includes(4)
                  c.middleEastern = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(7)
                  c.hispanic = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(3)
                  c.nativeAmerican = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(5)
                  c.pacificIslander = otherNativeCrValue[i].race.length > 0 ? true :  profileForm.values.careReceiver[i].race.includes(6)
                  c.otherRace = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(8)
                  c.refused = profileForm.values.careReceiver[i].race !== undefined && profileForm.values.careReceiver[i].race.includes(9)
                  c.ethnicity = profileForm.values.careReceiver[i].race.includes(9) ? []:[...otherAsianCrValue[i].race,...otherNativeCrValue[i].race]
                  return c;
          })
        
        if (otherValue === otherRelation) {
            if (profileForm.values.careGiver.dateOfBirth && birthyear1) {
                profileForm.values.date = profileForm.values.careGiver.dateOfBirth.split('/');
                profileForm.values.day = profileForm.values.date[2]
                profileForm.values.month = profileForm.values.date[1]
                profileForm.values.year = profileForm.values.date[0]
            }
            profileForm.values.careReceiver.map((k, i) => {
                k.firstName = k.firstName.trim()
                k.lastName = k.lastName.trim()
            })

            let details = {
                organizationId: localStorage.getItem('orgid'),
                caregiver: {
                    address: {
                        timeZone: profileForm.values.careGiver.address.timeZone,
                        country: profileForm.values.careGiver.address.country,
                        state: profileForm.values.careGiver.address.state,
                        addressLine1: profileForm.values.careGiver.address.addressLine1,
                        addressLine2: profileForm.values.careGiver.address.addressLine2,
                        city: profileForm.values.careGiver.address.city,
                        postalCode: profileForm.values.careGiver.address.postalCode,
                        addressLine3: profileForm.values.careGiver.address.addressLine3
                    },
                    language: profileForm.values.careGiver.language ? profileForm.values.careGiver.language : null,
                    firstName: profileForm.values.careGiver.firstName.trim(),
                    lastName: profileForm.values.careGiver.lastName.trim(),
                    emailAddress: profileForm.values.careGiver.emailAddress,
                    phone: profileForm.values.careGiver.phone,
                    subscriberId: profileForm.values.careGiver.subscriberId,
                    preferredName: profileForm.values.careGiver.preferredName,
                    dobDay: cgRefused || profileForm.values.careGiver.dateOfBirth === 0 ? 0 : birthyear1 ? parseInt(profileForm.values.day) : parseInt(profileForm.values.careGiver.dobDay ? profileForm.values.careGiver.dobDay : 1),
                    dobYear: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.year) : parseInt(profileForm.values.careGiver.dateOfBirth ? profileForm.values.careGiver.dateOfBirth : 0),
                    dobMonth: cgRefused || profileForm.values.careGiver.dateOfBirth === 0 ? 0 : birthyear1 ? parseInt(profileForm.values.month) : parseInt(profileForm.values.careGiver.dobMonth ? profileForm.values.careGiver.dobMonth : 1),
                    maritalStat: profileForm.values.careGiver.maritalStat ? profileForm.values.careGiver.maritalStat : null,
                    pronouns: profileForm.values.careGiver.pronouns ? profileForm.values.careGiver.pronouns : null,
                    ssn: profileForm.values.careGiver.ssn,
                    homePhone: profileForm.values.careGiver.homePhone,
                    gender: profileForm.values.careGiver.gender ? profileForm.values.careGiver.gender : null,
                    employmentId: profileForm.values.careGiver.employment ? profileForm.values.careGiver.employment : 0,
                    educationId: profileForm.values.careGiver.education ? profileForm.values.careGiver.education : 0,
                    incomeId: profileForm.values.careGiver.income ? profileForm.values.careGiver.income : 0,
                    middleName: profileForm.values.careGiver.middleName,
                    white: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(1),
                    black: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(2),
                    asian: otherAsianCgValue.length > 0 ? true : profileForm.values.careGiver.race.includes(4),
                    middleEastern: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(7),
                    hispanic: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(3),
                    nativeAmerican: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(5),
                    pacificIslander: otherNativeCgValue.length > 0 ? true : profileForm.values.careGiver.race.includes(6),
                    otherRace: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(8),
                    multiRacial: false,
                    uninsured: profileForm.values.careGiver.uninsured,
                    refused: profileForm.values.careGiver.race !== undefined && profileForm.values.careGiver.race.includes(9),
                    medicaid: profileForm.values.careGiver.medicaid,
                    medicare: profileForm.values.careGiver.medicare,
                    tricare: profileForm.values.careGiver.tricare,
                    otherInsurer: profileForm.values.careGiver.otherInsurer,
                    phoneAuthorized: contact === 'true' ? true : profileForm.values.careGiver.phoneAuthorized,
                    disability: profileForm.values.careGiver.disability ? profileForm.values.careGiver.disability : null,
                    pgName: profileForm.values.careGiver.pgName ? profileForm.values.careGiver.pgName : 0,
                    personId : profileForm.values.careGiver.personId ,
                    ethnicity: profileForm.values.careGiver.race.includes(9) ? []:[...otherAsianCgValue,...otherNativeCgValue],
                    otherAsianRace:{
                        otherSubRaceId:profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 29 :'',
                        otherSubRaceValue:profileForm.values.careGiver.otherAsianRace.otherSubRaceValue,
                        raceId:profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherAsianRace.otherSubRaceValue !== null ? 4 :''
                    },
                    otherNativeRace: {
                        otherSubRaceId: profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 35 : '',
                        otherSubRaceValue: profileForm.values.careGiver.otherNativeRace.otherSubRaceValue,
                        raceId: profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== '' && profileForm.values.careGiver.otherNativeRace.otherSubRaceValue !== null ? 6 : ''
                    }
                },
                noCaregiver: false,
                reassessReason: reaccessReason ? reaccessReason : 0,
                careReceiver: profileForm.values.careReceiver,
                pcspDate: profileForm.values.careGiver.pcspDate ? profileForm.values.careGiver.pcspDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
            }
            API.post('cases', details).then((res) => {
                if (res.status === 200) {
                    setDisable(false)
                    if (res.data.caseId.length > 1) {
                        history.push(`/case_list`);
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Cases created successfully'), active: true, type: 'success' }))
                        history.replace()
                    } else {
                        history.push(`/casedata/${res.data.caseId[0]}`);
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Case created successfully'), active: true, type: 'success' }))
                        history.replace()
                    }
                }
            }).catch(err => {
                setDisable(false)
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    profileForm.values.careReceiver.map((c, i) => {
                        if (birthyear1) {
                            c.dobDay = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[2] : c.dateOfBirth ? 1 : 0;
                            c.dobMonth = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[1] : c.dateOfBirth ? 1 : 0;
                            c.dobYear = crRefused[i].selected ? 0 : birthyear2[i].enable ? c.dateOfBirth[0] : c.dateOfBirth ? c.dateOfBirth : 0;
                            c.dateOfBirth = c.dobYear + "/" + c.dobMonth + "/" + c.dobDay
                        } else {
                            c.dobDay = crRefused[i].selected ? 0 : c.dateOfBirth ? 1 : 0;
                            c.dobMonth = crRefused[i].selected ? 0 : c.dateOfBirth ? 1 : 0;
                            c.dobYear = crRefused[i].selected ? 0 : c.dateOfBirth ? c.dateOfBirth : 0;
                        } return null;
                    })
                } else {
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                }
            }).finally()
        } else {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('One of the care receiver relationship field is empty'), active: true, type: 'warning' }))
        }
    }

    const referralSubmit = () => {
        if (referralForm.values.careReceiver !== undefined && referralForm.values.careReceiver.dateOfBirth && birthyear1) {
            referralForm.values.careReceiver.dateOfBirth = referralForm.values.careReceiver.dateOfBirth.split('/')
            referralForm.values.careReceiver.dobDay = referralForm.values.careReceiver.dateOfBirth[2]
            referralForm.values.careReceiver.dobMonth = referralForm.values.careReceiver.dateOfBirth[1]
            referralForm.values.careReceiver.dobYear = referralForm.values.careReceiver.dateOfBirth[0]
        }
        let details = {
            "pcspDate": referralForm.values.careReceiver.pcspDate ? referralForm.values.careReceiver.pcspDate.toString().replace('/', '-').replace('/', '-').slice(0, 10) : null,
            "caseId": 0,
            "organizationId": organization,
            "referrerCaseFollowup": {
                "reason": null,
                "caseId": 0,
                "notes": null,
                "followupDate": null,
                "referredByName": userDetails.sub,
                "rcfId": 0
            },
            "careReceiver": {
                "address": {
                    "state": referralForm.values.careReceiver.address.state,
                    "addressLine1": referralForm.values.careReceiver.address.addressLine1,
                    "addressLine2": "",
                    "city": referralForm.values.careReceiver.address.city,
                    "county": referralForm.values.careReceiver.address.county,
                    "postalCode": referralForm.values.careReceiver.address.postalCode,
                    "addressLine3": ""
                },
                "language": referralForm.values.careReceiver.language,
                "areaCode": "",
                "phone": referralForm.values.careReceiver.phone,
                "gender": '',
                "pronouns": null,
                "personId": 0,
                "firstName": referralForm.values.careReceiver.firstName.trim(),
                "lastName": referralForm.values.careReceiver.lastName.trim(),
                "emailAddress": referralForm.values.careReceiver.emailAddress,
                "maritalStat": "",
                "relationship": "",
                "relationshipId": referralForm.values.careReceiver.relationshipId || 0,
                "otherRelationship": referralForm.values.careReceiver.otherRelationship,
                "subscriberId": referralForm.values.careReceiver.subscriberId,
                "dobDay": crRefused ? 0 : birthyear1 ? parseInt(referralForm.values.careReceiver.dobDay) : parseInt(referralForm.values.careReceiver.dobDay ? referralForm.values.careReceiver.dobDay : 1),
                "dobMonth": crRefused ? 0 : birthyear1 ? parseInt(referralForm.values.careReceiver.dobMonth) : parseInt(referralForm.values.careReceiver.dobMonth ? referralForm.values.careReceiver.dobMonth : 1),
                "dobYear": crRefused ? 0 : birthyear1 ? parseInt(referralForm.values.careReceiver.dobYear) : parseInt(referralForm.values.careReceiver.dobYear ? referralForm.values.careReceiver.dobYear : 0),
                "preferredName": "",
                "hispanic": false,
                "nativeAmerican": false,
                "pacificIslander": false,
                "multiRacial": false,
                "uninsured": false,
                "samsId": "",
                "ssn": "",
                "homePhone": "",
                "employmentId": 0,
                "educationId": 0,
                "incomeId": 0,
                "white": false,
                "black": false,
                "asian": false,
                "middleEastern": false,
                "middleName": "",
                "phoneType": "",
                "phoneAuthorized": false,
                "otherRace": false,
                "assessType": referralForm.values.careReceiver.assessType,
                "tcareProtocol": referralForm.values.careReceiver.tcareProtocol
            }
        }
        API.post(`referrals`, details).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Referral Created Successfully'), type: 'success', active: true }))
                history.push('/pendingRefferals')
                // window.location.reload(false);
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))

            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }

    const common = (value) => {
        if (profileForm.values.careReceiver[0].subscriberId) {
            getSubscriberId(profileForm.values.careReceiver[0].subscriberId)
        }

        if (value === true) {
            setCrRefused1(crRefused[0].selected)
            setoption(true)

            // console.log(profileForm.values.careReceiver[0])
            // console.log("t1"+profileForm.values.careReceiver[0].refused)

            referralForm.values.careReceiver.pcspDate = profileForm.values.careGiver.pcspDate
            referralForm.values.careReceiver.firstName = profileForm.values.careReceiver[0].firstName
            referralForm.values.careReceiver.lastName = profileForm.values.careReceiver[0].lastName
            referralForm.values.careReceiver.phone = profileForm.values.careReceiver[0].phone
            referralForm.values.careReceiver.dobYear = profileForm.values.careReceiver[0].dobYear
            referralForm.values.careReceiver.refused = JSON.parse(crRefused.map(x => x.selected).toString())
            // console.log("t2"+profileForm.values.careReceiver[0].refused)
            // console.log("t2-1"+referralForm.values.careReceiver.refused)

            referralForm.values.careReceiver.relationship = profileForm.values.careReceiver[0].relationship
            referralForm.values.careReceiver.relationshipId = profileForm.values.careReceiver[0].relationshipId
            referralForm.values.careReceiver.subscriberId = profileForm.values.careReceiver[0].subscriberId
            referralForm.values.careReceiver.address.postalCode = profileForm.values.careReceiver[0].address.postalCode
            referralForm.values.careReceiver.address.city = profileForm.values.careReceiver[0].address.city
            referralForm.values.careReceiver.address.state = profileForm.values.careReceiver[0].address.state
            referralForm.values.careReceiver.address.county = profileForm.values.careReceiver[0].address.county
            referralForm.values.careReceiver.assessType = assessType1 === 'true' ? profileForm.values.careReceiver[0].assessType : ''
            referralForm.values.careReceiver.tcareProtocol = protocolType === 'true' ? profileForm.values.careReceiver[0].tcareProtocol : ''

            profileForm.resetForm();
            // profileForm.values.careGiver = ''
            setZipEnable1(true)
        } else if (value === false) {
            setoption(false)
            setMailDisable(false)
            setCrRefusing(0, crRefused1)

            profileForm.values.careGiver.pcspDate = referralForm.values.careReceiver.pcspDate
            profileForm.values.careReceiver[0].firstName = referralForm.values.careReceiver.firstName
            profileForm.values.careReceiver[0].lastName = referralForm.values.careReceiver.lastName
            profileForm.values.careReceiver[0].phone = referralForm.values.careReceiver.phone
            profileForm.values.careReceiver[0].dobYear = referralForm.values.careReceiver.dobYear
            profileForm.values.careReceiver[0].refused = JSON.parse(crRefused.map(x => x.selected).toString())
            profileForm.values.careReceiver[0].relationship = referralForm.values.careReceiver.relationship
            profileForm.values.careReceiver[0].relationshipId = referralForm.values.careReceiver.relationshipId
            profileForm.values.careReceiver[0].subscriberId = referralForm.values.careReceiver.subscriberId
            profileForm.values.careReceiver[0].address.postalCode = referralForm.values.careReceiver.address.postalCode
            profileForm.values.careReceiver[0].address.state = referralForm.values.careReceiver.address.state
            profileForm.values.careReceiver[0].address.city = referralForm.values.careReceiver.address.city
            profileForm.values.careReceiver[0].address.county = referralForm.values.careReceiver.address.county
            profileForm.values.careReceiver[0].assessType = assessType1 === 'true' ? referralForm.values.careReceiver.assessType : ''
            profileForm.values.careReceiver[0].tcareProtocol = protocolType === 'true' ? referralForm.values.careReceiver.tcareProtocol : ''
            //  getAddress1(profileForm.values.careReceiver[0].address.postalCode,0)

            // profileForm.resetForm()
            referralForm.resetForm()
            // profileForm.values.careGiver = ''
        } else {
            setoption(false)
        }
    }
    const nextPage = () => {
        profileForm.submitForm()
        setNextButton(nextButton + 1)
        if (profileForm.values.careGiver.otherAsianRace.otherSubRaceId === OTHER_ASIAN) {
            setOtherAsianString(true)
        }
        if (profileForm.values.careGiver.otherNativeRace.otherSubRaceId === OTHER_NATIVE) {
            setOtherNativeStringForCaregiver(true)
        }
        profileForm.values.careReceiver.forEach((c, i) => {
            if (profileForm.values.careReceiver[i].otherAsianRace.otherSubRaceId === OTHER_ASIAN) {
                setOtherAsianString1(otherAsianString1.map((c, j) => {
                    if (i === j) {
                        c.enable = true
                    }
                    return c
                }))
            }
            if (profileForm.values.careReceiver[i].otherNativeRace.otherSubRaceId === OTHER_NATIVE) {
                setOtherNativeStringForCareReceiver(otherNativeStringForCareReceiver.map((c, j) => {
                    if (i === j) {
                        c.enable = true
                    }
                    return c
                }))
            }
        })
    }

    const previousPage = () => {
        setDisable(false)
        setPage1(true)
        setPage2(false)
        setOtherAsianString(false)
        setOtherNativeStringForCaregiver(false)
        profileForm.values.careReceiver.forEach((c, i) => {
            if (profileForm.values.careReceiver[i].otherAsianRace.otherSubRaceId === OTHER_ASIAN) {
                setOtherAsianString1(otherAsianString1.map((c, j) => {
                    if (i === j) {
                        c.enable = false
                    }
                    return c
                }))
            }
            if (profileForm.values.careReceiver[i].otherNativeRace.otherSubRaceId === OTHER_NATIVE) {
                setOtherNativeStringForCareReceiver(otherNativeStringForCareReceiver.map((c, j) => {
                    if (i === j) {
                        c.enable = false
                    }
                    return c
                }))
            }
        })
    }

    const checkCrInsurances = (value, index, val) => {
        let clickedValue = val.name.split('.')[2]
        if (clickedValue === 'uninsured' && value) {
            profileForm.setValues({
                ...profileForm.values, careReceiver: profileForm.values.careReceiver.map((c, i) => {
                    if (i === index) {
                        c.medicaid = false
                        c.medicare = false
                        c.tricare = false
                        c.otherInsurer = false
                        c[clickedValue] = true
                    }
                    return c
                })
            })
        } else if (clickedValue !== 'uninsured' && value) {
            profileForm.setValues({
                ...profileForm.values, careReceiver: profileForm.values.careReceiver.map((c, i) => {
                    if (i === index) {
                        c.uninsured = false
                        c[clickedValue] = true
                    }
                    return c
                })
            })
        }
    }

    const setCgAsianValue = (value) => {
        if (otherAsianCgValue.includes(value)) {
            let values = otherAsianCgValue.filter((x) => x !== value)
            setOtherAsianCgValue(values)
        } else {
            setOtherAsianCgValue((prevValue) => [...prevValue, value])
        }
    }

    const setCgNativeValue = (value) => {
        if (otherNativeCgValue.includes(value)) {
            let values = otherNativeCgValue.filter((x) => x !== value)
            setOtherNativeCgValue(values)
        } else {
            setOtherNativeCgValue((prevValue) => [...prevValue, value])
        }
    }

    const setCrAsianValue = (value, index) => {
        if (otherAsianCrValue[index].race.includes(value)) {
            let values = otherAsianCrValue.map((c, i) => {
                if (i === index && c.race.includes(value)) {
                    c.race = c.race.filter((x) => x !== value)
                }
                return c
            })
            setOtherAsianCrValue(values)
        } else {
            let values = otherAsianCrValue.map((c, i) => {
                if (i === index) {
                    c.race.push(value)
                }
                return c
            })
            setOtherAsianCrValue(values)
        }
    }




    const setCrNativeValue = (value, index) => {
        if (otherNativeCrValue[index].race.includes(value)) {
            let values = otherNativeCrValue.map((c, i) => {
                if (i === index && c.race.includes(value)) {
                    c.race = c.race.filter((x) => x !== value)
                }
                return c
            })
            setOtherNativeCrValue(values)
        } else {
            let values = otherNativeCrValue.map((c, i) => {
                if (i === index) {
                    c.race.push(value)
                }
                return c
            })
            // ChangeotherNative1(value,index)
            setOtherNativeCrValue(values)
        }
    }

    useEffect(() => {
        const platform = navigator.platform.toLowerCase();
        const macOSPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
        if (macOSPlatforms.some(platformType => platform.includes(platformType))) {
          setOs('mac');
        } else {
          setOs('other');
        }
      }, []);



    return (
        <>
            {userDetails.env === "PRACTICE" ? <div>
                <p className={"text-red-700 dark:text-red-300"} style={{ fontSize: '19px', fontWeight: '400', textAlign: 'left' }}>{t('! This is the Sandbox environment; no production data should be entered here!')}</p>
            </div> : ''}&nbsp;
            {page2 && <div  ref={nextRef} tabIndex={-1}>
                <p style={{ fontSize: '19px', fontWeight: '500', textAlign: 'center' }}>{t('Additional Information')}</p>
                <div style={{ textAlign: 'center' }}>{t("To determine your eligibility for some programs and resources we will need to collect some additional details")}</div>
            </div>}&nbsp;
            {(userDetails.role.includes('referrer') && page1) ? <div className="mt-0 grid grid-cols-4 2xl:grid-cols-4 gap-4 w-full">
                {cgNew ? '' : <ReferrerCheckbox ariaLabel={'No Caregiver Available'} ariachecked={referralForm.values.check} loading={formLoading} label={t("No Caregiver Available")} autoComplete='off' fieldHelper={referralForm.getFieldHelpers('check')} fieldMeta={referralForm.getFieldMeta('check')} fieldProps={referralForm.getFieldProps('check')} dataLoaded={dataLoaded} edit={true} onChange={(ev) => common(ev)} />}{<div></div>}
            </div> : ''}&nbsp;
            <div className="flex flex-col justify-center w-full">
                <div className="flex flex-col lg:flex-row justify-between gap-5 flex-wrap">
                    <div className="flex-1 flex flex-col">
                        <div className="text-center font-bold ...">{t('Caregiver')} {t('Information')}</div>
                        <div className="mt-8 lg:mt-10">
                            {
                                page1 && <>
                                    {/* <div>
                                    <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Basic Information')}</p>
                                </div> */}
                                    <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                        <ReferrerInput ariaLabel={'Caregiver First Name is a required field'} hideData={true} disabled={option} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.firstName')} fieldProps={profileForm.getFieldProps('careGiver.firstName')} edit={editForm} dataLoaded={dataLoaded} />
                                        {/* <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.middleName')} fieldProps={profileForm.getFieldProps('careGiver.middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} /> */}
                                        <ReferrerInput ariaLabel={'Caregiver Last Name is a required field'} hideData={true} disabled={option} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.lastName')} fieldProps={profileForm.getFieldProps('careGiver.lastName')} edit={editForm} dataLoaded={dataLoaded} />
                                        {birthyear1 ?
                                            <FieldInput ariaLabel={'Caregiver Date Of Birth is a required field'} disabled={cgRefused && option ? true : option ? true : cgRefused ? true : false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} />
                                            : <FieldSelect ariaLabel={'Caregiver Birth Year is a required field'} disabled={cgRefused && option ? true : option ? true : cgRefused ? true : false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                        }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                            {/* <ReferrerInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                            {cgRefused && <button disabled={option} style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={() => setRefused('cg', false)}>{t('Refused')}</button>}
                                            {!cgRefused && <button disabled={option} style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tgray" onClick={() => setRefused('cg', true)}>{t('Refused')}</button>}
                                        </div>
                                        <ReferrerInput ariaLabel={'Caregiver Preferred Name'} disabled={option} loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.preferredName')} fieldProps={profileForm.getFieldProps('careGiver.preferredName')} edit={editForm} dataLoaded={dataLoaded} />
                                        {ssn === 'false' ? '' : <ReferrerInput ariaLabel={'Caregiver SSN'} disabled={option} loading={formLoading} label={t("SSN(Last 4 digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.ssn')} fieldProps={profileForm.getFieldProps('careGiver.ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />}
                                        {/* <ReferrerInput loading={formLoading} label={t("Subscriber ID")} autoComplete='off'  fieldMeta={profileForm.getFieldMeta('careGiver.subscriberId')} fieldProps={profileForm.getFieldProps('careGiver.subscriberId')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'9'} onChange={(ev) => getSubscriberId(ev)} err={errWarning} customErrmsg={customsubWarn}/> */}
                                        {programName1 ? <ReferrerSelect ariaLabel={'Caregiver Program Name Dropdown is a required field'} disabled={option} loading={formLoading} label={t('Program Name')} fieldMeta={profileForm.getFieldMeta('careGiver.pgName')} fieldHelper={profileForm.getFieldHelpers('careGiver.pgName')} fieldProps={profileForm.getFieldProps('careGiver.pgName')} edit={editForm} options={programName} position="bottom" dataLoaded={dataLoaded} /> : ''}
                                        {datePscp && option ? <PcspInput ariaLabel={'Caregiver Date of Associated PCSP is a required field'} loading={formLoading} type="date" label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.pcspDate`)} fieldProps={referralForm.getFieldProps(`careReceiver.pcspDate`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.pcspDate`)} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                                        {datePscp && !option ? <ReferrerInput ariaLabel={'Caregiver Date Of Associated PCSP is a required field'} loading={formLoading} type="date" label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.pcspDate')} fieldProps={profileForm.getFieldProps('careGiver.pcspDate')} fieldHelper={profileForm.getFieldHelpers('careGiver.pcspDate')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                                    </div>&nbsp;
                                    <div>
                                        <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Contact Information')}</p>
                                        <div>{t("Select caregiver’s most commonly used method of communication and enter below.")}</div>
                                        &nbsp;
                                    </div>
                                    <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                        <ReferrerInput ariaLabel={'Caregiver Mobile Number is a required field'} disabled={option} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.phone')} fieldProps={profileForm.getFieldProps('careGiver.phone')} edit={editForm} dataLoaded={dataLoaded} maxlength={10} />
                                        <ReferrerInput ariaLabel={'Caregiver Home Phone Number'} disabled={option} hideData={true} loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.homePhone')} fieldProps={profileForm.getFieldProps('careGiver.homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                                        <ReferrerInput ariaLabel={'Caregiver Email Address'} disabled={mailDisable || option} customMailError={cgEmailError} hideData={true} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.emailAddress')} fieldProps={profileForm.getFieldProps('careGiver.emailAddress')} edit={editForm} dataLoaded={dataLoaded} onChange={(val) => getEmailAddress(val)} onPaste={(val) => getEmailAddress(val)} err={errWarning1} customErrmsg={customsubWarn1} />
                                        {contact === 'true' ? '' : <FieldCheckbox disabled={option} loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.phoneAuthorized')} fieldProps={profileForm.getFieldProps('careGiver.phoneAuthorized')} fieldHelper={profileForm.getFieldHelpers('careGiver.phoneAuthorized')} edit={editForm} dataLoaded={dataLoaded} />}
                                    </div>&nbsp;
                                    <div>
                                        <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Address Information')}</p>
                                        <div>{t("The primary address will be used as the default location for caregiver resources")}</div>
                                        &nbsp;
                                    </div>
                                    <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                        <ReferrerInput ariaLabel={'Caregiver Zip Code is a required field'} disabled={option} loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.postalCode')} fieldProps={profileForm.getFieldProps('careGiver.address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev) => getAddress(ev, 'cg')} />
                                        {zipEnable ? <ReferrerInput ariaLabel={'Caregiver Address Line1 is a required field'} disabled={option} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.addressLine1')} fieldProps={profileForm.getFieldProps('careGiver.address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                                        {zipEnable ? <ReferrerInput ariaLabel={'Caregiver Address Line2'} disabled={option} loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.addressLine2')} fieldProps={profileForm.getFieldProps('careGiver.address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                                        {zipEnable ? <ReferrerInput ariaLabel={'Caregiver City'} disabled={option} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.city')} fieldProps={profileForm.getFieldProps('careGiver.address.city')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                                        {zipEnable ? <ReferrerSelect ariaLabel={'Caregiver State Dropdown is a required field'} disabled={option} loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta('careGiver.address.state')} fieldHelper={profileForm.getFieldHelpers('careGiver.address.state')} fieldProps={profileForm.getFieldProps('careGiver.address.state')} edit={editForm} options={states} position="bottom" dataLoaded={dataLoaded} /> : ''}
                                    </div>
                                </>
                            }


                            {
                                page2 &&
                                <div className={'mb-2 p-2'}>
                                    {
                                        (
                                            <>
                                                <div data-private="lipsum" tabIndex={0} role="region" {...(os === 'other' ? {'aria-label': `CareGiver ${profileForm.values.careGiver.firstName} ${profileForm.values.careGiver.lastName} `} : {'aria-label' : `CareGiver ${profileForm.values.careGiver.firstName} ${profileForm.values.careGiver.lastName} `})}>
                                                    <p style={{ fontSize: '19px', fontWeight: '500' }} aria-hidden="true">{profileForm.values.careGiver.firstName} {profileForm.values.careGiver.lastName}</p>
                                                </div>
                                                  <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                                  <FieldSelect ariaLabel={'Caregiver Gender Dropdown'} loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('careGiver.gender')} fieldHelper={profileForm.getFieldHelpers('careGiver.gender')} fieldProps={profileForm.getFieldProps('careGiver.gender')} edit={editForm} options={gender} position="top" dataLoaded={dataLoaded} />
                                                  <FieldSelect ariaLabel={'Caregiver Pronoun Dropdown'} loading={formLoading} label={t("Pronoun")} fieldMeta={profileForm.getFieldMeta('careGiver.pronouns')} fieldHelper={profileForm.getFieldHelpers('careGiver.pronouns')} fieldProps={profileForm.getFieldProps('careGiver.pronouns')} edit={editForm} options={pronouns} position="top" dataLoaded={dataLoaded} />
                                                  { orgRaceEnable ? <FieldMultiSelect ariaLabel={'Caregiver Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('careGiver.race')} fieldHelper={profileForm.getFieldHelpers('careGiver.race')} fieldProps={profileForm.getFieldProps('careGiver.race')} edit={editForm} options={raceEthnicity} position="bottom" asians={subAsian} native={subNative} dataLoaded={dataLoaded} setAsian={(ev) => setCgAsianValue(ev)} setNative={(ev) => setCgNativeValue(ev)} onChange={changeRacevalues(profileForm.getFieldProps('careGiver.race'))} />:
                                                  <UserMultiSelect ariaLabel={'Caregiver Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('careGiver.race')} fieldHelper={profileForm.getFieldHelpers('careGiver.race')} fieldProps={profileForm.getFieldProps('careGiver.race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('careGiver.race'))} />}
                                                  {otherAsianString ? <ReferrerInput ariaLabel={'Other Asian'} hideData={true} disabled={option} loading={formLoading} label={t("Other Asian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.otherAsianRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('careGiver.otherAsianRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} />:''}
                                                  {otherNativeStringForCaregiver ? <ReferrerInput ariaLabel={'Other Native Hawaiian'} hideData={true} disabled={option} loading={formLoading} label={t("Other Native Hawaiian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.otherNativeRace.otherSubRaceValue')} fieldProps={profileForm.getFieldProps('careGiver.otherNativeRace.otherSubRaceValue')} edit={editForm} dataLoaded={dataLoaded} />:''}
                                                  <FieldSelect ariaLabel={'Caregiver Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('careGiver.maritalStat')} fieldHelper={profileForm.getFieldHelpers('careGiver.maritalStat')} fieldProps={profileForm.getFieldProps('careGiver.maritalStat')} edit={editForm} options={maritalStatus} position="top" dataLoaded={dataLoaded} />
                                                  <EduSelect ariaLabel={'Caregiver Education Level Dropdown'} loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta('careGiver.education')} fieldHelper={profileForm.getFieldHelpers('careGiver.education')} fieldProps={profileForm.getFieldProps('careGiver.education')} edit={editForm} options={education} position="top" dataLoaded={dataLoaded} />
                                                  <FieldSelect ariaLabel={'Caregiver Employment Status Dropdown'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('careGiver.employment')} fieldHelper={profileForm.getFieldHelpers('careGiver.employment')} fieldProps={profileForm.getFieldProps('careGiver.employment')} edit={editForm} options={employment} position="top" dataLoaded={dataLoaded} />
                                                  <FieldSelect ariaLabel={'Caregiver Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('careGiver.income')} fieldHelper={profileForm.getFieldHelpers('careGiver.income')} fieldProps={profileForm.getFieldProps('careGiver.income')} edit={editForm} options={Income} position="top" dataLoaded={dataLoaded} />
                                                  <FieldSelect ariaLabel={'Caregiver Preferred Language Dropdown'} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careGiver.language')} fieldHelper={profileForm.getFieldHelpers('careGiver.language')} fieldProps={profileForm.getFieldProps('careGiver.language')} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} />
                                                  <FieldSelect ariaLabel={'Caregiver Disability Dropdown'} loading={formLoading} label={t("Disability")} fieldMeta={profileForm.getFieldMeta('careGiver.disability')} fieldHelper={profileForm.getFieldHelpers('careGiver.disability')} fieldProps={profileForm.getFieldProps('careGiver.disability')} edit={editForm} options={disability} position="top" dataLoaded={dataLoaded} />
                                              </div>&nbsp;
                                              <div>
                                                  <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Insurance')}</p>
                                              </div>
                                              <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                                  <FieldCheckbox loading={formLoading} ariaLabel={'Caregiver Medicaid Insurance'} ariachecked={profileForm.values.careGiver.medicaid} label={t("Medicaid")} fieldMeta={profileForm.getFieldMeta('careGiver.medicaid')} fieldProps={profileForm.getFieldProps('careGiver.medicaid')} fieldHelper={profileForm.getFieldHelpers('careGiver.medicaid')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('careGiver.medicaid'))} />
                                                  <FieldCheckbox loading={formLoading} ariaLabel={'Caregiver Medicare Insurance'} ariachecked={profileForm.values.careGiver.medicare} label={t("Medicare")} fieldMeta={profileForm.getFieldMeta('careGiver.medicare')} fieldProps={profileForm.getFieldProps('careGiver.medicare')} fieldHelper={profileForm.getFieldHelpers('careGiver.medicare')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('careGiver.medicare'))} />
                                                  <FieldCheckbox loading={formLoading} ariaLabel={'Caregiver Tricare Insurance'} ariachecked={profileForm.values.careGiver.tricare} label={t("Tricare")} fieldMeta={profileForm.getFieldMeta('careGiver.tricare')} fieldProps={profileForm.getFieldProps('careGiver.tricare')} fieldHelper={profileForm.getFieldHelpers('careGiver.tricare')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('careGiver.tricare'))} />
                                                  <FieldCheckbox loading={formLoading} ariaLabel={'Caregiver Other Insurer Insurance'} ariachecked={profileForm.values.careGiver.otherInsurer} label={t("Other Insurer")} fieldMeta={profileForm.getFieldMeta('careGiver.otherInsurer')} fieldProps={profileForm.getFieldProps('careGiver.otherInsurer')} fieldHelper={profileForm.getFieldHelpers('careGiver.otherInsurer')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('careGiver.otherInsurer'))} />
                                                  <FieldCheckbox loading={formLoading} ariaLabel={'Caregiver Uninsured Insurance'} ariachecked={profileForm.values.careGiver.uninsured} label={t("Uninsured")} fieldMeta={profileForm.getFieldMeta('careGiver.uninsured')} fieldProps={profileForm.getFieldProps('careGiver.uninsured')} fieldHelper={profileForm.getFieldHelpers('careGiver.uninsured')} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCgInsurances(ev, profileForm.getFieldProps('careGiver.uninsured'))} />
                                              </div>
                                              </>
                                            )
                                        
                                    }
                                </div>



                            }
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col ring-2 ring-blue-500/50 rounded-lg shadow-2xl">
                        <div className="text-center p-2 font-bold ...">{t('Care Receiver')} {t('Information')}</div>
                        <div className="mt-8 lg:mt-1 p-2">
                            {page1 && !option &&
                                profileForm.values.careReceiver.map((c, i) => {
                                    return (
                                        <div key={i}>
                                            {/* <div>
                                            <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Basic Information')}</p>
                                        </div> */}
                                            <div class="mt-5">{(profileForm.values.careReceiver.length === 1) ? '' : i !== 0 && <span className="place-self-stretch font-bold  cursor-pointer" onClick={() => removeCareReceiver(i)}>{t("- Remove Care Receiver")}{`(${i + 1})`}</span>}</div>
                                            <div className="mt-2 grid grid-cols-2 gap-4 w-full">
                                                <FieldInput ariaLabel={'Row[' + `${i+1}` + '] Care Receiver First Name is a required field '} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.firstName`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.firstName`)} edit={editForm} dataLoaded={dataLoaded} />
                                                <FieldInput ariaLabel={'Row[' + `${i+1}` + '] Care Receiver Last Name is a required field '} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.lastName`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.lastName`)} edit={editForm} dataLoaded={dataLoaded} />
                                                {birthyear1 ? <FieldInput ariaLabel={'Row[' + `${i+1}` + '] Care Receiver Date Of Birth is a required field '} loading={formLoading} disabled={crRefused[i].selected ? true : false} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.dateOfBirth`)} edit={editForm} dataLoaded={dataLoaded} /> :
                                                    <FieldSelect ariaLabel={'Row[' + `${i+1}` + '] Care Receiver Birth Year is a required field '} disabled={crRefused[i].selected ? true : false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                                }
                                                <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                                    {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                                    {crRefused[i].selected && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={() => setCrRefusing(i, false)}>{t('Refused')}</button>}
                                                    {!crRefused[i].selected && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tgray" onClick={() => setCrRefusing(i, true)}>{t('Refused')}</button>}
                                                </div>
                                                <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Care Receiver Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.phone`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.phone`)} edit={editForm} dataLoaded={dataLoaded} maxlength={10} />
                                                <FieldSelect ariaLabel={'Row['+ `${i+1}` +'] Care Receiver Relationship dropdown is a required field'} loading={formLoading} label={t("Relationship")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.relationshipId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.relationshipId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.relationshipId`)} edit={editForm} options={relationShip} position="top" onChange={(ev) => otherValue(ev, i)} dataLoaded={dataLoaded} />
                                                {inputEnable[i].enable && <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Care Receiver Other Relationship is a required field'} loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.otherRelationship`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.otherRelationship`)} edit={editForm} dataLoaded={dataLoaded} />}
                                                <FieldInput ariaLabel={subId === 'true' ? 'Row['+ `${i+1}` +'] Care Receiver Subscriber ID is a required field' : 'Row['+ `${i+1}` +'] Care Receiver Subscriber'} disabled={crError[i].subDisable} loading={formLoading} label={t("Subscriber ID")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.subscriberId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.subscriberId`)} edit={editForm} dataLoaded={dataLoaded} ml={true} onBlur={(ev) => getSubscriberId1(ev.target.value, i)} onPaste={(ev) => getSubscriberId1(ev, i)} err={crError[i]?.crErrorWarn} customErrmsg={crError[i]?.crCustomMsg} />
                                                <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Care Receiver Zip code is a required field'} loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.address.postalCode`)} edit={editForm} onChange={(ev) => getAddress1(ev, i)} dataLoaded={dataLoaded} ml={true} length={'5'} />
                                                {notEnable[i].enable && <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Care Receiver City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.address.city`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.address.city`)} edit={editForm} dataLoaded={dataLoaded} />}
                                                {notEnable[i].enable && <FieldSelect ariaLabel={'Row['+ `${i+1}` +'] Care Receiver State Dropdown'} loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.address.state`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.address.state`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.address.state`)} edit={editForm} options={states} position="bottom" dataLoaded={dataLoaded} />}
                                                {notEnable[i].enable && <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Care Receiver County'} loading={formLoading} label={t("County")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.address.county`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.address.county`)} edit={editForm} dataLoaded={dataLoaded} />}
                                                {assessmentTypeId1 ? <FieldSelect ariaLabel={'Row['+ `${i+1}` +'] Care Receiver Assessment Type dropdown is a required field'} loading={formLoading} label={t("Assessment Type")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.assessType`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.assessType`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.assessType`)} edit={editForm} options={assessmentType} position="top" dataLoaded={dataLoaded} /> : ''}
                                                {protocolTypeId1 ? <FieldSelect ariaLabel={'Row['+ `${i+1}` +'] Tcare Protocol Assessment Type dropdown is a required field'} loading={formLoading} label={t("Protocol Type")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.tcareProtocol`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.tcareProtocol`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.tcareProtocol`)} edit={editForm} options={protocol} position="top" dataLoaded={dataLoaded} /> : ''}
                                            </div>
                                        </div>
                                    )
                                })
                            }{page1 && option &&
                                <div className="mt-2 grid grid-cols-2 gap-4 w-full">
                                    <FieldInput ariaLabel={'Care Receiver First Name is a required field'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.firstName`)} fieldProps={referralForm.getFieldProps(`careReceiver.firstName`)} edit={editForm} dataLoaded={dataLoaded} />
                                    <FieldInput ariaLabel={'Care Receiver Last Name is a required field'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.lastName`)} fieldProps={referralForm.getFieldProps(`careReceiver.lastName`)} edit={editForm} dataLoaded={dataLoaded} />
                                    {birthyear1 ? <FieldInput ariaLabel={'Care Receiver Date Of Birth is a required field'} loading={formLoading} disabled={crRefused1 ? true : false} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={referralForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.dateOfBirth`)} edit={editForm} dataLoaded={dataLoaded} /> :
                                        <FieldSelect ariaLabel={'Care Receiver Birth Year is a required field'} disabled={crRefused1 ? true : false} loading={formLoading} label={t('Birth Year')} fieldMeta={referralForm.getFieldMeta(`careReceiver.dateOfBirth`)} fieldProps={referralForm.getFieldProps(`careReceiver.dateOfBirth`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />
                                    }
                                    <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                        {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={referralForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={referralForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={referralForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                        {crRefused1 && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={() => setRefused('cr', false)}>{t('Refused')}</button>}
                                        {!crRefused1 && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tgray" onClick={() => setRefused('cr', true)}>{t('Refused')}</button>}
                                    </div>
                                    <FieldInput ariaLabel={'Care Receiver Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.phone`)} fieldProps={referralForm.getFieldProps(`careReceiver.phone`)} edit={editForm} dataLoaded={dataLoaded} maxlength={10} />
                                <FieldSelect ariaLabel={'Care Receiver Relationship Dropdown is a required field'} loading={formLoading} label={t("Relationship")} fieldMeta={referralForm.getFieldMeta(`careReceiver.relationshipId`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.relationshipId`)} fieldProps={referralForm.getFieldProps(`careReceiver.relationshipId`)} edit={editForm} options={relationShip} position="top" onChange={(ev) => otherValue1(ev)} dataLoaded={dataLoaded} />
                                {inputEnable1 && <FieldInput ariaLabel={'Care Receiver Other Relationship v'} loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.otherRelationship`)} fieldProps={referralForm.getFieldProps(`careReceiver.otherRelationship`)} edit={editForm} dataLoaded={dataLoaded} />}
                                <FieldInput ariaLabel={'Care Receiver Subscriber ID is a required field'} loading={formLoading} label={t("Subscriber ID")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.subscriberId`)} fieldProps={referralForm.getFieldProps(`careReceiver.subscriberId`)} edit={editForm} dataLoaded={dataLoaded} ml={true} onBlur={(ev) => getSubscriberId(ev.target.value)} err={errWarning2} customErrmsg={customsubWarn2}/>
                                <FieldInput ariaLabel={'Care Receiver Zip Code is a required field'} loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.address.postalCode`)} fieldProps={referralForm.getFieldProps(`careReceiver.address.postalCode`)} edit={editForm} onChange={(ev) => getAddress2(ev)} dataLoaded={dataLoaded} ml={true} length={'5'} />
                                {zipEnable1 && <FieldInput ariaLabel={'Care Receiver City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.address.city`)} fieldProps={referralForm.getFieldProps(`careReceiver.address.city`)} edit={editForm} dataLoaded={dataLoaded} />}
                                {zipEnable1 && <FieldSelect ariaLabel={'Care Receiver State Dropdown'} loading={formLoading} label={t('State')} fieldMeta={referralForm.getFieldMeta(`careReceiver.address.state`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.address.state`)} fieldProps={referralForm.getFieldProps(`careReceiver.address.state`)} edit={editForm} options={states} position="bottom" dataLoaded={dataLoaded} />}
                                {zipEnable1 && <FieldInput ariaLabel={'Care Receiver County'} loading={formLoading} label={t("County")} autoComplete='off' fieldMeta={referralForm.getFieldMeta(`careReceiver.address.county`)} fieldProps={referralForm.getFieldProps(`careReceiver.address.county`)} edit={editForm} dataLoaded={dataLoaded} />}
                                {assessmentTypeId1 ? <FieldSelect ariaLabel={'Care Receiver Assessment Type Dropdown is a required field'} loading={formLoading} label={t("Assessment Type")} fieldMeta={referralForm.getFieldMeta(`careReceiver.assessType`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.assessType`)} fieldProps={referralForm.getFieldProps(`careReceiver.assessType`)} edit={editForm} options={assessmentType} position="top" dataLoaded={dataLoaded} /> : ''}
                                {protocolTypeId1 ? <FieldSelect ariaLabel={'Tcare Protocol Assessment Type Dropdown is a required field'} loading={formLoading} label={t("Protocol Type")} fieldMeta={referralForm.getFieldMeta(`careReceiver.tcareProtocol`)} fieldHelper={referralForm.getFieldHelpers(`careReceiver.tcareProtocol`)} fieldProps={referralForm.getFieldProps(`careReceiver.tcareProtocol`)} edit={editForm} options={protocol} position="top" dataLoaded={dataLoaded} /> : ''}
                            </div>
                            }
                        </div>
                        {
                            page2 &&
                            <div className={'mb-2 p-2'}>
                                {/* <div>
                                <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Additional Information')}</p>
                                <div>To determine your eligibility for some programs and resources we will need to collect some additional demographic details</div>
                                &nbsp;
                            </div> */}
                                {
                                    profileForm.values.careReceiver.map((c, i) => {
                                        return (
                                            <div key={i} className={'mb-2'}>
                                                <div data-private="lipsum" tabIndex={0} role="region" {...(os === 'other' ? {'aria-label': `CareReceiver ${c.firstName} ${c.lastName} `} : {'aria-label' : `CareReceiver ${c.firstName} ${c.lastName} `})}>
                                                    <p style={{ fontSize: '19px', fontWeight: '500' }} aria-hidden="true">{c.firstName} {c.lastName}</p>
                                                </div>
                                                <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4 w-full">
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Gender Dropdown'} loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.gender`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.gender`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.gender`)} edit={editForm} options={gender} position="top" dataLoaded={dataLoaded} />
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Pronoun Dropdown'} loading={formLoading} label={t("Pronoun")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.pronouns`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.pronouns`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.pronouns`)} edit={editForm} options={pronouns} position="top" dataLoaded={dataLoaded} />
                                                   {orgRaceEnable ? <FieldMultiSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.race`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.race`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.race`)} edit={editForm} options={raceEthnicity} asians={subAsian} native={subNative} position="bottom" dataLoaded={dataLoaded} setAsian={(ev) => setCrAsianValue(ev, i)} setNative={(ev) => setCrNativeValue(ev, i)} onChange={(ev) => changeCrRaceValues(ev, i)} />:
                                                    <UserMultiSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Race/Ethnicity Multiselect Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.race`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.race`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.race`)} edit={editForm} options={raceEthnicity}  position="bottom" dataLoaded={dataLoaded} onChange={(ev) => changeCrRaceValues(ev, i)} />}
                                                    {otherAsianString1[i].enable &&  <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Other Asian '} hideData={true} loading={formLoading} label={t("Other Asian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.otherAsianRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.otherAsianRace.otherSubRaceValue`)} edit={editForm} dataLoaded={dataLoaded} />}
                                                    {otherNativeStringForCareReceiver[i].enable &&  <FieldInput ariaLabel={'Row['+ `${i+1}` +'] Other Asian '} hideData={true} loading={formLoading} label={t("Other Native Hawaiian")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.otherNativeRace.otherSubRaceValue`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.otherNativeRace.otherSubRaceValue`)} edit={editForm} dataLoaded={dataLoaded} />}
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.maritalStat`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.maritalStat`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.maritalStat`)} edit={editForm} options={maritalStatus} position="top" dataLoaded={dataLoaded} />
                                                    <EduSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Education Level Dropdown'} loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.educationId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.educationId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.educationId`)} edit={editForm} options={education} position="top" dataLoaded={dataLoaded} />
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Employment Status Dropdown'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.employmentId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.employmentId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.employmentId`)} edit={editForm} options={employment} position="top" dataLoaded={dataLoaded} />
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.incomeId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.incomeId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.incomeId`)} edit={editForm} options={Income} position="top" dataLoaded={dataLoaded} />
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Preferred Language Dropdown'} loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.language`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.language`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.language`)} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} />
                                                    <FieldSelect ariaLabel={'Row['+`${i+1}`+'] Care Receiver Disability Dropdown'} loading={formLoading} label={t("Disability")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.disability`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.disability`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.disability`)} edit={editForm} options={disability} position="top" dataLoaded={dataLoaded} />
                                                </div>&nbsp;
                                                <div>
                                                    <p style={{ fontSize: '19px', fontWeight: '500' }}>{t('Insurance')}</p>
                                                </div>
                                                <div className="mt-2 grid grid-cols-2 2xl:grid-cols-2 gap-4">
                                                    <FieldCheckbox loading={formLoading} ariaLabel={'Row['+`${i+1}`+'] Care Receiver Medicare Insurance'} ariachecked={profileForm.values.careReceiver[i].medicare} label={t("Medicare")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.medicare`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.medicare`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.medicare`)} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCrInsurances(ev, i, profileForm.getFieldProps(`careReceiver.${i}.medicare`))} />
                                                    <FieldCheckbox loading={formLoading} ariaLabel={'Row['+`${i+1}`+'] Care Receiver Medicaid Insurance'} ariachecked={profileForm.values.careReceiver[i].medicaid} label={t("Medicaid")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.medicaid`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.medicaid`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.medicaid`)} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCrInsurances(ev, i, profileForm.getFieldProps(`careReceiver.${i}.medicaid`))} />
                                                    <FieldCheckbox loading={formLoading} ariaLabel={'Row['+`${i+1}`+'] Care Receiver Tricare Insurance'} ariachecked={profileForm.values.careReceiver[i].tricare} label={t("Tricare")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.tricare`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.tricare`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.tricare`)} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCrInsurances(ev, i, profileForm.getFieldProps(`careReceiver.${i}.tricare`))} />
                                                    <FieldCheckbox loading={formLoading} ariaLabel={'Row['+`${i+1}`+'] Care Receiver Other Insurer Insurance'} ariachecked={profileForm.values.careReceiver[i].otherInsurer} label={t("Other Insurer")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.otherInsurer`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.otherInsurer`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.otherInsurer`)} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCrInsurances(ev, i, profileForm.getFieldProps(`careReceiver.${i}.otherInsurer`))} />
                                                    <FieldCheckbox loading={formLoading} ariaLabel={'Row['+`${i+1}`+'] Care Receiver Uninsured Insurance'} ariachecked={profileForm.values.careReceiver[i].uninsured} label={t("Uninsured")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.uninsured`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.uninsured`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.uninsured`)} edit={editForm} dataLoaded={dataLoaded} onChange={(ev) => checkCrInsurances(ev, i, profileForm.getFieldProps(`careReceiver.${i}.uninsured`))} />
                                                </div>&nbsp;
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {page1 && !option && <button className="place-self-stretch font-bold cursor-pointer text-left" onClick={() => addNewCareReceiver()}>{t('+ Add Another Care Receiver')}</button>}
                    </div>
                </div>
                <div className="text-center">
                    {editForm &&
                        <div className="flex justify-center mt-7 mx-5">
                            {page1 && <button className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={() => history.push('/')}>{t("Cancel")}</button>}
                            {page2 && <button className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={() => previousPage()}>{t("Previous")}</button>}
                            {page1 && !option && <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => nextPage()}>{t("Next")}</button>}
                            {page1 && option && <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => referralForm.submitForm()}>{t("Save")}</button>}
                            {page2 && <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>}
                        </div>
                    }
                </div>
                <div>
                    {/* <FormCard title={t("Basic Information")} injectClass="mt-12">
                <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => setEditForm(false)}/>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.firstName')} fieldProps={profileForm.getFieldProps('careGiver.firstName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.middleName')} fieldProps={profileForm.getFieldProps('careGiver.middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} />
                    <FieldInput loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.lastName')} fieldProps={profileForm.getFieldProps('careGiver.lastName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.preferredName')} fieldProps={profileForm.getFieldProps('careGiver.preferredName')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("SSN(Last 4 digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.ssn')} fieldProps={profileForm.getFieldProps('careGiver.ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                    <FieldInput loading={formLoading} label={t("Subscriber Id")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.subscriberId')} fieldProps={profileForm.getFieldProps('careGiver.subscriberId')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard> */}
                    {/* <FormCard title={t("Contact Information")} injectClass="mt-12">
                <div>Select caregiver’s most commonly used method of communication and enter below.</div>
                &nbsp;
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.phone')} fieldProps={profileForm.getFieldProps('careGiver.phone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.homePhone')} fieldProps={profileForm.getFieldProps('careGiver.homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.emailAddress')} fieldProps={profileForm.getFieldProps('careGiver.emailAddress')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.phoneAuthorized')} fieldProps={profileForm.getFieldProps('careGiver.phoneAuthorized')} fieldHelper={profileForm.getFieldHelpers('careGiver.phoneAuthorized')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard> */}
                    {/* <FormCard title={t("Additional Information")} injectClass="mt-12">
                <div>This helps identify the best resources for the caregiver — not for eligibility requirements</div>
                &nbsp;
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldSelect loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('careGiver.gender')} fieldHelper={profileForm.getFieldHelpers('careGiver.gender')} fieldProps={profileForm.getFieldProps('careGiver.gender')} edit={editForm} options={gender} position="top" dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Pronoun")} fieldMeta={profileForm.getFieldMeta('careGiver.pronouns')} fieldHelper={profileForm.getFieldHelpers('careGiver.pronouns')} fieldProps={profileForm.getFieldProps('careGiver.pronouns')} edit={editForm} options={pronouns} position="top" dataLoaded={dataLoaded} />
                    <FieldMultiSelect loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('careGiver.race')} fieldHelper={profileForm.getFieldHelpers('careGiver.race')} fieldProps={profileForm.getFieldProps('careGiver.race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('race'))} />
                    <FieldSelect loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('careGiver.maritalStat')} fieldHelper={profileForm.getFieldHelpers('careGiver.maritalStat')} fieldProps={profileForm.getFieldProps('careGiver.maritalStat')} edit={editForm} options={maritalStatus} position="top" dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta('careGiver.education')} fieldHelper={profileForm.getFieldHelpers('careGiver.education')} fieldProps={profileForm.getFieldProps('careGiver.education')} edit={editForm} options={education} position="top" dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('careGiver.employment')} fieldHelper={profileForm.getFieldHelpers('careGiver.employment')} fieldProps={profileForm.getFieldProps('careGiver.employment')} edit={editForm} options={employment} position="top" dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('careGiver.income')} fieldHelper={profileForm.getFieldHelpers('careGiver.income')} fieldProps={profileForm.getFieldProps('careGiver.income')} edit={editForm} options={Income} position="top" dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('careGiver.language')} fieldHelper={profileForm.getFieldHelpers('careGiver.language')} fieldProps={profileForm.getFieldProps('careGiver.language')} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} />
                </div>
            </FormCard> */}

                    {/* <FormCard title={t("Primary Address")} injectClass="mt-12">
            <div>The primary address will be used as the default location for caregiver resources</div>
                &nbsp;
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldInput loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.addressLine1')} fieldProps={profileForm.getFieldProps('careGiver.address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.addressLine2')} fieldProps={profileForm.getFieldProps('careGiver.address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.city')} fieldProps={profileForm.getFieldProps('careGiver.address.city')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldSelect loading={formLoading} label={t('State')} fieldMeta={profileForm.getFieldMeta('careGiver.address.state')} fieldHelper={profileForm.getFieldHelpers('careGiver.address.state')} fieldProps={profileForm.getFieldProps('careGiver.address.state')} edit={editForm} options={states} position="bottom" dataLoaded={dataLoaded} />
                    <FieldInput loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.address.postalCode')} fieldProps={profileForm.getFieldProps('careGiver.address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} />
                </div>
            </FormCard>
            <FormCard title={t("Insurance")} injectClass="mt-12">
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    <FieldCheckbox loading={formLoading} label={t("Medicaid")} fieldMeta={profileForm.getFieldMeta('medicaid')} fieldProps={profileForm.getFieldProps('medicaid')} fieldHelper={profileForm.getFieldHelpers('medicaid')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Medicare")} fieldMeta={profileForm.getFieldMeta('medicare')} fieldProps={profileForm.getFieldProps('medicare')} fieldHelper={profileForm.getFieldHelpers('medicare')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Tricare")} fieldMeta={profileForm.getFieldMeta('tricare')} fieldProps={profileForm.getFieldProps('tricare')} fieldHelper={profileForm.getFieldHelpers('tricare')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Other Insurer")} fieldMeta={profileForm.getFieldMeta('otherInsurer')} fieldProps={profileForm.getFieldProps('otherInsurer')} fieldHelper={profileForm.getFieldHelpers('otherInsurer')} edit={editForm} dataLoaded={dataLoaded} />
                    <FieldCheckbox loading={formLoading} label={t("Uninsured")} fieldMeta={profileForm.getFieldMeta('uninsured')} fieldProps={profileForm.getFieldProps('uninsured')} fieldHelper={profileForm.getFieldHelpers('uninsured')} edit={editForm} dataLoaded={dataLoaded} />
                </div>
            </FormCard> */}

                    {/* <FormCard title={t("Create a Case")}>
                    <div>There are no associated cases for this caregiver. Please select a care relationship below to create a case.</div>
                    <div>is caring for...</div>
                    &nbsp;
                    {
                        profileForm.values.careReceiver.map((c, i) => {
                            return (
                                <div className={'mt-2'}>
                                    <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                                        <FieldSelect loading={formLoading} label={t("Relationship")} fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.relationshipId`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.relationshipId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.relationshipId`)} edit={editForm} options={relationShip} position="top" onChange={(ev) => otherValue(ev, i)} dataLoaded={dataLoaded} />
                                        {inputEnable[i].enable && <FieldInput loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.otherRelationship`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.otherRelationship`)} edit={editForm} dataLoaded={dataLoaded} />}
                                        <FieldInput loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.firstName`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.firstName`)} edit={editForm} dataLoaded={dataLoaded} />
                                        <FieldInput loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.lastName`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.lastName`)} edit={editForm} dataLoaded={dataLoaded} />
                                        <FieldInput loading={formLoading} label={t("Subscriber Id")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.subscriberId`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.subscriberId`)} edit={editForm} dataLoaded={dataLoaded} />
                                        <FieldInput loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.dateOfBirth`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.dateOfBirth`)} fieldHelper={profileForm.getFieldHelpers(`careReceiver.${i}.dateOfBirth`)} edit={editForm} dataLoaded={dataLoaded} />
                                        <FieldInput loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta(`careReceiver.${i}.address.postalCode`)} fieldProps={profileForm.getFieldProps(`careReceiver.${i}.address.postalCode`)} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} />
                                        <RemoveButton class="place-self-stretch" onClick={() => removeCareReceiver(i)} />
                                    </div>
                                </div>
                            )
                        })
                    }
                    &nbsp;
                    {careReceiverList < 2 ? <AddButton onClick={() => addNewCareReceiver()} /> : ''}
                </FormCard>
                {editForm &&
                    <div className="flex justify-center mt-7 mx-5">
                        <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-500 hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                        <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
                    </div>
                } */}
                </div>
            </div>
            <Dialog title={t('Subscriber ID is already exist')} showDialog={subDialog} onClose={() => setSubDialog(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Do you want to add a New caregiver?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setSubDialog(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => history.push({ pathname: `/casedata/${caseId}`, state: { tabIndex: 3, fromNewCase: true } })}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t('Subscriber ID is already exist')} showDialog={subDialog1} onClose={() => setSubDialog1(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Please Complete Referral Case")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setSubDialog1(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => history.push({ pathname: `/createCase/${referral.caseId}/carereceiver/${referral.crId}/caregiver/${referral.cgId}/${referral.cmId}` })}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
            <Dialog title={t('Email is already in use')} showDialog={mailDialog} onClose={() => setMailDialog(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Do you want add a new Care Receiver?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setMailDialog(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => history.push({ pathname: `/casedata/${caseId}`, state: { tabIndex: 3, fromNewCase: true } })}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
        </>

    )
}

export default NewCase