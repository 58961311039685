import FlowText from "../../components/snippets/FlowText"
import { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { motion } from "framer-motion"
import API from '../../axios/API'
import { useEffect } from "react"
import { FiDownload } from "react-icons/fi"
import { FaArrowLeft, FaArrowRight, FaCheck, FaChevronUp } from "react-icons/fa"
import AssessmentTextField from "../../components/snippets/Assessment/AssessmentTextField"
import AssessmentOptionField from "../../components/snippets/Assessment/AssessmentOptionField"
import AssessmentPages from "../../components/snippets/Assessment/AssessmentPages"
import AssessmentLoader from "./AssessmentLoader"
import { setAlert } from "../../store/theme/actions"
import { useHistory } from "react-router"
import { useFormik } from "formik"
import * as Yup from 'yup'
import AssessmentOptionChip from '../../components/snippets/Assessment/AssessmentOptionChip'
import { createPortal } from "react-dom"
import Dialog from "../../components/snippets/Dialog"
import { useTranslation } from "react-i18next"
import FieldSelect from "../../components/snippets/FieldSelect"
import 'moment-timezone';
import moment from 'moment';
import CareplanDialog from "../Dialogs/CareplanDialog";
import { FaChevronDown } from "react-icons/fa";
import FieldInput from "../../components/snippets/FieldInput"
const initialFormValues = {
    disease: []
}

const initialYearFormValues = {
    year: '',
    startFostering:'',
}

const initialTextFormValues ={
    permanency:'',
    firstFostering:'',
    currentPlan:''
}


const AssessmentFirstPage = ({ asmtId, screenerId, caseId, editorActive }) => {

    const quesWrap = useRef(null)
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    const [pageData, setPageData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16])
    const [page, setPage] = useState(3)
    const [caseFlag, setCaseFlag] = useState(1)
    const [previous, setPrevious] = useState(false)
    // const themeMode = useSelector(state => state.theme)
    const [textEnable, setTextEnable] = useState(false)
    const [textEnable1, setTextEnable1] = useState(false)
    const [unansweredCount, setUnansweredCount] = useState()
    const [answeredCount, setAnsweredCount] = useState()
    const [answers, setAnswers] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    const organization = useSelector(state => state.auth.organization)
    const [pageList, setPageList] = useState([{ id: 1, completed: false }, { id: 2, completed: false }, { id: 3, completed: false }, { id: 4, completed: false }, { id: 5, completed: false }, { id: 6, completed: false }, { id: 7, completed: false }, { id: 8, completed: false }, { id: 9, completed: false }, { id: 10, completed: false }, { id: 11, completed: false }, { id: 12, completed: false }, { id: 13, completed: false }, { id: 14, completed: false }, { id: 15, completed: false }, { id: 16, completed: false }])
    const [multiSelect, setMultiSelect] = useState(false)
    const [otherDisease, setOtherDisease] = useState([])
    const [diseaseString, setDiseaseString] = useState('')
    const [diseases, setDiseases] = useState([])
    // const roles = useSelector(state => state.auth.userDetails?.role)
    const [downloadData] = useState('')
    const [downloadPopup, setDownloadPopup] = useState(false)
    const [followupPopup, setFollowupPopup] = useState(false)
    // const userDetails = useSelector(state => state.auth.userDetails)
    const [otherDiseaseDetails, setOtherDiseaseDetails] = useState('')
    const { t } = useTranslation()
    // const onlyNumber = useSelector(state => state.auth.onlyNumber)
    const [onlynumber1, setOnlyNumber1] = useState(false)
    const [onlynumber2, setOnlyNumber2] = useState(false)
    const [noSpace1, setNoSpace1] = useState(false)
    const [noSpace2, setNoSpace2] = useState(false)

    const [disable, setDisable] = useState(false)
    const [years, setYears] = useState([])
    const [inputValue, setInput] = useState()
    const [input1Value, setInput1] = useState()
    const [triggerFirst, setTriggerFirst] = useState(false)
    const [downloadBlankAssessPopup, setDownloadBlankAssessPopup] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    let orgId = localStorage.getItem('orgid')
    let blankPdfEnable = localStorage.getItem('blankPdfEnable')
    let nextActionPopup = localStorage.getItem('nextActionPopup');
    const [followupDays, setfollowupDays] = useState()
    const [grgSubmit, setGrgSubmit] = useState(false)
    const [currentText,setCurrentText] = useState(false)

    useEffect(() => {
        for (let i = new Date().getFullYear(); i >= 1900; i--) {
            years.push(i)
        }
    }, []) // eslint-disable-line

    const diseaseFormSchema = Yup.object().shape({
        disease: Yup.array(),
    })

    const diseaseForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: diseaseFormSchema,
    })

    const yearsFormSchema = Yup.object().shape({
        year: Yup.string(),
        startFostering: Yup.string(),
    })
    const textFormSchema = Yup.object().shape({
        permanency: Yup.string(),
        firstFostering:Yup.string(),
        currentPlan:Yup.string()
    })
    const yearsForm = useFormik({
        initialValues: initialYearFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: yearsFormSchema
    })
    const textForm = useFormik({
        initialValues: initialTextFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: textFormSchema
    })

    useEffect(() => {
        setYears(years.map(c => ({ text: c, value: c })))
        API.post(`options/diseases`).then(res => {
            if (res.status === 200) {
                setDiseases(res.data.body.map(c => ({ text: c.diseaseName, value: c.diseaseId })))
            }
        })
    }, [])// eslint-disable-line  


    useEffect(() => {
        setLoading(true)
        diseaseForm.setFieldValue('disease', [])
        API.get(`organizations/${orgId}/assessments/${asmtId}/pages/${page}/questions?screener=${screenerId}&currentPage=${caseFlag}`).then(res => {
            setAnswers([])
            setAnsweredCount(0)
            setUnansweredCount(0)
            setMultiSelect(false)
            setTextEnable1(false)
            setTextEnable(false)
            // if (res.data.body.question.length > 0) {
            //     setPage(res.data.body.pageNumber - 2)
            // }
            setGrgSubmit(res.data.body.grgSubmit)
            if ((res.data.body.question.length === 0) && !previous) {
                pageList.map((c, i) => {
                    if (page - 2 === c.id) {
                        c.completed = true
                    } return null;
                })
                if (caseFlag === 1) {
                    setCaseFlag(0)
                }
                setPage(res.data.body.nextPage)
            } else if ((res.data.body.question.length === 0) && previous) {
                pageList.map((c, i) => {
                    if (page === c.id) {
                        c.completed = false
                    } return null;
                })
                if (caseFlag === 1) {
                    setCaseFlag(0)
                }
                setPage(res.data.body.previousPage)
            } else {
                // const question = res.data.body?.question?.map(c => {
                //     return { ...c, expansionPanel: false };
                // });
                setQuestions(res.data.body);  
                let answer = 0;
                let unanswer = 0;
                let firstOtherValue = ''
                res.data.body.question.map((c, i) => {
                    c.expansionPanel = false
                    if (c.questionId === 148 || c.questionId === 202 || c.questionId === 257) {
                        c.answers.forEach((element, i) => {
                            if ((element.answerId === 604 || element.answerId === 1470 || element.answerId === 1729) && element.selected) {
                                firstOtherValue = element.otherText
                                if (firstOtherValue) {
                                    setOtherDiseaseDetails(firstOtherValue)
                                }
                                if (element.otherText !== null) {
                                    let otherText = element.otherText.split(',')
                                    otherText.map(e => {
                                        c.answers.push({ answerId: e, answerLabel: e, questionId: c.questionId, selected: true })
                                        return null;
                                    })
                                }
                                setMultiSelect(true)
                            }
                        })
                    }
                    if (c.questionId === 175 || c.questionId === 356) {
                        setfollowupDays(c.followupDays)
                        c.answers.map(e => {
                            if (e.answerId === 879 || e.answerId === 2096) {
                                if (e.otherText) {
                                    setTextEnable(true)
                                    setInput(e.otherText);
                                    setDisable(false)
                                } else {
                                    setInput("")
                                    setDisable(false)

                                }
                                // c.textBox = e.otherText
                                // c.answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId, "answerValue": e.otherText }] })
                            } return null;
                        })
                    }
                    if (c.questionId === 178 || c.questionId === 325) {
                        c.answers.map(f => {
                            if (f.answerId === 918 || f.answerId === 2008) {
                                if (f.otherText) {
                                    setTextEnable1(true)
                                    setInput1(f.otherText)
                                    setDisable(false)
                                } else {
                                    setInput1("")
                                    setDisable(false)
                                }
                                // c.textBox = f.otherText
                                // c.answers.push({ "questionId": c.questionId, "answers": [{ "answerId": f.answerId, "answerValue": f.otherText }] })
                            } return null;
                        })
                    }
                    if (c.questionId === 336 || c.questionId === 364 || c.questionId === 362) {
                        c.answers.map(f => {
                            if (f.answerId === 2037 || f.answerId === 2117 || f.answerId === 2120) {
                                if (f.otherText && f.selected) {
                                   setCurrentText(true)
                                   textForm.setValues({ ...textForm.values, currentPlan: f.otherText })
                                    setDisable(false)
                                } else {
                                    setCurrentText(false)
                                    textForm.setValues({ ...textForm.values, currentPlan: '' })
                                    setDisable(false)
                                }
                                // c.textBox = f.otherText
                                // c.answers.push({ "questionId": c.questionId, "answers": [{ "answerId": f.answerId, "answerValue": f.otherText }] })
                            } return null;
                        })
                    }
                    if (c.questionType !== "header" && c.questionType !== "multi" && c.questionType !== "text") {
                        c.answers.forEach((element, i) => {
                            if (c.questionId === element.questionId && element.selected) {
                                initializeAnswers(c.questionId, element.answerId, c.questionType,'',c.conditionalQuestions,element.otherText)
                                c.answered = true
                                answer = answer + 1
                                setAnsweredCount(answer)
                            }
                        });
                        unanswer = unanswer + 1
                        setUnansweredCount(unanswer)
                    } else if (c.questionType === "multi") {
                        let stopCount = 0;
                        c.answers.forEach((element, i) => {
                            if(c.questionId === element.questionId && element.selected && stopCount === 0 && element.answerId === 2120){
                                answers.push({ "questionId": element.questionId, "answers": [{ "answerId": element.answerId,"otherText":element.otherText }] })
                                c.answered = true
                                answer = answer + 1
                                stopCount = stopCount + 1
                                setAnsweredCount(answer)
                            }else if (c.questionId === element.questionId && element.selected && stopCount === 0 && element.answerId !== 2120 && (element.answerId !== 604 || element.answerId !== 1470 || element.answerId !== 1729)) {
                                answers.push({ "questionId": element.questionId, "answers": [{ "answerId": element.answerId }] })
                                setAnswers(answers)
                                c.answered = true
                                answer = answer + 1
                                stopCount = stopCount + 1
                                setAnsweredCount(answer)
                            } else if (c.questionId === element.questionId && element.selected && stopCount > 0) {
                                initializeAnswers(c.questionId, element.answerId, c.questionType, firstOtherValue,c.conditionalQuestions,element.otherText)
                            } else if (c.questionId === element.questionId && element.selected && stopCount === 0 && (element.answerId === 604 || element.answerId === 1470 || element.answerId === 1729)) {
                                answers.push({ "questionId": element.questionId, "answers": [{ "answerId": element.answerId, answerValue: firstOtherValue }] })
                                setAnswers(answers)
                                c.answered = true
                                answer = answer + 1
                                stopCount = stopCount + 1
                                setAnsweredCount(answer)
                            }
                           
                        });
                        unanswer = unanswer + 1
                        setUnansweredCount(unanswer)
                    } else if (c.questionType === 'text' && c.textBox) {
                        c.answered = true
                        initializeAnswers(c.questionId, c.textBox, c.questionType,'',c.conditionalQuestions)
                        answer = answer + 1;
                        setAnsweredCount(answer)
                        unanswer = unanswer + 1
                        setUnansweredCount(unanswer)
                    } else if (c.questionType === "text" && c.textBox === null) {
                        unanswer = unanswer + 1
                        setUnansweredCount(unanswer)
                    } return null;
                })

            }

            setLoading(false)
        }).catch(e => {
            console.log(e)
        })
    }, [page, triggerFirst]) // eslint-disable-line


    const initializeAnswers =async (questionId, answerId, questionType, otherValue,conditionalQuestions,textBox) => {
        console.log(questionId, answerId, questionType, otherValue,conditionalQuestions,textBox)
        if (questionType === 'text') {
            if (questionId === 258) {
                yearsForm.setValues({ ...yearsForm.values, startFostering: parseInt(answerId) })
                answers.push({ "questionId": questionId, "answers": [{ "answerValue": parseInt(answerId) }] })
            }
            // if (questionId === 259) {
            //     textForm.setValues({ ...textForm.values, firstFostering: parseInt(answerId) })
            //     answers.push({ "questionId": questionId, "answers": [{ "answerValue": parseInt(answerId) }] })
            // }
            if (questionId === 20) {
                yearsForm.setValues({ ...yearsForm.values, year: parseInt(answerId) })
                answers.push({ "questionId": questionId, "answers": [{ "answerValue": parseInt(answerId) }] })
            }else if(questionId === 332){
                textForm.setValues({ ...textForm.values, permanency: answerId })
                answers.push({ "questionId": questionId, "answers": [{ "answerValue": answerId }] })
            } else {
                answers.push({ "questionId": questionId, "answers": [{ "answerValue": answerId }] })
            }
            setAnswers(answers)
        } else if (questionType === "multi") {
            answers.map(c => {
                if (c.questionId === questionId) {
                    if (typeof (answerId) === 'string') {
                    } else {
                        if(answerId === 2120){
                            c.answers.push({ "answerId": answerId, "otherText": textBox })
                            setAnswers(answers)
                        }else if (answerId === 604 || answerId === 1470 || answerId === 1729) {
                            c.answers.push({ "answerId": answerId, answerValue: otherValue })
                            setAnswers(answers)
                        }else{
                            c.answers.push({ "answerId": answerId })
                            setAnswers(answers)
                        }
                    }
                } return null;
            })
            // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[answerValue-1] }] })
        } else if(questionType === "radio" && questionId === 364 && answerId === 2117 && textBox !== undefined && textBox){
            answers.push({ "questionId": questionId, "answers": [{ "answerId": answerId,"otherText":textBox }] })
        }else {
            answers.push({ "questionId": questionId, "answers": [{ "answerId": answerId }] })
            if(conditionalQuestions.length !== 0){
                let totalCount = conditionalQuestions.length;
                let loopCount = 0
                await conditionalQuestions.map((d)=>{
                    if(answers.filter((x)=>x.questionId === questionId)[0].conditionalQuestion === undefined){
                        answers.filter((x)=>x.questionId === questionId)[0].conditionalQuestion = [{questionId : d.questionId,answers:[{answerId:d.answers.filter((x)=>x.selected)[0]?.answerId}]}]
                    }else{
                        answers.filter((x)=>x.questionId === questionId)[0].conditionalQuestion.push({questionId : d.questionId,answers:[{answerId:d.answers.filter((x)=>x.selected)[0]?.answerId}]});
                    }
                    loopCount = loopCount + 1;
                })
                if(loopCount === totalCount){
                    setAnswers(answers)
                }
            }else{
                setAnswers(answers)
            }
        }
    }

    const getAnsweredCount = () => {
        let count = 0
        questions.question.map((c, i) => {
            if ((c.questionType !== "headers") && (c.answered === true)) {
                count = count + 1
                setAnsweredCount(count)
            } return null;
        })
    }

    const setField = (objD, value) => {
        // let id = objD.questionId;
        let method = value.split(',')[0];
        if (method === 'add') {
            // otherDisease.push(value.split(',')[0])
            otherDisease.push(value.replaceAll('add,', ''))
            setOtherDisease(otherDisease)
            let output = otherDisease.join(',')
            answers.map(c => {
                if (c.questionId === 148 || c.questionId === 202 || c.questionId === 257) {
                    c.answers.map(e => {
                        if (e.answerId === 604 || e.answerId === 1470 || e.answerId === 1729) {
                            if (otherDiseaseDetails) {
                                e.answerValue = otherDiseaseDetails + ',' + output;
                                setDiseaseString(e.answerValue)
                            } else {
                                e.answerValue = output;
                                setDiseaseString(e.answerValue)
                            }
                        } return null;
                    })
                } return null;
            })
        } else {
            otherDisease.map((c, i) => {
                if (value.split(',')[0] === c) {
                    otherDisease.splice(i, 1)
                } return null
            })
            let output = otherDisease.join(',')
            answers.map(c => {
                if (c.questionId === 148 || c.questionId === 202 || c.questionId === 257) {
                    c.answers.map(e => {
                        if (e.answerId === 604 || e.answerId === 1470 || e.answerId === 1729) {
                            e.answerValue = output;
                            setDiseaseString(e.answerValue)
                        } return null;
                    })
                } return null;
            })
        }
    }

    const setTextFieldValue = (id, val) => {
        const a = val.split('');
        if (a.length != 0 && a[0] === ' ') {
            if (id === 175 || id === 356) {
                setNoSpace1(true)
            } else if (id === 178 || id === 325) {
                setNoSpace2(true)
            }
        } else {
            if (id === 175 || id === 356) {
                setNoSpace1(false)
            } else if (id === 178 || id === 325) {
                setNoSpace2(false)
            }
            if ((id === 175 || id === 356) && !(/^\d*$/.test(val))) {
                setOnlyNumber1(true)
            } else if ((id === 175 || id === 356) && /^\d*$/.test(val)) {
                setOnlyNumber1(false)
            } else if ((id === 178 || id === 325) && !(/^\d*$/.test(val))) {
                setOnlyNumber2(true)
            } else if ((id === 178 || id === 325) && /^\d*$/.test(val)) {
                setOnlyNumber2(false)
            }
        }


        // if(stringValidation.includes(parseInt(valSplit[valSplit.length-1]))){
        //     dispatch(setOnlyNumber(false))
        // }else{
        //     dispatch(setOnlyNumber(true))
        // }
        answers.map(c => {
            if (c.questionId === id && id === 175) {
                setInput(val)
                c.answers[0].answerValue = val
                setAnswers(answers)
            }else if (c.questionId === id && id === 356) {
                setInput(val)
                c.answers[0].answerValue = val
                setAnswers(answers)
            }else if (c.questionId === id && (id === 178 || id === 325)) {
                setInput1(val)
                c.answers[0].answerValue = val
                setAnswers(answers)
            }else if(c.questionId === id && id === 336){
                c.answers[0].answerValue = val
                setAnswers(answers)
            } else if(c.questionId === id && id === 364) {
                c.answers[0].otherText = val
             } else if(c.questionId === id && id === 362) {
                c.answers.map(e => {
                    if (e.answerId === 2120) {
                       e.otherText = val
                    } return null;
                })
                
             }
            return null;
        })
    }
    let diseaseDetails = [];

    const setFieldValue = (id, value) => {
        if (questions.currentPage === 3 && id === 202) {
            setPageData(questions.question.map(async (c, i) => {
                // if(c.conditionalQUestionEnabled && questions.question.filter((x)=>x.questionId === id).answers.filter((x)=>x.answerId === value && x.answerValue === '1')){
                //     c.expansionPanel = true
                // }
                if (c.questionId === id) {
                    if(c.conditionalQUestionEnabled && c.answers.filter((x)=>x.answerId === value)[0].answerValue === '1'){
                        c.expansionPanel = true
                    }else{
                        c.expansionPanel = false
                        c.conditionalQuestions.map((d)=>{
                            d.answers.map((e)=>{
                                e.selected = false
                            })
                        })
                        answers.map((x)=>{
                            if(x.questionId === id){
                                delete x.conditionalQuestion
                            }
                        })
                    }
                    if (c.answered) {
                        if (c.questionType === "multi") {
                            let falseCount = 0
                            await value.map(e => {
                                if (e.selected === false) {
                                    falseCount = falseCount + 1;
                                } return null;
                            })
                            if (falseCount === value.length) {
                                c.answered = false
                                answers.map((e, i) => {
                                    if (e.questionId === id) {
                                        answers.splice(i, 1)
                                        setOtherDisease([])
                                        diseaseForm.setFieldValue('disease', [])
                                        setMultiSelect(false)
                                    } return null;
                                })
                                setAnsweredCount(answeredCount - 1)
                            } else {
                                if (answers.filter(e => e.questionId === id).length > 0) {
                                    value.map((f) => {
                                        if (f.answerId === 1470) {
                                            answers.map((h) => {
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 1470) {
                                                        h.answers.answerValue = ""
                                                    } return null;
                                                })
                                                return null;
                                            })
                                        }
                                        if (typeof (f.answerId) === 'string') {
                                            if (f.selected) {
                                                answers.map((c) => {
                                                    c.answers.map((d) => {
                                                        if (d.answerId !== undefined) {
                                                            if (d.answerId === 1470) {
                                                                let addAnswer = d.answerValue.split(',')
                                                                addAnswer.push(f.answerId)
                                                                d.answerValue = addAnswer
                                                            }
                                                            let unique = [...new Set(d.answerValue)]
                                                            d.answerValue = unique.join(',')
                                                            return d
                                                        }
                                                    })
                                                    return c
                                                })
                                            } else {
                                                answers.map((c) => {
                                                    c.answers.map((d) => {
                                                        if (d.answerId === 1470) {
                                                            let removeAnswer = d.answerValue.split(',');
                                                            // removeAnswer.filter((x)=>x !== f.answerId)
                                                            let unique = [...new Set(removeAnswer.filter((x) => x !== f.answerId))]
                                                            d.answerValue = unique.join(',')
                                                        }
                                                        return d
                                                    })
                                                    return c
                                                })
                                            }
                                            setAnswers(answers)
                                        }
                                        if (f.selected && f.answerId === 1470) {
                                            setMultiSelect(true)
                                            answers.map((h) => {
                                                if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                    if (h.questionId === 202 && typeof (f.answerId) === 'number') {
                                                        h.answers.push({ answerId: f.answerId })
                                                    }
                                                } return null;
                                            })
                                        } else if (f.selected && f.answerId !== 1470 && f.answerId !== 1471) {
                                            answers.map((h) => {
                                                if (h.answers.filter(j => j.answerId === 1471).length > 0) {
                                                    h.answers.map((k, j) => {
                                                        if (k.answerId === 1471) {
                                                            h.answers.splice(j, 1)
                                                            if (h.questionId === 202 && typeof (f.answerId) === 'number') {
                                                                h.answers.push({ answerId: f.answerId })
                                                            }
                                                        } return null;
                                                    })
                                                } else {
                                                    if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                        if (h.questionId === 202 && typeof (f.answerId) === 'number') {
                                                            h.answers.push({ answerId: f.answerId })
                                                        }
                                                    }
                                                } return null;
                                            })
                                        } else if (!f.selected && typeof (f.answerId) === 'number') {
                                            if (f.answerId === 1470) {
                                                setMultiSelect(false)
                                                answers.map((h) => {
                                                    h.answers.map((k, j) => {
                                                        if (k.answerId === 1470) {
                                                            h.answers.splice(j, 1)
                                                            setOtherDisease([])
                                                            diseaseForm.setFieldValue('disease', [])
                                                            if (value.filter((p) => typeof (p.answerId) === 'string').length > 0) {
                                                                value.filter((p) => {
                                                                    if (typeof (p.answerId) === 'string') {
                                                                        p.selected = false
                                                                        p.answerLabel = ''
                                                                    } return null;
                                                                })
                                                            }
                                                            if (value.filter((l) => l.selected).length === 0) {
                                                                setAnsweredCount(answeredCount - 1)
                                                                c.answered = false
                                                            }
                                                        } return null;
                                                    })
                                                    return null;
                                                });
                                            } else {
                                                answers.map((h) => {
                                                    h.answers.map((k, j) => {
                                                        if (k.answerId === f.answerId) {
                                                            h.answers.splice(j, 1)
                                                        } return null;
                                                    })
                                                    return null;
                                                })
                                            }
                                        }
                                        else if (!f.selected && typeof (f.answerId) !== 'number') {
                                        }
                                        else if (f.selected && f.answerId === 1471) {
                                            answers.map((h, i) => {
                                                if (h.questionId === id) {
                                                    answers.splice(i, 1)
                                                }
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 1470) {
                                                        h.answers.splice(j, 1)
                                                        setOtherDisease([])
                                                        diseaseForm.setFieldValue('disease', [])
                                                    } return null;
                                                })
                                            })


                                            answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                        } else if (f.answerId !== 1470 && f.selected === false) {
                                            answers.map((h) => {
                                                if (h.questionId === 202 && typeof (f.answerId) === 'string' && f.selected === false) {
                                                    h.answers.map(c => {
                                                        if (c.answerId === 1470) {
                                                            c.answerValue = c.answerValue.split(',').filter(e => e !== f.answerId).join(',')
                                                        } return null;
                                                    })
                                                }
                                                setAnswers(answers)
                                                return null;
                                            })
                                        } return null;
                                    })
                                } else {
                                    value.map((f) => {
                                        if (f.selected === true) {
                                            answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                        } return null;
                                    })
                                }
                            }
                        }
                    } else if (!c.answered) {
                        c.answered = true
                        if (c.questionType === "multi") {
                            if (answers.filter(e => e.questionId === id).length > 0) {
                                getAnsweredCount()
                                value.map((f) => {
                                    if (f.selected && f.answerId === 1470) {
                                        setMultiSelect(true)
                                        answers.map((h) => {
                                            if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                if (h.questionId === 202 && typeof (f.answerId) === "number") {
                                                    h.answers.push({ answerId: f.answerId })
                                                }
                                            } return null;
                                        })
                                    } else if (f.selected && f.answerId !== 1470 && f.answerId !== 1471) {
                                        answers.map((h) => {
                                            if (h.answers.filter(j => j.answerId === 1471).length > 0) {
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 1471) {
                                                        h.answers.splice(j, 1)
                                                        if (h.questionId === 202 && typeof (f.answerId) === 'number') {
                                                            h.answers.push({ answerId: f.answerId })
                                                        }
                                                    } return null;
                                                })
                                            } else {
                                                if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                    if (h.questionId === 202 && typeof (f.answerId) === 'number') {
                                                        h.answers.push({ answerId: f.answerId })
                                                    }
                                                }
                                            } return null;
                                        })
                                    } else if (!f.selected && f.answerId === 1470) {
                                        setMultiSelect(false)
                                        answers.map((h) => {
                                            h.answers.map((k, j) => {
                                                if (k.answerId === 1470) {
                                                    h.answers.splice(j, 1)
                                                    setOtherDisease([])
                                                    diseaseForm.setFieldValue('disease', [])
                                                } return null;
                                            })
                                            return null;
                                        })
                                    } else if (f.selected && f.answerId === 1471) {
                                        answers.map((h, i) => {
                                            if (h.questionId === id) {
                                                answers.splice(i, 1)
                                            }
                                            return null;
                                        })
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                    } return null;
                                })
                            } else {
                                getAnsweredCount()
                                value.map((f) => {
                                    if (f.selected && f.answerId === 1470) {
                                        setMultiSelect(true)
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId, answerValue: '' }] })
                                    } else if (f.selected && f.answerId !== 1470) {
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                    } return null;
                                })
                            }
                        }
                    }


                    let selectedArrTemp = [];

                    c.answers.forEach(j => {
                        if (typeof j.answerId === 'string' && j.selected) {
                            selectedArrTemp.push(j.answerId)
                        }
                    });
                    setOtherDiseaseDetails(selectedArrTemp.join(','))
                }
            }))
        }
        else if (questions.currentPage === 18 && (id === 148 || id === 257)) {
            setPageData(questions.question.map(async (c, i) => {
                // if(c.conditionalQUestionEnabled && questions.question.filter((x)=>x.questionId === id).answers.filter((x)=>x.answerId === value && x.answerValue === '1')){
                //     c.expansionPanel = true
                // }
                if (c.questionId === id) {
                    if(c.conditionalQUestionEnabled && c.answers.filter((x)=>x.answerId === value)[0].answerValue === '1'){
                        c.expansionPanel = true
                    }else{
                        c.expansionPanel = false
                        c.conditionalQuestions.map((d)=>{
                            d.answers.map((e)=>{
                                e.selected = false
                            })
                        })
                        answers.map((x)=>{
                            if(x.questionId === id){
                                delete x.conditionalQuestion
                            }
                        })
                    }
                    if (c.answered) {
                        if (c.questionType === "multi") {
                            let falseCount = 0
                            await value.map(e => {
                                if (e.selected === false) {
                                    falseCount = falseCount + 1;
                                } return null;
                            })
                            if (falseCount === value.length) {
                                c.answered = false
                                answers.map((e, i) => {
                                    if (e.questionId === id) {
                                        answers.splice(i, 1)
                                        setOtherDisease([])
                                        diseaseForm.setFieldValue('disease', [])
                                        setMultiSelect(false)
                                    } return null;
                                })
                                setAnsweredCount(answeredCount - 1)
                            } else {
                                if (answers.filter(e => e.questionId === id).length > 0) {
                                    value.map((f) => {
                                        if (f.answerId === 604 || f.answerId === 1729) {
                                            answers.map((h) => {
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 604 || k.answerId === 1729) {
                                                        h.answers.answerValue = ""
                                                    } return null;
                                                })
                                                return null;
                                            })
                                        }
                                        if (typeof (f.answerId) === 'string') {
                                            if (f.selected) {
                                                answers.map((c) => {
                                                    c.answers.map((d) => {
                                                        if (d.answerId === 604 || d.answerId === 1729) {
                                                            let addAnswer = d.answerValue.split(',')
                                                            addAnswer.push(f.answerId)
                                                            d.answerValue = addAnswer
                                                        }
                                                        let unique = [...new Set(d.answerValue)]
                                                        d.answerValue = unique.join(',')
                                                        return d
                                                    })
                                                    return c
                                                })
                                            } else {
                                                answers.map((c) => {
                                                    c.answers.map((d) => {
                                                        if (d.answerId === 604 || d.answerId === 1729) {
                                                            let removeAnswer = d.answerValue.split(',');
                                                            // removeAnswer.filter((x)=>x !== f.answerId)
                                                            let unique = [...new Set(removeAnswer.filter((x) => x !== f.answerId))]
                                                            d.answerValue = unique.join(',')
                                                        }
                                                        return d
                                                    })
                                                    return c
                                                })
                                            }
                                            setAnswers(answers)
                                        }

                                        if (f.selected && (f.answerId === 604 || f.answerId === 1729)) {
                                            setMultiSelect(true)
                                            answers.map((h) => {
                                                if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                    if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'number') {
                                                        h.answers.push({ answerId: f.answerId })
                                                    }
                                                } return null;
                                            })
                                        } else if (f.selected && ((f.answerId !== 604 && f.answerId !== 603) ||(f.answerId !== 1729 && f.answerId !== 1728))) {
                                            answers.map((h) => {
                                                if (h.answers.filter(j => (j.answerId === 603 || j.answerId === 1728)).length > 0) {
                                                    h.answers.map((k, j) => {
                                                        if ((k.answerId === 603 || k.answerId === 1728)) {
                                                            h.answers.splice(j, 1)
                                                            if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'number') {
                                                                h.answers.push({ answerId: f.answerId })
                                                            }
                                                        } return null;
                                                    })
                                                } else {
                                                    if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                        if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'number') {
                                                            h.answers.push({ answerId: f.answerId })
                                                        }
                                                    }
                                                } return null;
                                            })
                                        } else if (!f.selected && typeof (f.answerId) === 'number') {

                                            if (f.answerId === 604 || f.answerId === 1729) {
                                                setMultiSelect(false)
                                                answers.map((h) => {
                                                    h.answers.map((k, j) => {
                                                        if (k.answerId === 604 || k.answerId === 1729) {
                                                            h.answers.splice(j, 1)
                                                            setOtherDisease([])
                                                            diseaseForm.setFieldValue('disease', [])
                                                            if (value.filter((p) => typeof (p.answerId) === 'string').length > 0) {
                                                                value.filter((p) => {
                                                                    if (typeof (p.answerId) === 'string') {
                                                                        p.selected = false
                                                                        p.answerLabel = ''
                                                                    } return null;
                                                                })
                                                            }
                                                            if (value.filter((l) => l.selected).length === 0) {
                                                                setAnsweredCount(answeredCount - 1)
                                                                c.answered = false
                                                            }
                                                        } return null;
                                                    })
                                                    return null;
                                                });
                                            } else {
                                                answers.map((h) => {
                                                    h.answers.map((k, j) => {
                                                        if (k.answerId === f.answerId) {
                                                            h.answers.splice(j, 1)
                                                        } return null;
                                                    })
                                                    return null;
                                                })
                                            }
                                        }
                                        else if (!f.selected && typeof (f.answerId) !== 'number') {
                                        }
                                        else if (f.selected && (f.answerId === 603 || f.answerId === 1728)) {
                                            answers.map((h, i) => {
                                                if (h.questionId === id) {
                                                    answers.splice(i, 1)
                                                }
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 604 || k.answerId === 1729) {
                                                        h.answers.splice(j, 1)
                                                        setOtherDisease([])
                                                        diseaseForm.setFieldValue('disease', [])
                                                    } return null;
                                                })
                                            })


                                            answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                        } else if ((f.answerId !== 604 || f.answerId !== 1729) && f.selected === false) {
                                            answers.map((h) => {
                                                if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'string' && f.selected === false) {
                                                    h.answers.map(c => {
                                                        if (c.answerId === 604 || c.answerId === 1729) {
                                                            c.answerValue = c.answerValue.split(',').filter(e => e !== f.answerId).join(',')
                                                        } return null;
                                                    })
                                                }
                                                setAnswers(answers)
                                                return null;
                                            })
                                        } return null;
                                    })
                                } else {
                                    value.map((f) => {
                                        if (f.selected === true) {
                                            answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                        } return null;
                                    })
                                }
                            }
                        }
                    } else if (!c.answered) {
                        c.answered = true
                        if (c.questionType === "multi") {
                            if (answers.filter(e => e.questionId === id).length > 0) {
                                getAnsweredCount()
                                value.map((f) => {
                                    if (f.selected && (f.answerId === 604 || f.answerId === 1729)) {
                                        setMultiSelect(true)
                                        answers.map((h) => {
                                            if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === "number") {
                                                    h.answers.push({ answerId: f.answerId })
                                                }
                                            } return null;
                                        })
                                    } else if (f.selected && ((f.answerId !== 604 && f.answerId !== 603) || (f.answerId !== 1729 && f.answerId !== 1728))) {
                                        answers.map((h) => {
                                            if (h.answers.filter(j => (j.answerId === 603 || j.answerId === 1728)).length > 0) {
                                                h.answers.map((k, j) => {
                                                    if (k.answerId === 603 || k.answerId === 1728) {
                                                        h.answers.splice(j, 1)
                                                        if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'number') {
                                                            h.answers.push({ answerId: f.answerId })
                                                        }
                                                    } return null;
                                                })
                                            } else {
                                                if (h.answers.filter(j => j.answerId === f.answerId).length === 0) {
                                                    if ((h.questionId === 148 || h.questionId === 257) && typeof (f.answerId) === 'number') {
                                                        h.answers.push({ answerId: f.answerId })
                                                    }
                                                }
                                            } return null;
                                        })
                                    } else if (!f.selected && (f.answerId === 604 || f.answerId === 1729)) {
                                        setMultiSelect(false)
                                        answers.map((h) => {
                                            h.answers.map((k, j) => {
                                                if (k.answerId === 604 || k.answerId === 1729) {
                                                    h.answers.splice(j, 1)
                                                    setOtherDisease([])
                                                    diseaseForm.setFieldValue('disease', [])
                                                } return null;
                                            })
                                            return null;
                                        })
                                    } else if (f.selected && (f.answerId === 603 || f.answerId === 1728)) {
                                        answers.map((h, i) => {
                                            if (h.questionId === id) {
                                                answers.splice(i, 1)
                                            }
                                            return null;
                                        })
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                    } return null;
                                })
                            } else {
                                getAnsweredCount()
                                value.map((f) => {
                                    if (f.selected && (f.answerId === 604 || f.answerId === 1729)) {
                                        setMultiSelect(true)
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId, answerValue: '' }] })
                                    } else if (f.selected && (f.answerId !== 604 || f.answerId !== 1729)) {
                                        answers.push({ questionId: id, answers: [{ answerId: f.answerId }] })
                                    } return null;
                                })
                            }
                        }
                    }


                    let selectedArrTemp = [];

                    c.answers.forEach(j => {
                        if (typeof j.answerId === 'string' && j.selected) {
                            selectedArrTemp.push(j.answerId)
                        }
                    });
                    setOtherDiseaseDetails(selectedArrTemp.join(','))
                }
            }))
        } else {
            setPageData(questions.question.map((c, i) => {
                // if(c.conditionalQUestionEnabled && questions.question.answers.filter((x)=>x.answerId === value && x.answerValue === '1')){
                //     c.expansionPanel = true
                // }
                if (c.questionId === id) {
                    if(c.conditionalQUestionEnabled && c.answers.filter((x)=>x.answerId === value)[0].answerValue === '1'){
                        c.expansionPanel = true
                        
                    }else{
                        c.expansionPanel = false
                        c.conditionalQuestions.map((d)=>{
                            d.answers.map((e)=>{
                                e.selected = false
                            })
                        })
                        answers.map((x)=>{
                            if(x.questionId === id){
                                delete x.conditionalQuestion
                            }
                        })
                    }
                    if (!c.answered) {
                        c.answered = true
                        getAnsweredCount()
                        if (c.questionType === 'radio' || c.questionType === 'select' || c.questionType === 'survey') {
                            if (value === 879 || value === 918 || value === 2096 || value === 2008) {
                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value, "answerValue": '' }] })
                            } else {
                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                            }
                            c.answers = c.answers.map(e => {
                                if (c.questionId === 175 || c.questionId === 356) {
                                    if ((e.answerId === 879 && value === 879) || (e.answerId === 2096 && value === 2096)) {
                                        setTextEnable(true)
                                    } else if ((e.answerId === 880 && value === 880)||(e.answerId === 2097 && value === 2097)) {
                                        setTextEnable(false)
                                    } else { }
                                }
                                if (c.questionId === 178 || c.questionId === 325) {
                                    if ((e.answerId === 918 && value === 918) || (e.answerId === 2008 && value === 2008)) {
                                        setTextEnable1(true)
                                    } else if ((e.answerId === 919 && value === 919) || (e.answerId === 2009 && value === 2009)) {
                                        setTextEnable1(false)
                                    } else { }
                                }
                                if (c.questionId === 336) {
                                    if ((e.answerId === 2037 && value === 2037)) {
                                        setCurrentText(true)
                                    } else if ((e.answerId === 2038 && value === 2038)) {
                                        setCurrentText(false)
                                        textForm.values.currentPlan = ''
                                    } else { }
                                }
                                if (c.questionId === 364) {
                                    if ((e.answerId === 2117 && value === 2117)) {
                                        setCurrentText(true)
                                    } else{
                                        setCurrentText(false)
                                        textForm.values.currentPlan = ''
                                    }
                                }
                                if (c.questionId === 106) {
                                    if (e.answerId !== 424 && value !== 424) {
                                        questions.question.map(c => {
                                            if (c.questionId === 107 || c.questionId === 108) {
                                                c.answers.map((e, i) => {
                                                    if (i === 0 || i === c.answers.length - 1) {
                                                        e.selected = false
                                                    } return null;
                                                })
                                            } return null;
                                        })
                                    }
                                }
                                if (e.answerId === 424 && value === 424) {
                                    e.selected = true
                                    // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                c.answered = true
                                                getAnsweredCount()
                                                if (i === 0) {
                                                    answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                    e.selected = true
                                                } else {
                                                    e.selected = false
                                                } return null;
                                            })
                                        } return null;
                                    })
                                } else if (e.answerId === 972 && value === 972) {
                                    e.selected = true
                                    // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                c.answered = true
                                                getAnsweredCount()
                                                if (i === c.answers.length - 1) {
                                                    answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                    e.selected = true
                                                } else {
                                                    e.selected = false
                                                } return null;
                                            })
                                            // c.answers[c.answers.length - 1].selected = true
                                        } return null;
                                    })
                                } else if (e.answerId === value) {
                                    e.selected = true
                                } else {
                                    e.selected = false
                                }
                                return e
                            })
                        } else if (c.questionType === 'multi') {
                            if (c.questionId === 148 || c.questionId === 257) {
                                let count = 0
                                c.answers.map(e => {
                                    if ((e.answerId === 604 || e.answerId === 1729) && e.selected) {
                                        setMultiSelect(true)
                                        let answerValue = value.map(e => {
                                            if (e.selected) {
                                                return e.answerId
                                            } return null;
                                        })
                                        answerValue = answerValue.filter(e => e !== undefined)
                                        answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0], answerValue: diseaseString }] })
                                        c.answers = value
                                    } else if ((e.answerId === 604 || e.answerId === 1729) && !e.selected) {
                                        setMultiSelect(false)
                                    } else {
                                        if (count === 0 && ((c.questionId === 148 && e.answerId !== 604) ||(c.questionId === 257 && e.answerId !== 1729) )) {
                                            let answerValue = value.map(e => {
                                                if (e.selected) {
                                                    return e.answerId
                                                } return null;
                                            })
                                            answerValue = answerValue.filter(e => e !== undefined)
                                            count = count + 1
                                            answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0] }] })
                                            c.answers = value
                                        }
                                    } return null;
                                })
                            } else {
                                let answerValue = value.map(e => {
                                    let answerId;
                                    if (e.selected) {
                                        answerId = e.answerId
                                    }
                                    return answerId
                                })
                                answerValue = answerValue.filter(e => e !== undefined)
                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0] }] })
                                c.answers = value
                            }
                            if (c.questionId === 362) {
                                c.answers.map(e => {
                                if ((e.answerId === 2120 && e.selected)) {
                                    setCurrentText(true)
                                }else if(e.answerId === 2120 && !e.selected){
                                    setCurrentText(false)
                                    textForm.values.currentPlan = ''
                                }
                            })
                            }
                        } else if (c.questionType === 'text') {
                            if (!value) {
                                c.answered = false
                                c.textBox = value
                            } else {
                                c.textBox = value
                                answers.push({ "questionId": c.questionId, "answers": [{ "answerValue": value }] })
                            }
                        }
                    } else {
                        if (c.questionType === 'radio' || c.questionType === 'select' || c.questionType === 'survey') {
                            answers.map((f, i) => {
                                if (f.questionId === c.questionId) {
                                    answers.splice(i, 1)
                                    if (value === 879 || value === 918 || value === 2096 || value === 2008) {
                                        answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value, "answerValue": '' }] })
                                    } else {
                                        answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                                    }
                                } return null;
                            })
                            c.answers = c.answers.map(e => {
                                if (c.questionId === 175 || c.questionId === 356) {
                                    if ((e.answerId === 879 && value === 879) || (e.answerId === 2096 && value === 2096)) {
                                        setTextEnable(true)
                                    } else if ((e.answerId === 880 && value === 880) || (e.answerId === 2097 && value === 2097)) {
                                        setTextEnable(false)
                                        setInput("")
                                    } else { }
                                }
                                if (c.questionId === 178 || c.questionId === 325) {
                                    if ((e.answerId === 918 && value === 918) || (e.answerId === 2008 && value === 2008)) {
                                        setTextEnable1(true)
                                    } else if ((e.answerId === 919 && value === 919) || (e.answerId === 2009 && value === 2009)) {
                                        setTextEnable1(false)
                                        setInput1("")
                                    } else { }
                                }
                                if (c.questionId === 336) {
                                    if ((e.answerId === 2037 && value === 2037)) {
                                        setCurrentText(true)
                                    } else if ((e.answerId === 2038 && value === 2038)) {
                                        setCurrentText(false)
                                        textForm.values.currentPlan = ''
                                    } else { }
                                }
                                if (c.questionId === 364) {
                                    if ((e.answerId === 2117 && value === 2117)) {
                                        setCurrentText(true)
                                    } else{
                                        setCurrentText(false)
                                        textForm.values.currentPlan = ''
                                    }
                                }
                                if (c.questionId === 106) {
                                    if (e.answerId !== 424 && value !== 424) {
                                        questions.question.map(c => {
                                            if (c.questionId === 107 || c.questionId === 108) {
                                                c.answers.map((e, i) => {
                                                    if ((e.answerId === 434 && e.selected === true) || (e.answerId === 973 && e.selected === true)) {
                                                        c.answered = false
                                                    }
                                                    if ((e.answerId === 435 && e.selected === true) || (e.answerId === 974 && e.selected === true)) {
                                                        c.answered = false
                                                    }
                                                    getAnsweredCount()
                                                    // setUnansweredCount(answeredCount - 1)
                                                    if (i === 0 || i === c.answers.length - 1) {
                                                        e.selected = false
                                                        answers.map((f, i) => {
                                                            if (e.answerId === f.answers[0].answerId) {
                                                                answers.splice(i, 1)
                                                            } return null;
                                                        })
                                                    } return null;
                                                })
                                            } return null;
                                        })
                                    }
                                }
                                if (e.answerId === 424 && value === 424) {
                                    e.selected = true
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                c.answered = true
                                                getAnsweredCount()
                                                if (i === 0) {
                                                    answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                    e.selected = true
                                                } else {
                                                    answers.map((f, i) => {
                                                        if (e.answerId === f.answers[0].answerId) {
                                                            answers.splice(i, 1)
                                                        } return null;
                                                    })
                                                    e.selected = false
                                                } return null;
                                            })
                                        } return null;
                                    })
                                } else if (e.answerId === 972 && value === 972) {
                                    e.selected = true
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                if (i === c.answers.length - 1) {
                                                    c.answered = true
                                                    getAnsweredCount()
                                                    answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                    e.selected = true
                                                } else {
                                                    answers.map((f, i) => {
                                                        if (e.answerId === f.answers[0].answerId) {
                                                            answers.splice(i, 1)
                                                        } return null;
                                                    })
                                                    e.selected = false
                                                } return null;
                                            })
                                            // c.answers[c.answers.length - 1].selected = true
                                        } return null;
                                    })
                                } else if (e.answerId === value) {
                                    e.selected = true
                                } else {
                                    e.selected = false
                                }
                                return e
                            })
                        } else if (c.questionType === 'multi') {
                            if (c.questionId === 148 || c.questionId === 257) {
                                c.answers.map(e => {
                                    if ((e.answerId === 604 || e.answerId === 1729) && e.selected) {
                                        setMultiSelect(true)
                                        let answerValue = value.map(e => {
                                            if (e.selected && (e.answerId === 604 || e.answerId === 1729)) {
                                            }
                                            return e.answerId

                                        })
                                        answerValue = answerValue.filter(e => e !== undefined)
                                        let details = []
                                        answerValue.map(c => {
                                            details.push({ answerId: c, answerValue: diseaseString })
                                            return c;
                                        })
                                        let count = 0
                                        if (count === 0) {
                                            answers.map((e, i) => {
                                                if (e.questionId === id) {
                                                    e.answers.push(...details)
                                                } return null;
                                            })
                                        }

                                        c.answers = value
                                    } else if ((e.answerId === 604 || e.answerId === 1729) && !e.selected) {
                                        setMultiSelect(false)
                                    } else {
                                        if (typeof (e.answerId) === 'number') {
                                            let answerValue = value.map(e => {
                                                if (e.selected && (e.answerId !== 604 || e.answerId !== 1729)) {
                                                }
                                                return e.answerId

                                            })
                                            answerValue = answerValue.filter(e => e !== undefined)
                                            let details = []
                                            answerValue.map((c, i) => {
                                                if (typeof (c) === 'string') {
                                                    answerValue.splice(i, 1)
                                                } else {
                                                    details.push({ answerId: c })
                                                } return null;
                                            })
                                            let count = 0
                                            if (count === 0) {
                                                answers.map((e, i) => {
                                                    if (e.questionId === id) {
                                                        answers.splice(i, 1)
                                                        count = count + 1
                                                        if (details.length === 0) {
                                                            setAnsweredCount(answeredCount - 1)
                                                            c.answered = false
                                                        }
                                                        if (details.length > 0) {
                                                            answers.push({ questionId: e.questionId, answers: details })
                                                        }
                                                    } return null;
                                                })
                                            }
                                            c.answers = value
                                        } else {
                                            if (typeof (e.answerId) === 'string') {
                                                if (e.selected) {
                                                    diseaseDetails.push(e.answerId)
                                                }
                                                answers.map(e => {
                                                    if (e.questionId === 148 || e.questionId === 257) {
                                                        e.answers.map((f, i) => {
                                                            if ((f.answerId !== 604 || f.answerId !== 1729) && f.answerId === e.answers[i].answerId) {
                                                                e.answers.splice(i, 1)
                                                            } else if (f.answerId === 604 || f.answerId === 1729) {
                                                                if (diseaseString) {
                                                                    setDiseaseString([diseaseString].filter(c => c !== e.answerId))
                                                                    setOtherDiseaseDetails([otherDiseaseDetails].filter(c => c !== e.answerId))
                                                                    e.answers[i].answerValue = diseaseDetails + ',' + diseaseString
                                                                } else {
                                                                    let temp = diseaseDetails.join(',')
                                                                    setDiseaseString([diseaseString].filter(c => c !== e.answerId))
                                                                    setOtherDiseaseDetails([otherDiseaseDetails].filter(c => c !== e.answerId))
                                                                    e.answers[i].answerValue = temp
                                                                }
                                                            }
                                                            return f
                                                        })
                                                        return e
                                                    } return null;
                                                })
                                            }
                                        }
                                    } return null;
                                })
                            } else {
                                let answerValue = value.map(e => {
                                    let answerId;
                                    if (e.selected && (e.answerId !== 604 || e.answerId !== 1729)) {
                                        answerId = e.answerId
                                    }
                                    return answerId
                                })
                                answerValue = answerValue.filter(e => e !== undefined)
                                let details = []
                                answerValue.map(c => {
                                    details.push({ answerId: c })
                                    return null;
                                })
                                let count = 0
                                if (count === 0) {
                                    answers.map((e, i) => {
                                        if (e.questionId === id) {
                                            answers.splice(i, 1)
                                            count = count + 1
                                            if (details.length === 0) {
                                                c.answered = false
                                                getAnsweredCount()
                                            }
                                            if (details.length > 0) {
                                                answers.push({ questionId: e.questionId, answers: details })
                                            }
                                        } return null;
                                    })
                                }
                                c.answers = value
                            }
                            if (c.questionId === 362) {
                                c.answers.map(e => {
                                if ((e.answerId === 2120 && e.selected)) {
                                    setCurrentText(true)
                                }else if(e.answerId === 2120 && !e.selected){
                                    setCurrentText(false)
                                    textForm.values.currentPlan = ''
                                }
                            })
                            }
                        } else if (c.questionType === 'text') {
                            if (!value) {
                                answers.map((e, i) => {
                                    if (e.questionId === id) {
                                        answers.splice(i, 1)
                                    } return null;
                                })
                                setAnsweredCount(answeredCount - 1)
                                c.answered = false
                                c.textBox = value
                            } else {
                                answers.map(e => {
                                    if (e.questionId === id) {
                                        e.answers[0].answerValue = value
                                        c.textBox = value
                                    } return null;
                                })
                            }
                        }
                    }
                }
                return c
            }))
        }
        setTimeout(() => {
            let values = pageData;
            setPageData(values)
        }, 5000)
    }

    const previousPage = () => {
        if (questions.currentPage > 3) {
            setCaseFlag(0)
            setPrevious(true)
            if (questions.previousPage === page) {
                setTriggerFirst(!triggerFirst)
            }
            setPage(questions.previousPage)
            setUnansweredCount(0)
            setAnsweredCount(0)
            setAnswers([])
            setLoading(true)
        }
    }

    const nextPage = () => {
        setPrevious(false)
        // pageList.map((c, i) => {
        //     if (questions.currentPage - 2 === c.id) {
        //         c.completed = true
        //     } return null;
        // })
        let details = {
            "assessmentId": asmtId,
            "assessmentComplete": false,
            "caseId": caseId,
            questions: answers,
            orgId: organization,
            pageNumber: questions.pageNumber
        }
        let charCount = 0
        let loopCount = 0
        answers.map(e => {
            if (e.questionId === 172 || e.questionId === 173 || e.questionId === 156 || e.questionId === 157 ||
                e.questionId === 158 || e.questionId === 159 || e.questionId === 160 || e.questionId === 161 || e.questionId === 30) {
                loopCount = loopCount + 1
                if (/^\d*$/.test(e.answers[0].answerValue)) {
                    charCount = charCount + 1
                }

            } return null;
        })
        let index1 = answers.findIndex((x) => {
            return x.questionId === 172
        })
        let index2 = answers.findIndex((x) => {
            return x.questionId === 173
        })
        let index3 = answers.findIndex((x) => {
            return x.questionId === 30
        })
        let index4 = answers.findIndex((x) => {
            return x.questionId === 156
        })
        let index5 = answers.findIndex((x) => {
            return x.questionId === 157
        })
        let index6 = answers.findIndex((x) => {
            return x.questionId === 158
        })
        let index7 = answers.findIndex((x) => {
            return x.questionId === 159
        })
        let index8 = answers.findIndex((x) => {
            return x.questionId === 160
        })
        let index9 = answers.findIndex((x) => {
            return x.questionId === 161
        })
        let xCount = 0
        if (index1 !== -1 && index2 !== -1) {
            if (answers[index1].answers[0].answerValue === '0' && answers[index2].answers[0].answerValue === '0') {
                xCount = xCount + 1
            } else if (answers[index1].answers[0].answerValue === '00' && answers[index2].answers[0].answerValue === '00') {
                xCount = xCount + 1
            } else if (answers[index1].answers[0].answerValue === '00' || answers[index2].answers[0].answerValue === '00') {
                xCount = xCount + 1
            }
        }
        let yCount = 0
        answers.map((c) => {
            if (c.questionId === 25) {
                c.answers.map((e, i) => {
                    if (e.answerValue || e.answerValue === '' || e.answerValue === null) {
                        delete c.answers[i].answerValue
                    }
                })
            }
            return c
        })
        let zcount = 0
        if (index3 !== -1 || index4 !== -1 || index5 !== -1 || index6 !== -1 || index7 !== -1 || index8 !== -1 || index9 !== -1) {
            if (answers[index3] !== undefined && answers[index3].answers[0].answerValue === '00') {
                yCount = yCount + 1
            } else if (answers[index4] !== undefined && answers[index4].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index4] !== undefined && (answers[index4].answers[0].answerValue === '00' || answers[index4].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index5] !== undefined && answers[index5].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index5] !== undefined && (answers[index5].answers[0].answerValue === '00' || answers[index5].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index6] !== undefined && answers[index6].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index6] !== undefined && (answers[index6].answers[0].answerValue === '00' || answers[index6].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index7] !== undefined && answers[index7].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index7] !== undefined && (answers[index7].answers[0].answerValue === '00' || answers[index7].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index8] !== undefined && answers[index8].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index8] !== undefined && (answers[index8].answers[0].answerValue === '00' || answers[index8].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index9] !== undefined && answers[index9].answers[0].answerValue > 168) {
                zcount = zcount + 1
            } else if (answers[index9] !== undefined && (answers[index9].answers[0].answerValue === '00' || answers[index9].answers[0].answerValue === '000')) {
                yCount = yCount + 1
            } else if (answers[index3] !== undefined && answers[index3].answers[0].answerValue === '00') {
                yCount = yCount + 1
            }
        }
        let otherSelected = false
        let count = 0
        answers.map(c => {
            if (c.questionId === 202) {
                c.answers.map(e => {
                    if (e.answerId === 1470) {
                        otherSelected = true
                        if (e.answerValue) {
                            count = count + 1
                        } else if (otherDiseaseDetails !== null && otherDiseaseDetails !== undefined && otherDiseaseDetails !== '') {
                            e.answerValue = otherDiseaseDetails
                            count = count + 1
                        } else {
                            dispatch(setAlert({ title: t('Warning'), subtitle: t('Either you need to select the disease or enter the disease'), type: 'warning', active: true }))
                            setDisable(false)
                        }
                    } return null;
                })
            }
        })
        if (page === 3 && (xCount === 1)) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Please enter the number of dependents'), type: 'warning', active: true }))
        } else {
            if (page === 3 && (loopCount !== charCount)) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('TextField Accept Only Number'), type: 'warning', active: true }))
            } else if (page === 4 && (zcount === 1)) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('Please enter only numbers (max limit 168 hrs.)'), type: 'warning', active: true }))
            } else if (page === 4 && (loopCount !== charCount)) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('TextField Accept Only Number'), type: 'warning', active: true }))
            } else if (page === 4 && (yCount === 1)) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('The text field accepts valid numbers only.'), type: 'warning', active: true }))
            } else if ((!otherSelected && loopCount === charCount) || (otherSelected && count > 0)) {
                details.questions.map(c => {
                    if (c.questionId === 202) {
                        c.answers.map((e) => {
                            if (e.answerId === 1470) {
                                let value = e.answerValue.split(',')
                                let newString = []
                                let answerIds = []
                                value.map((f) => {
                                    if (typeof (f) === 'string' && isNaN(parseInt(f))) {
                                        if (diseases.filter(x => x.diseaseName === f)) {
                                            let idValue = diseases.filter(x => x.text === f.trim())
                                            if (idValue.length > 0) {
                                                answerIds.push(idValue[0].value)
                                            } else {
                                                newString.push(f)
                                            }
                                        }
                                    } else {
                                        answerIds.push(parseInt(f))
                                    } return null;
                                })
                                e.newDisease = newString
                                e.answerValue = answerIds.join(',')
                            } return null;
                        })
                    } return null;
                })
                let questionCount = details.questions.length
                let countValue = 0
                details.questions.map((c)=>{
                    if((c.conditionalQuestion === undefined && ((questions.question.filter((x)=>x.questionId === c.questionId)[0]?.conditionalQuestions?.length !== 0 && questions.question.filter((x)=>x.questionId === c.questionId)[0]?.answers.filter((y)=>y.answerId === c.answers[0].answerId)[0].answerValue !== '1') || questions.question.filter((x)=>x.questionId === c.questionId)[0]?.conditionalQuestions?.length === 0)) || (c.conditionalQuestion !== undefined && c.conditionalQuestion.length === questions.question.filter((x)=>x.questionId === c.questionId)[0]?.conditionalQuestions?.length)){
                        countValue = countValue+1
                    }
                })
                if(countValue === questionCount){
                    API.patch(`organizations/${orgId}/cases/${caseId}/assessment`, details).then(res => {
                        if (res.status === 200) {
                            setCaseFlag(0);
                            setUnansweredCount(0)
                            setAnsweredCount(0)
                            setAnswers([])
                            setOtherDisease([])
                            // setPage(page + 1);
                            setPage(questions.nextPage)
                            setLoading(true)
                            for (var i = 0; i < questions.nextPage - 2; i++) {
                                pageList[i].completed = true
                                setPageList(pageList)
                            }
                        }
                    }).catch(err => {
                        if (err.response.status === 406) {
                            dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                            history.push(`/casedata/${caseId}`)
    
                        } else {
                            dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        }
                    })
                }else{
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Some of the conditional questions are not answered.'), type: 'error', active: true }))
                }
            }
        }
    }


    const submitPage = () => {
        if (editorActive) {
            return dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Save or Close Notes Before Submit'), type: 'warning', active: true }))
        }
        setDisable(true)
        setPrevious(false)
        pageList.map((c, i) => {
            if (page === c.id) {
                c.completed = true
            } return null;
        })
        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        let details = {
            assessmentId: asmtId,
            assessmentComplete: true,
            caseId: caseId,
            questions: answers,
            orgId: organization,
            pageNumber: questions.pageNumber,
            timeZone: tzabbr
        }
        let count = 0
        let otherSelected = false
        let charCount = 0
        let loopCount = 0
        let textfield = 0
        let numCount = 0
        answers.map(e => {
            if (e.questionId === 175 || e.questionId === 178 || e.questionId === 356 || e.questionId === 325) {
                loopCount = loopCount + 1
                if (e.answers[0].answerValue !== undefined && e.answers[0].answerValue.length === 0) {
                    textfield = textfield + 1
                }
                if (e.answers[0].answerValue !== undefined && /^\d*$/.test(e.answers[0].answerValue)) {
                    charCount = charCount + 1
                }
                if (e.answers[0].answerValue !== undefined && e.answers[0].answerValue <= followupDays && /^\d*$/.test(e.answers[0].answerValue)) {
                    numCount = numCount + 1
                }
                else if (e.answers[0].answerValue === undefined) {
                    loopCount = loopCount - 1
                }
            } return null;
        })
        answers.map(c => {
            if (c.questionId === 148 || c.questionId === 257) {
                c.answers.map(e => {
                    if (e.answerId === 604 || e.answerId === 1729) {
                        otherSelected = true
                        if (e.answerValue) {
                            count = count + 1
                        } else if (otherDiseaseDetails !== null && otherDiseaseDetails !== undefined && otherDiseaseDetails !== '') {
                            e.answerValue = otherDiseaseDetails
                            count = count + 1
                        } else {
                            dispatch(setAlert({ title: t('Warning'), subtitle: t('Either you need to select the disease or enter the disease'), type: 'warning', active: true }))
                            setDisable(false)
                        }
                    } return null;
                })
            } else if (c.questionId === 175 || c.questionId === 178) {
                c.answers.map((e) => {
                    if (e.answerValue !== undefined && e.answerId !== 879 && e.answerId !== 918) {
                        delete e.answerValue
                    }
                })
            }else{
                c.answers.map((e) => {
                    if (e.answerValue !== undefined && e.answerId !== 2096 && e.answerId !== 2008) {
                        delete e.answerValue
                    }
                })
            } return null
        })

        let question1 = 0
        let question2 = 0

        answers.map((c) => {
            if (c.questionId === 175) {
                c.answers.map((e) => {
                    if ((e.answerId === 879) && e.answerValue <= 0) {
                        question1 = 1
                    }
                    // else if(e.answerId === 879 && e.answerValue === undefined){
                    //     question1 = 1
                    // }
                    return null;
                })
            }
            if (c.questionId === 178 || c.questionId === 325) {
                c.answers.map((e) => {
                    if ((e.answerId === 918 || e.answerId === 2008) && e.answerValue <= 0) {
                        question2 = 1
                    }
                    // else if(e.answerId == 918 && e.answerValue === undefined){
                    //     question2 = 1
                    // }
                    return null;
                })
            } return null;
        })
        if (question1 === 0 && question2 === 0) {
            if (textfield === 1) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Fill the Required Values'), type: 'warning', active: true }))
                setDisable(false)
            } else if (textfield === 1) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Fill the Required Values'), type: 'warning', active: true }))
                setDisable(false)
            } else if (loopCount !== charCount) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t("If you clicked 'Yes' kindly please provide the values in number"), active: true, type: 'warning' }))
                setDisable(false)
            } else if (loopCount !== numCount) {
                dispatch(setAlert({ title: t('Warning'), subtitle: t(`Please Fill the TextField Values with Maximum ${followupDays} Days`), active: true, type: 'warning' }))
                setDisable(false)
            } else if ((!otherSelected && count === 0 && charCount === loopCount) || (otherSelected && count > 0 && charCount === loopCount)) {
                details.questions.map(c => {
                    if (c.questionId === 148 || c.questionId === 257) {
                        c.answers.map((e) => {
                            if (e.answerId === 604 || e.answerId === 1729) {
                                let value = e.answerValue.split(',')
                                let newString = []
                                let answerIds = []
                                value.map((f) => {
                                    if (typeof (f) === 'string' && isNaN(parseInt(f))) {
                                        if (diseases.filter(x => x.diseaseName === f)) {
                                            let idValue = diseases.filter(x => x.text === f.trim())
                                            if (idValue.length > 0) {
                                                answerIds.push(idValue[0].value)
                                            } else {
                                                newString.push(f)
                                            }
                                        }
                                    } else {
                                        answerIds.push(parseInt(f))
                                    } return null;
                                })
                                e.newDisease = newString
                                e.answerValue = answerIds.join(',')
                            } return null;
                        })
                    } return null;
                })
                API.patch(`organizations/${orgId}/cases/${caseId}/assessment`, details).then(res => {
                    if (res.status === 200) {
                        if (res.data.body.screenerAssess) {
                            dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment submitted successfully'), type: 'success', active: true }))
                            history.push(`/case/${caseId}/score/${asmtId}`)
                        } else if (!res.data.body.screenerAssess) {
                            dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment submitted successfully'), type: 'success', active: true }))
                            history.push(`/case/${caseId}/summary/${asmtId}`)
                        } else {
                            setDisable(false)
                        }
                    } else if (res.status === 500) {

                    }
                }).catch(err => {
                    if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        history.push(`/casedata/${caseId}`)

                    } else if (err.response.status === 500) {
                        // dispatch(setAlert({ title: t('Error'), subtitle: err.response.data.errorMessage, type: 'error', active: true }))
                        setFollowupPopup(true)
                    } else {
                        dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
                        setDisable(false)
                    }
                })
            }
        } else if (question1 === 1) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Fill the TextField Values with greater than zero'), type: 'warning', active: true }))
            setDisable(false)
        } else if (question2 === 1) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Fill the TextField Values with greater than zero'), type: 'warning', active: true }))
            setDisable(false)
        } else if (question1 === 1 && question2 === 1) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Please Fill the TextField Values with greater than zero'), type: 'warning', active: true }))
            setDisable(false)
        } else { }

    }
    const followup = () => {
        setPage(3)
        setFollowupPopup(false)
    }

    const CloseFollowupPopup = () => {
        history.push(`/casedata/${caseId}`)
    }

    // const printAssessment = (asmtId, page) => {
    //     API.post(`assessments/${asmtId}:printForm`).then(res => {
    //         setDownloadData(`data:application/pdf;base64,${res.data.body}`)
    //         setDownloadPopup(true)
    //     })
    // }
    const openConfirm = () => {
        setShowConfirm(true)
    }
    const revertAssessment = () => {
        API.post(`organizations/${orgId}/cases/${caseId}/assessment/${asmtId}/revertAssessment`).then(res => {
            if (res.status === 200) {
                history.push(`/casedata/${caseId}`)
            }
        })
    }
    const printBlankAssess = () => {
        API.get(`assessments/${asmtId}:printForm`).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Blank-Assessment.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment downloaded successfully'), type: 'success', active: true }))
                //onClose()
            } else {
                dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
                //onClose();
            }
        }).catch(err => {
            dispatch(setAlert({ title: ('Error'), subtitle: t('Something went wrong'), active: true, type: 'error' }))
            //onClose();
        })
    }
    const downloadFiles = () => {
        const linkSource = downloadData;
        const downloadLink = document.createElement("a");
        const fileName = 'TCE-ASSESSMENT.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setDownloadPopup(false)
    }

    const setExpansionPanelFieldValue = (id,inId, value) => {
        setPageData(questions.question.map((c, i) => {
            if (c.questionId === id) {
                c.conditionalQuestions.map((e,j)=>{
                    if(e.questionId === inId){
                        e.answers.filter((x,i)=>x.answerId === value)[0].selected = true
                        e.answers.filter((x,i)=>x.answerId !== value).map((c)=>c.selected = false)
                        if(answers.filter((x)=>x.questionId === c.questionId)[0].conditionalQuestion?.length > 0){
                            if(answers.filter((x)=>x.questionId === c.questionId)[0].conditionalQuestion.filter((x)=>x.questionId === e.questionId).length){
                                answers.filter((x)=>x.questionId === c.questionId)[0].conditionalQuestion.filter((x)=>x.questionId === e.questionId)[0].answers[0].answerId = value
                            }else{
                            answers.filter((x)=>x.questionId === c.questionId)[0].conditionalQuestion.push({questionId : e.questionId ,answers:[{answerId:value}]}  )
                            }
                        }else{
                            answers.filter((x)=>x.questionId === c.questionId)[0].conditionalQuestion = [{questionId : e.questionId ,answers:[{answerId:value}]}]
                        }
                    }
                    return e
                })
            }
            return c
        }))
    }

    const toggleExpansion = (value)=>{
        setPageData(questions.question.map((e,i)=>{
            if(e.questionId === value.questionId){
                e.expansionPanel = !e.expansionPanel
            }
            return e
        }))
    }

    return (
        <div className={'flex-1'}>
            {
                createPortal(<Dialog title={t("Confirmation")} showDialog={showConfirm} onClose={() => setShowConfirm(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={t(`Please Confirm You Have Started an Assessment in Error ?`)} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => revertAssessment()}>{t("Yes")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setShowConfirm(false)}>{t("No")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
            {
                loading ? <AssessmentLoader /> : <div className="flex-1 pl-5 h-full">
                    <div className="flex justify-end">
                        <div className="flex flex-1 items-center">
                            <AssessmentPages pages={pageList} page={questions.currentPage - 2} />
                        </div>
                    </div>
                    <div  className="mt-2 ml-2 mr-2 justify-between flex flex-1"> 
                    <div tabIndex={0} aria-label={`${t('Page')} ${questions.questionCategory}`}>
                        <FlowText  text={`${t('Page')} ${questions.questionCategory}`} style={{ fontWeight: 'bold' }} />
                        </div>
                        <div className="flex">
                            {nextActionPopup === 'true' ? <button className="flex gap-1 items-center py-0.5 px-4 ml-2 text-sm rounded bg-red-700 hover:bg-opacity-80 text-white text-black cursor-pointer" onClick={() => openConfirm()} >{t('Assessment Opened in Error')}</button> : ''}
                            {blankPdfEnable === 'true' ? <button className="flex gap-1 items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-black cursor-pointer" onClick={() => printBlankAssess()}>
                                <FiDownload role="img" title={t('Click to Print Blank Assessment')} /> {t('Download')}
                            </button> : ''}
                        </div>
                        {/* {userDetails.role.includes('screener') ? '' : <div className="flex gap-1 items-center bg-gradient-to-tr from-tcolor to-blue-400 px-3 py-0.5 rounded text-[10px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white" onClick={() => printAssessment(asmtId, page)}>
                            <AiFillPrinter size="12px" /><span>{t("Print")}</span>
                        </div>} */}
                    </div>
                    {
                        (page === 3 || page === 11 || page === 13) ? <div ref={quesWrap} className="mt-1 mb-5 overflow-y-auto height_less_topbar -mr-8 pr-8">
                            {
                                questions.question.map((c, i) => {
                                    if (c.questionType === "radio") {
                                        return <>
                                            <motion.section aria-label={c.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className="flex flex-row w-full justify-between">
                                                    <label aria-label="testing" className={`flex flex-row w-full justify-between items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </label>
                                                    {c.conditionalQUestionEnabled ? c.expansionPanel ? <FaChevronUp  className="font-bold cursor-pointer" onClick={() => toggleExpansion(c)} /> : <FaChevronDown className="mx-1 cursor-pointer" onClick={() => toggleExpansion(c)} /> : ''}
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                                {
                                                    ((c.questionId === 175 || c.questionId === 356) && textEnable) ? <AssessmentTextField ariaLabel={"Number Input Field"} edit={true} type={'number'} value={c.textBox !== null ? c.textBox : inputValue} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                }
                                                {
                                                    ((c.questionId === 178 || c.questionId === 325) && textEnable1) ? <AssessmentTextField ariaLabel={"Number Input Field"} edit={true} type={'number'} value={c.textBox !== null ? c.textBox : input1Value} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                }
                                                 {
                                                    (c.questionId === 336 || c.questionId === 364) && currentText &&  <FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`currentPlan`)} fieldProps={textForm.getFieldProps(`currentPlan`)} edit={true} dataLoaded={true} onChange={(ev) => setTextFieldValue(c.questionId, ev)} />           

                                                }
                                            </motion.section>
                                            {
                                                c.conditionalQUestionEnabled && (c.answers[0].answerValue === '1' && c.answers[0].selected) && c.expansionPanel && <div ref={quesWrap} className="mt-1 overflow-y-auto min-h-fit max-h-72 height_less_topbar -mr-8 pr-8">
                                                    {
                                                        c.conditionalQuestions.map((e, i) => {
                                                            if (e.questionType === "radio") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className="flex flex-row w-full">
                                                                        <div className={`flex justify-between flex-row w-full items-center`}>
                                                                            <div className="w-11/12 text-sm">
                                                                                {e.questionLabel}
                                                                            </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "text") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        {
                                                                            // e.questionId == 20 && <AssessmentTextField edit={true} type={'number'} value={e.textBox !== '0'? e.textBox :''} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'4'} />
                                                                            e.questionId === 20 ? <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('year')} fieldProps={yearsForm.getFieldProps('year')} fieldHelper={yearsForm.getFieldHelpers('year')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />:
                                                                            e.questionId === 258 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('startFostering')} fieldProps={yearsForm.getFieldProps('startFostering')} fieldHelper={yearsForm.getFieldHelpers('startFostering')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                                            // e.questionId === 259 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={textForm.getFieldMeta('firstFostering')} fieldProps={textForm.getFieldProps('firstFostering')} fieldHelper={textForm.getFieldHelpers('firstFostering')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                                            
                                                                        }

                                                                        {
                                                                            (e.questionId === 156 || e.questionId === 157 || e.questionId === 158 || e.questionId === 159 || e.questionId === 160 || e.questionId === 161) && e.questionId !== 20 && e.questionId !== 258 && e.questionId !== 259 ? <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'3'} /> :
                                                                                (e.questionId !== 156 || e.questionId !== 157 || e.questionId !== 158 || e.questionId !== 159 || e.questionId !== 160 || e.questionId !== 161) && e.questionId !== 20 && e.questionId !== 258 && <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'2'} />

                                                                        }

                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "select") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "multi") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'multi_option'} value={e.answers} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                    {(multiSelect && (e.questionId === 148 || e.questionId === 202)) ? <div className="mt-4 pb-3">
                                                                        <AssessmentOptionChip ariaLabel={t('Disease')} edit={true} label={t('Disease')} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c, val)} />
                                                                    </div> : ''}
                                                                </motion.section>
                                                            } else if (e.questionType === 'header') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                                            {e.questionLabel}
                                                                        </div>
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === 'survey') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div>
                                                                        <div className={'flex flex-col justify-start'}>
                                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                                <div className={'w-11/12 text-sm'}>
                                                                                    {e.questionLabel}
                                                                                </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                            </div>
                                                                            <div className={'flex flex-1'}>
                                                                                <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} /> */}
                                                                    </div>
                                                                </motion.section>
                                                            } return null;
                                                        })
                                                    }
                                                </div>
                                            }
                                        </>
                                    } else if (c.questionType === "text") {
                                        return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm" tabIndex={0}>
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            {/* <div className="mt-4 pb-3">
                                                <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'2'} />
                                            </div> */}
                                            <div className="mt-4 pb-3">
                                                {
                                                    // c.questionId == 20 && <AssessmentTextField edit={true} type={'number'} value={c.textBox !== '0'? c.textBox :''} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'4'} />
                                                    c.questionId === 20 ? <FieldSelect label={t("Year")} fieldMeta={yearsForm.getFieldMeta('year')} fieldProps={yearsForm.getFieldProps('year')} fieldHelper={yearsForm.getFieldHelpers('year')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />:
                                                    c.questionId === 258 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('startFostering')} fieldProps={yearsForm.getFieldProps('startFostering')} fieldHelper={yearsForm.getFieldHelpers('startFostering')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                    // c.questionId === 259 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={textForm.getFieldMeta('firstFostering')} fieldProps={textForm.getFieldProps('firstFostering')} fieldHelper={textForm.getFieldHelpers('firstFostering')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />   
                                                }

                                                {
                                                    (c.questionId === 156 || c.questionId === 157 || c.questionId === 158 || c.questionId === 159 || c.questionId === 160 || c.questionId === 161) && c.questionId !== 20 && c.questionId !== 258 && c.questionId !== 259 ? <AssessmentTextField ariaLabel={"Number Input Field"} edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'3'} /> :
                                                        (c.questionId !== 156 || c.questionId !== 157 || c.questionId !== 158 || c.questionId !== 159 || c.questionId !== 160 || c.questionId !== 161 )&& c.questionId !== 332 && c.questionId !== 20 && c.questionId !== 258  ? <AssessmentTextField ariaLabel={"Number Input Field"} edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'2'} />:
                                                        c.questionId === 332 &&  <FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`permanency`)} fieldProps={textForm.getFieldProps(`permanency`)} edit={true} dataLoaded={true} onChange={(ev) => setFieldValue(c.questionId, ev)} />

                                                }

                                            </div>
                                        </motion.section>
                                    } else if (c.questionType === "select") {
                                        return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm" tabIndex={0}>
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className="mt-4 pb-3">
                                                <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                            </div>
                                        </motion.section>
                                    } else if (c.questionType === "multi") {
                                        return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm" tabIndex={0}>
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className="mt-4 pb-3">
                                                <AssessmentOptionField edit={true} type={'multi_option'} value={c.answers} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                            </div>
                                            {(multiSelect && (c.questionId === 148 || c.questionId === 202 || c.questionId === 257)) ? <div className="mt-4 pb-3">
                                                <AssessmentOptionChip ariaLabel={t('Disease')} edit={true} label={t('Disease')} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c, val)} />
                                            </div> : ''}
                                            {c.questionId === 362 && currentText &&<FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`currentPlan`)} fieldProps={textForm.getFieldProps(`currentPlan`)} edit={true} dataLoaded={true} onChange={(ev) => setTextFieldValue(c.questionId, ev)} />}
                                           
                                        </motion.section>
                                    } else if (c.questionType === 'header') {
                                        return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div tabIndex={0} className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                    {c.questionLabel}
                                                </div>
                                            </div>
                                            {/* {
                                            questions.question.map(c => {
                                                if (c.questionType === 'survey') {
                                                    return <div className={'mt-10 mb-10'}>
                                                        <div className={'flex justify-start'}>
                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                <div className={'w-11/12 text-sm'}>
                                                                    {c.questionLabel}
                                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                            </div>
                                                            <div className={'flex flex-1'}>
                                                                <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                            </div>
                                                        </div>
                                                        <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} />
                                                    </div>
                                                } else if (c.questionType === "header") {

                                                }
                                            })
                                        } */}
                                        </motion.section>
                                    } else if (c.questionType === 'survey') {
                                        return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={'flex justy-between items-center'}>
                                                <div className={'w-11/12 text-sm'} tabIndex={0}>
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className={'mt-4 pb-3'}>
                                                <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                            </div>
                                        </motion.section>
                                    } return null;
                                })
                            }
                        </div> :
                            <div ref={quesWrap} className="mt-1 mb-5 overflow-y-auto height_less_topbar -mr-8 pr-8">
                                {
                                    questions.question.map((c, i) => {
                                        if (c.questionType === "radio") {
                                            return <>
                                            <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                              <div className="flex flex-row w-full">
                                                    <div className={`flex justify-between flex-row w-full items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </div>
                                                    {c.conditionalQUestionEnabled ? c.expansionPanel ? <FaChevronUp  className="font-bold cursor-pointer" onClick={() => toggleExpansion(c)} />: <FaChevronDown className="font-bold cursor-pointer" onClick={() => toggleExpansion(c)} /> : ''}
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                                {
                                                    ((c.questionId === 175 || c.questionId === 356) && textEnable) && <div className={`relative ring-1 focus-within:ring-2 'ring-gray-400 dark:ring-gray-600' focus-within:ring-tcolor dark:focus-within:ring-tcolor px-2 py-1 rounded 'hover:cursor-wait' flex items-center 'animate-pulse' }`}>
                                                        <input className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded px-3 py-1 focus:text-black bg-transparent`}
                                                            placeholder={t("Number of days hospitalized")} type="text" id="Q175" value={inputValue}
                                                            onChange={val => setTextFieldValue(c.questionId, val.target.value)} maxLength={2} />
                                                        {noSpace1 ? <span className="text-red-500 text-xs">{t("Space at beginning is not allowed")}</span> : ''}
                                                        {onlynumber1 ? <span className="text-red-500 text-xs">{t("Only Numbers")}</span> : ''}
                                                        {/* <AssessmentTextField ariaLabel={"Number Input Field"} edit={true} type={'number'} value={c.textBox !== null ? c.textBox : ''} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> */}
                                                    </div>
                                                }
                                                {
                                                    ((c.questionId === 178 || c.questionId === 325) && textEnable1) && <div className={`relative ring-1 focus-within:ring-2 'ring-gray-400 dark:ring-gray-600' focus-within:ring-tcolor dark:focus-within:ring-tcolor px-2 py-1 rounded 'hover:cursor-wait' flex items-center 'animate-pulse' }`}>
                                                        <input className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded px-3 py-1 focus:text-black bg-transparent`} placeholder={t("Number of days hospitalized")} type="text" value={input1Value} onChange={val => setTextFieldValue(c.questionId, val.target.value)} maxLength={2} />
                                                        {noSpace2 ? <span className="text-red-500 text-xs">{t("Space at beginning is not allowed")}</span> : ''}
                                                        {onlynumber2 ? <span className="text-red-500 text-xs">{t("Only Numbers")}</span> : ''}
                                                    </div>
                                                }
                                               {
                                                    c.questionId === 336 && currentText &&  <FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`currentPlan`)} fieldProps={textForm.getFieldProps(`currentPlan`)} edit={true} dataLoaded={true} onChange={(ev) => setTextFieldValue(c.questionId, ev)} />           

                                                }
                                            </motion.section>
                                            {
                                                c.conditionalQUestionEnabled && (c.answers[0].answerValue === '1' && c.answers[0].selected) && c.expansionPanel && <div ref={quesWrap} className="mt-1 overflow-y-auto min-h-fit max-h-72 height_less_topbar -mr-8 pr-8">
                                                    {
                                                        c.conditionalQuestions.map((e, i) => {
                                                            if (e.questionType === "radio") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className="flex flex-row w-full">
                                                                        <div className={`flex justify-between flex-row w-full items-center`}>
                                                                            <div className="w-11/12 text-sm">
                                                                                {e.questionLabel}
                                                                            </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "text") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        {
                                                                            // e.questionId == 20 && <AssessmentTextField edit={true} type={'number'} value={e.textBox !== '0'? e.textBox :''} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'4'} />
                                                                            e.questionId === 20 ? <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('year')} fieldProps={yearsForm.getFieldProps('year')} fieldHelper={yearsForm.getFieldHelpers('year')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />:
                                                                            e.questionId === 258 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('startFostering')} fieldProps={yearsForm.getFieldProps('startFostering')} fieldHelper={yearsForm.getFieldHelpers('startFostering')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                                            // e.questionId === 259 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={textForm.getFieldMeta('firstFostering')} fieldProps={textForm.getFieldProps('firstFostering')} fieldHelper={textForm.getFieldHelpers('firstFostering')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                                        }

                                                                        {
                                                                            (e.questionId === 156 || e.questionId === 157 || e.questionId === 158 || e.questionId === 159 || e.questionId === 160 || e.questionId === 161) && e.questionId !== 20 && e.questionId !== 258 && e.questionId !== 259 ? <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'3'} /> :
                                                                                (e.questionId !== 156 || e.questionId !== 157 || e.questionId !== 158 || e.questionId !== 159 || e.questionId !== 160 || e.questionId !== 161) && e.questionId !== 20 && e.questionId !== 258 && <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'2'} />

                                                                        }

                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "select") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "multi") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'multi_option'} value={e.answers} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                    {(multiSelect && (e.questionId === 148 || e.questionId === 202)) ? <div className="mt-4 pb-3">
                                                                        <AssessmentOptionChip ariaLabel={t('Disease')} edit={true} label={t('Disease')} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c, val)} />
                                                                    </div> : ''}
                                                                </motion.section>
                                                            } else if (e.questionType === 'header') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                                            {e.questionLabel}
                                                                        </div>
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === 'survey') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div>
                                                                        <div className={'flex flex-col justify-start'}>
                                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                                <div className={'w-11/12 text-sm'}>
                                                                                    {e.questionLabel}
                                                                                </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                            </div>
                                                                            <div className={'flex flex-1'}>
                                                                                <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} /> */}
                                                                    </div>
                                                                </motion.section>
                                                            } return null;
                                                        })
                                                    }
                                                </div>
                                            }
                                            </>
                                        } else if (c.questionType === "text") {
                                            return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm" tabIndex={0}>
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    {
                                                        // c.questionId == 20 && <AssessmentTextField edit={true} type={'number'} value={c.textBox !== '0'? c.textBox :''} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'4'} />
                                                        c.questionId === 20 ? <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('year')} fieldProps={yearsForm.getFieldProps('year')} fieldHelper={yearsForm.getFieldHelpers('year')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />:
                                                        c.questionId === 258 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('startFostering')} fieldProps={yearsForm.getFieldProps('startFostering')} fieldHelper={yearsForm.getFieldHelpers('startFostering')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                        // c.questionId === 259 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={textForm.getFieldMeta('firstFostering')} fieldProps={textForm.getFieldProps('firstFostering')} fieldHelper={textForm.getFieldHelpers('firstFostering')} options={years} onChange={(ev) => setFieldValue(c.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                
                                                    }

                                                    {
                                                        (c.questionId === 156 || c.questionId === 157 || c.questionId === 158 || c.questionId === 159 || c.questionId === 160 || c.questionId === 161) && c.questionId !== 20 && c.questionId !== 258 && c.questionId !== 259 ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'3'} /> :
                                                            (c.questionId !== 156 || c.questionId !== 157 || c.questionId !== 158 || c.questionId !== 159 || c.questionId !== 160 || c.questionId !== 161) && c.questionId !== 332 && c.questionId !== 20 && c.questionId !== 258  ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'2'} />:
                                                            c.questionId === 332 &&  <FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`permanency`)} fieldProps={textForm.getFieldProps(`permanency`)} edit={true} dataLoaded={true} onChange={(ev) => setFieldValue(c.questionId, ev)} />           

                                                    }

                                                </div>
                                            </motion.section>
                                        } else if (c.questionType === "select") {
                                            return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm" tabIndex={0}>
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                            </motion.section>
                                        } else if (c.questionType === "multi") {
                                            return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm" tabIndex={0}>
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'multi_option'} value={c.answers} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                                {(multiSelect && (c.questionId === 148 || c.questionId === 202 || c.questionId === 257)) ? <div className="mt-4 pb-3">
                                                    <AssessmentOptionChip ariaLabel={t('Disease')} edit={true} label={t('Disease')} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c, val)} />
                                                </div> : ''}{
                                                    c.questionId === 362 && currentText &&  <FieldInput ariaLabel={'Type text'} hideData={true} label={t("Type text")} autoComplete='off' fieldMeta={textForm.getFieldMeta(`currentPlan`)} fieldProps={textForm.getFieldProps(`currentPlan`)} edit={true} dataLoaded={true} onChange={(ev) => setTextFieldValue(c.questionId, ev)} />           

                                                }
                                            </motion.section>
                                        } else if (c.questionType === 'header') {
                                            return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div tabIndex={0} className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                        {c.questionLabel}
                                                    </div>
                                                </div>
                                                {/* {
                                            questions.question.map(c => {
                                                if (c.questionType === 'survey') {
                                                    return <div className={'mt-10 mb-10'}>
                                                        <div className={'flex justify-start'}>
                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                <div className={'w-11/12 text-sm'}>
                                                                    {c.questionLabel}
                                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                            </div>
                                                            <div className={'flex flex-1'}>
                                                                <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                            </div>
                                                        </div>
                                                        <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} />
                                                    </div>
                                                } else if (c.questionType === "header") {

                                                }
                                            })
                                        } */}
                                            </motion.section>
                                        } else if (c.questionType === 'survey') {
                                            return <motion.section  initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div>
                                                    <div className={'flex justify-start'}>
                                                        <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                            <div className={'w-11/12 text-sm'} tabIndex={0}>
                                                                {c.questionLabel}
                                                            </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                        </div>
                                                        <div className={'flex flex-1'}>
                                                            <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                        </div>
                                                    </div>
                                                    {/* <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} /> */}
                                                </div>
                                            </motion.section>
                                        } return null;
                                    })
                                }
                            </div>
                    }
                    <div className="flex justify-center mx-4">
                        <button className={`flex items-center rounded-lg  px-3 py-1 bg-tcolor text-black ${questions.currentPage > 3 ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed '} ml-2 mr-2`} onClick={questions.currentPage > 3 && previousPage}>
                            <FaArrowLeft title={t("Previous")} size="15px" />
                            <span className="ml-2 text-sm font-medium">{t("Previous")}</span>
                        </button>
                        <div className="flex gap-3">
                            {((questions.currentPage < 18 && !grgSubmit) || (questions.currentPage < 13 && grgSubmit)) ?
                                <button className={`flex items-center rounded-lg  px-3 py-1 bg-tcolor text-black ${((answeredCount === unansweredCount) && (questions.currentPage < 18)) ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed '} ml-2 mr-2`} onClick={((answeredCount === unansweredCount) && (questions.currentPage < 18)) ? nextPage : ''}>
                                    <span className="mr-2 text-sm font-medium">{t("Next")}</span>
                                    <FaArrowRight title={t("Next")} size="15px" />
                                </button>
                                : <button className={`flex items-center rounded-lg text-white px-8 py-1 bg-green-700 ${((answeredCount === unansweredCount) && !disable) ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed '} ml-2 mr-2`} onClick={(((answeredCount === unansweredCount) && (!disable)) ? submitPage : '')}>
                                    <span className="mr-2 text-sm font-medium">{t("Submit")}</span>
                                    <FaArrowRight title={t("Submit")} size="15px" />
                                </button>
                            }
                            {/* <AnimatePresence>
                        {isSubmit &&
                            <motion.div variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`flex items-center rounded-lg text-black px-3 py-1 cursor-pointer bg-gradient-to-tr from-green-400 to-green-500 hover:ring-2 hover:ring-green-500 hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark`} onClick={submit}>
                                <span className="mr-2 text-sm font-medium">Submit</span>
                                <FaArrowRight title="Next" size="15px" />
                            </motion.div>
                        }
                    </AnimatePresence> */}
                        </div>
                    </div>
                </div>
            }
            {
                createPortal(<Dialog title={t("Download File")} showDialog={downloadPopup} onClose={() => setDownloadPopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={t(`Are you sure you want to download this Assessment form?`)} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadPopup(false)}>{t("No")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => downloadFiles()}>{t("Yes")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("FollowUp Assessment")} showDialog={followupPopup} onClose={() => CloseFollowupPopup()}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <div>
                                {t("Based on the caregiver's responses to these follow-up assessment questions,some scores have")}<br />
                                {t("increased and will require the completion of additional questions to determine their service")}<br />
                                {t("needs.")}
                            </div>

                        </div>
                        <div className="flex justify-center mt-8 mx-2">
                            {/* <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setFollowupPopup(false)}>{t("No")}</button> */}
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => followup()}>{t("Continue")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
            {/* {
                createPortal(<Dialog title={t("Download")} showDialog={downloadBlankAssessPopup} onClose={() => setDownloadBlankAssessPopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <CareplanDialog onClose={() => setDownloadBlankAssessPopup(false)} assessmentId={asmtId} caseId={caseId} careplanId={0} type={'create'} />

                        </div>
                    </div>
                </Dialog>, document.body)
            } */}
        </div>
    )
}

export default AssessmentFirstPage